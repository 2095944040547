const initialState = {
	
};

const lessonPreviewReduser = (state = initialState, action) => {
	switch (action.type) {
		case 'TOGGLEPREVIEWLESSON':
			return {
				...action.data
			};
		default:
			return state;
	}
};

export default lessonPreviewReduser;

export const togglePreviewLesson = (data) => {
	return { 
		type: 'TOGGLEPREVIEWLESSON', data
	 }
};

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Drawer, Select } from 'antd'
import classNames from 'classnames';
import {languagesApi} from '../../../api'
import {changeLanguages} from '../../../store/languages-reduser'
import iconRu from './icons/lang__ru.png'
import iconBe from './icons/lang_be.png'
import iconKz from './icons/lang_kz.png'
import iconAr from './icons/lang_ar.png'
import iconKg from './icons/lang__kg.png'
import iconYz from './icons/lang__yz.png'
import iconEn from './icons/lang_en.png'
import iconFr from './icons/lang_fr.png'
import iconIs from './icons/lang_is.png'
import iconIn from './icons/lang_in.png'

const Translation = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    //
    fetch(`${process.env.REACT_APP_URL_API}languages`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      dispatch(changeLanguages(data))
    });
  
    //
  }, [])

  const handleChange = (value) => {
    localStorage.setItem('selectedLanguage', value)
   const language = document.querySelector(`[data-google-id="${value}"]`)
   language.click()
  };
  

  let languages = useSelector(
      (state) => state?.languages
  );
  languages?.sort(function (a, b) {
    if (a.rank > b.rank) {
      return 1;
    }
    if (a.rank < b.rank) {
      return -1;
    }
    return 0;
  });
  

  const selectedLanguage = localStorage.getItem('selectedLanguage');
  if(selectedLanguage){
    const languageActive = languages.find((elem)=>{
      if(elem?.id==selectedLanguage){
        return true;
      }
      return false;
    })
    languages=[languageActive, ...languages]
    const languagesPrev = languages;
    languages = [];
    languages.push(languageActive);
    languagesPrev.forEach(element => {
      if(element?.id !==languageActive?.id){
        languages.push(element);
      }
    });
  }
  useEffect(()=>{
    if(!selectedLanguage){
  
      const ru = languages.find((elem)=>{
        if(elem?.id==0) return true;
        return false;
      })
      
      
      if(ru?.is_active==0 || ru?.id!=languages[0]?.id){
        const language = languages.find((elem)=>elem?.is_active==1)
       
        if(language){
         
            const languageActive = document.querySelector(`[data-google-id="${language?.id}"]`)
           
            localStorage.setItem('selectedLanguage', language?.id)
            languageActive.click()
          
        }
       
      }
    }
   
  }, [languages?.length])
  
  return(
    <>{
      (languages?.length > 0 && languages[0]!=undefined)  ? <Select
          listHeight={350}
          defaultValue={selectedLanguage ? languages[0]?.id : languages?.find((el)=>el?.is_active==1)?.id}
          style={{ width: 60 }}
          className="selectLanguages"
          onChange={handleChange}
          options={
            languages?.filter((elem)=>{
              if(elem?.is_active==1){
                return true
              }
              return false
            }).map((el)=>{
              return { value: el?.id, label: el?.language=='Русский' ? <img style={{width:'20px'}} src={iconRu} /> : el?.language=='Беларусский' ? <img style={{width:'20px'}} src={iconBe} /> : el?.language=='Казахский' ? <img style={{width:'20px'}} src={iconKz} /> : el?.language=='Армянский' ? <img style={{width:'20px'}} src={iconAr} /> : el?.language=='Кыргызский' ? <img style={{width:'20px'}} src={iconKg} /> : el?.language=='Узбекский' ? <img style={{width:'20px'}} src={iconYz} /> : el?.language=='Английский' ? <img style={{width:'20px'}} src={iconEn} /> : el?.language=='Французский' ? <img style={{width:'20px'}} src={iconFr} /> : el?.language=='Испанский' ? <img style={{width:'20px'}} src={iconIs} /> : el?.language=='Индийский' ? <img style={{width:'20px'}} src={iconIn} /> : el?.language , className:'selectLanguagesOption', key: el?.id }
            })
            }
        /> : <></>
    }</>
  )
}

export default Translation;
import cn from 'classnames'
import { useEffect } from 'react'
import './index.css'


const Modal = ({ setActive, active = false, className = false, fullScreenMobile = true, children, styleContent = {} }) => {
  const addNoScroll = () => { document.body.style.overflow = 'hidden' }
  const removeNoScroll = () => { document.body.style.overflow = 'auto' }

  useEffect(() => {
    (active === true) ? addNoScroll() : removeNoScroll()
    return removeNoScroll
  }, [active])

  return <div className={cn('modal__background', { 'active': active })} onMouseDown={() => setActive(false)} onTouchStart={() => setActive(false)} >
    <div className={cn('modal__content', { [className]: className }, { 'fullScreenMobile': fullScreenMobile })} style={styleContent} onMouseDown={e => e.stopPropagation()} onTouchStart={e => e.stopPropagation()}>
      {active && children}
    </div>
  </div>
}


export default Modal

import classNames from 'classnames'
import { declOfNum } from '../../../helpers/declOfNum'
import s from './Task.module.css'

import {InputSelect} from "../../UI/InputNew";


export const TaskBalls = ({status,bindDirection, title = 'Тестовая часть', val = '0', maxVal = '100', valTitle = 'Набрано баллов', maxValTitle = 'Максимальное кол-во', type = 'balls', maxType = 'balls', edit }) => {

  return <>
    <h2 className="section-title">{title}</h2>
    <div className={s.balls}>
      <Booble value={val} title={valTitle} type={type} />
      <Booble value={maxVal} title={maxValTitle} edit={edit} type={maxType} />
      {title === 'Итого' && status === 5 && <InputSelect  {...bindDirection} />}
    </div>
  </>
}

const Booble = ({ title, value, type, edit }) => <div>
  <span className={s.ballTitle}>{title}</span>
  {typeof edit === 'function'
    ? <input className={classNames(s.booble, s.input)} value={value} onChange={edit} />

    : <span className={s.booble}>{value} {type === 'balls' ? declOfNum(value, ['балл', 'балла', 'баллов']) : type}</span>
  }

</div>

import { getInArr } from '../../../helpers/getInArr';
import { jsonConvert } from '../../../helpers/jsonConvert';

// значение по умолчанию для tipTap
const tipTapValueDefault = (text) =>
	text
		? {
				type: 'doc',
				content: [{ type: 'paragraph', content: [{ type: 'text', text }] }],
		  }
		: { type: 'doc' };
// поля значение, которых идут для tipTap
const tipTapList = ['question', 'key_text', 'prompt'];

export const formateData = {
	getAll: (data) => {
		const props = formateData.getValuesList(data.props);
		return { ...data, props };
	},
	getValuesList: (data) => {
		const props = {};
		for (let key in data) {
			const value = data[key];
			if (getInArr(key, tipTapList)) {
				const defaultValue = jsonConvert(value, 'json');
				if (typeof defaultValue === 'string') {
					props[key] = tipTapValueDefault(defaultValue);
					continue;
				} else if (!defaultValue.type) {
					props[key] = tipTapValueDefault();
					continue;
				}
			}
			props[key] = jsonConvert(value, 'json');
		}
		return props;
	},
};

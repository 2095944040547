import {menuApi} from "@/api";

const SET_MENU = "impulse/menu/SET_MENU";

const initialState = {
  items: [],
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU: {
      return {
        items: action.menu
      };
    }

    default:
      return state;
  }
};

export const actionSetMenu = (menu) => ({
  type: SET_MENU,
  menu,
});

export const fetchMenu = () => (dispatch) => {
  
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  
  menuApi
    .getMenu(selectedLanguage, 'basic')
    .then(({data}) => dispatch(actionSetMenu(data)))
};

export default menuReducer;

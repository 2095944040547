import { Color } from '@tiptap/extension-color'
import Underline from '@tiptap/extension-underline'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import classNames from 'classnames'
import s from './index.module.css'
import { useOutside } from '../../../hooks'
import SelectColor from '../SelectColor'
import { useEffect, useState } from 'react'
import Math from './extension-math'
import './styles.css'

import Code from '@tiptap/extension-code'
import Document from '@tiptap/extension-document'
import Link from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import  { useCallback } from 'react'


const MenuBar = ({ className, editor }) => {
  const [color, setColor] = useState('#000000')
  const [ref, isShow, setIsShow] = useOutside(false)

  const onSetColor = color => {
    const colorVal = (color?.target?.value) ? (color?.target?.value) : color
    editor.chain().focus().setColor(colorVal).run()
    setColor(colorVal)
    setIsShow(false)
  }

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('Введите ссылку', previousUrl)

    if (url === null) {
      return
    }
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()
      return
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  if (!editor) return null

  const action = type => {
    switch (type) {
      case 'bold': editor.chain().focus().toggleBold().run()
        break
      case 'italic': editor.chain().focus().toggleItalic().run()
        break
      case 'underline': editor.chain().focus().toggleUnderline().run()
        break
      case 'strike': editor.chain().focus().toggleStrike().run()
        break
      case 'bulletList': editor.chain().focus().toggleBulletList().run()
        break
      case 'orderedList': editor.chain().focus().toggleOrderedList().run()
        break
      case 'math': editor.chain().insertContent(' ').toggleMath().insertContent(' ').run()
        break
      default: break
    }
  }

 

  return <div className={classNames(s.menuWrap, { [className]: className })}>
    <div className={s.menu}>

      <button onClick={() => action('bold')} className={classNames(s.btn, { [s.active]: editor.isActive('bold') })} >
        <svg className={s.ico} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"></path>
          </g>
        </svg>
      </button>

      <button onClick={() => action('italic')} className={classNames(s.btn, { [s.active]: editor.isActive('italic') })} >
        <svg className={s.ico} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z"></path>
          </g>
        </svg>
      </button>

      <button onClick={() => action('underline')} className={classNames(s.btn, { [s.active]: editor.isActive('underline') })}>
        <svg className={s.ico} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z"></path>
          </g>
        </svg>
      </button>

      <button onClick={() => action('strike')} className={classNames(s.btn, { [s.active]: editor.isActive('strike') })}>
        <svg className={s.ico} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"></path>
          </g>
        </svg>
      </button>


      <div ref={ref} className={s.colorWrap}>
        <span style={{ backgroundColor: color }} onClick={() => setIsShow(!isShow)} className={s.color}></span>
        <div className={classNames(s.colorContainer, { [s.show]: isShow })}>
          <SelectColor value={color} onClick={onSetColor} className={s.colorSelect} type='code' />
        </div>
      </div>

      <label className={classNames(s.btn)}>
        <svg className={s.ico} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <path d="M5 5v3h14V5H5zM4 3h16a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm2 9h6a1 1 0 0 1 1 1v3h1v6h-4v-6h1v-2H5a1 1 0 0 1-1-1v-2h2v1zm11.732 1.732 1.768-1.768 1.768 1.768a2.5 2.5 0 1 1-3.536 0z" />
        </svg>
        <input type="color" className={s.colorI} onInput={onSetColor} value={editor.getAttributes('textStyle').color ? editor.getAttributes('textStyle').color : ''} />
      </label>



      <button onClick={() => action('bulletList')} className={classNames(s.btn, { [s.active]: editor.isActive('bulletList') })} >
        <svg className={s.ico} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M8 4h13v2H8V4zM4.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 6.9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"></path>
          </g>
        </svg>
      </button>

      <button onClick={() => action('orderedList')} className={classNames(s.btn, { [s.active]: editor.isActive('orderedList') })} >
        <svg className={s.ico} width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M8 4h13v2H8V4zM5 3v3h1v1H3V6h1V4H3V3h2zM3 14v-2.5h2V11H3v-1h3v2.5H4v.5h2v1H3zm2 5.5H3v-1h2V18H3v-1h3v4H3v-1h2v-.5zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"></path>
          </g>
        </svg>
      </button>

      <button onClick={() => action('math')} className={classNames(s.btn, { [s.active]: editor.isActive('math') })} >
        <svg className={s.ico} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
          <path d="M20.3125 13.5H18.75L17.1875 16.4227L15.625 13.5H14.0625L16.332 17.25L14.0625 21H15.625L17.1875 18.1508L18.75 21H20.3125L18.0453 17.25L20.3125 13.5Z" />
          <path d="M14.8437 4.5V3H10.8698C10.4794 2.99836 10.1026 3.13773 9.81432 3.39044C9.52603 3.64315 9.34728 3.99072 9.31359 4.3641L8.80359 9.75H5.46875V11.25H8.66148L7.88023 19.5H3.90625V21H7.88023C8.27063 21.0016 8.64738 20.8623 8.93568 20.6096C9.22397 20.3568 9.40272 20.0093 9.43641 19.6359L10.2306 11.25H14.0625V9.75H10.3726L10.8698 4.5H14.8437Z" />
        </svg>
      </button>
      <div className={s.link}>
        <button onClick={setLink}>
          Создать ссылку
        </button>
        <button
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive('link')}
        >
          Сбросить
        </button>
      </div>
    </div>
  </div >
}

const Tiptap = ({ placeholder = '', value = '', error, setValue = () => { }, ...props }) => {
  const [stateValue, setStateValue] = useState(value);
  const [flag, reRenderEditor] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!value?.type || value?.content !== stateValue?.content) {
      reRenderEditor(!flag)
    }
  }, [value]);

  const editor = useEditor({
    editable: !props?.disabled,
    extensions: [StarterKit, Color, Underline, TextStyle, Math,  Document, Paragraph, Text, Code, Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: 'https',
    })],
    content: typeof value === 'object' ? value : String(value),
    onUpdate: ({ editor }) => {
      const json = editor.getJSON()
      setStateValue(json)
      setValue(json)
    },
  }, [flag]);

  const [ref, show, setIsShow] = useOutside(false)

  const onHove = () => setIsShow(true)

  const condition = () => {
    let flag = true
    if (value?.content?.length > 1) flag = false
    if (value?.content?.length > 0 && (value?.content[0].content !== undefined || value?.content[0].type === 'math')) flag = false
    return flag
  }

  return <div ref={ref} onFocus={onHove} className={`${s.wrapper} wrapperTiptapLibrary tiptapStyle`} placeholder={condition() ? placeholder : ''}>
    <MenuBar onHove={onHove} editor={editor} className={classNames({ [s.show]: show })} />
    <EditorContent editor={editor}
                   placeholder={condition() ? placeholder : ''}
                   className={classNames(s.text, 'wrapperTiptapLibraryText', { [s.error]: error })}
    />
  </div>
}

export default Tiptap


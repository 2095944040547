import noAvatar from "@/images/noAvatar.png";

export const getRightWord = (value, words) =>{
    value = Math.abs(value) % 100;
    var num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if( num == 1) return words[0];
    return words[2];
};

const screenWidth = window.innerWidth;
// const screenHeight = window.innerHeight;
const isMobile = screenWidth < 540;
export const checkWidth = (page, unReadChats) => {
    if (isMobile && page !== 'default') {
        return screenWidth * 0.97;
    } else if (!isMobile && page !== 'default') {
        return screenWidth * 0.8;
    } else if (!isMobile && page === 'default') {
        if (unReadChats?.length === 0) {
            return 100
        } else {
            return 232
        }
    } else if (isMobile && page === 'default') {
        if (unReadChats?.length === 0) {
            return 100
        } else {
            return screenWidth * 0.9;
        }
    }
}
export const checkHeight = (page) => {
    const screenHeight = window.innerHeight;
    if (isMobile && page !== 'default') {
        return screenHeight * 0.8;
    } else if (!isMobile && page !== 'default') {
        return screenHeight * 0.8;
    } else if (!isMobile && page === 'default') {
        return 60
    } else if (isMobile && page === 'default') {
        return 60
    }
}

export const checkMinWidth = (page, unReadChats) => {
    if (page === 'default' && unReadChats.length>0) {
        return 203
    } else if (page === 'default' && unReadChats.length === 0) {
        return 100
    } else if (page !== 'default' && !isMobile) {
        return 500
    } else {
        return screenWidth * 0.8
    }
}

export const getAvatar = (path, photo = noAvatar) => {
    if (path) {
        return /^http(s):\/\/.*/.test(path)
            ? path
            : process.env.REACT_APP_PATH_FILE + path
    }

    return photo;
}

import Button from '../../../../UI/Button'
import s from './index.module.css'
import Modal from '../../../../common/Modal'
import Select from '../../../../UI/Select'
import Task from '../../../../common/Task'


const ProbeTasks = props => {
  return <>

    <div className={s.list}>
      {props.probeTask?.map((item, idx) => <Task key={item.id ?? `dev-${item.devId}`} props={{ ...item, category: 'edit', 'direction_id': props.directionID }} setStatus={() => { }} title={idx + 1} saveLS={props.saveLS} moveLS={props.moveLS} removeLS={props.removeLS} cancelLS={props.cancelLS} staticData={props.staticData} />)}
    </div>


    <div className={s.bottomBtns}>
      <Button styles='accent' action={() => props.setShow(true)}>Добавить задание</Button>
    </div>


    <Modal active={props.show} setActive={props.setShow}>
      <h2 className="modal__title">Создать новую задачу</h2>
      <div className={s.modalBody}> <Select {...props.bindType} /> </div>
      <Button styles='accent' action={props.onAdd}>Выбрать</Button>
    </Modal>
  </>
}

export default ProbeTasks

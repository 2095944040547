import s from "./UserImage.module.css";
import {AvatarDefaultIcon} from "@/utils/icons";
import React from "react";
import {getAvatar} from "@common/Chat/helpers";
import classNames from "classnames";
import {Image} from "antd";

const UserImage = ({path, customClass = '', width = 48, height = 48, previewDisabled = true}) => {
    return (
        <div className={classNames(s.rowImage, customClass)}>
            {!previewDisabled && <div className={classNames(s.rowImage, customClass)}>
                <img src={getAvatar(path)} alt={''} className={s.img}/>
            </div>}
            {previewDisabled && <Image.PreviewGroup>
                <Image
                    width={width}
                    height={height}
                    src={getAvatar(path, '')}
                    style={{borderRadius: '50%'}}
                    className={s.img}
                    onClick={(e) => e.stopPropagation()}
                />
            </Image.PreviewGroup>}

        </div>
    )
}
export default UserImage;
import s from './AuthPlaceholder.module.css'
import img from '../../../../images/atTable.png'

const AuthPlaceholder = () => {
  return (
  <div className={s.wrapper}>
    <h2 className={s.title}>Учитесь по-другому</h2>
    <h3 className={s.subtitle}>Авторизуйтесь, чтобы начать заниматься</h3>
    <img className={s.img} src={img} alt="" />
  </div>
)}

export default AuthPlaceholder

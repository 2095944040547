import { useDispatch, useSelector } from "react-redux"
import { removeAlert, selectGetAlerts } from "@store/alerts.reducer"
import s from "./Alerts.module.css"
import cn from 'classnames'
import { GetIco } from "../../../utils/icons"
import CopyText from "../../../utils/CopyText"
import React, {useEffect} from "react"
import { jsonConvert } from "../../../helpers/jsonConvert"
import {IconImage, IconVideo, IconAudio, IconWord, IconExcel, IconPdf} from "@UI/Icons";

const Alerts = () => {
  const alerts = useSelector(selectGetAlerts)
  if (alerts.length < 1) return null
  return <div className={s.alerts}>
    {alerts?.map(alert => <Alert key={alert.id}{...alert} />)}
  </div>
}

export default Alerts

const Alert = props => {
  const dispatch = useDispatch()

  const [exit, setExit] = React.useState(false)
  const [width, setWidth] = React.useState(0)
  const [intervalID, setIntervalID] = React.useState(null)

  useEffect(() => {
    if (props.sound) {
      const audio = new Audio('/audio/notification.mp3')
      audio.play()
    }
  }, []);

  const handleStartTimer = () => {
    if (props.timer) {
      const id = setInterval(() => {
        setWidth(prev => {
          if (prev < 100) return prev + 0.5
          clearInterval(id)
          return prev
        })
      }, 20)
      setIntervalID(id)
    }
  }
  const handlePauseTimer = () => clearInterval(intervalID)
  const handleClickClose = () => {
    handlePauseTimer()
    setExit(true)
    setTimeout(() => {
      dispatch(removeAlert(props.id))
    }, 300);
  }

  const handleClickCopy = () => CopyText(props.text)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { if (width === 100) handleClickClose() }, [width])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { handleStartTimer() }, [])

  const textOriginal = jsonConvert(props.text)

  const typeText = (typeof textOriginal === 'object' && textOriginal?.length > 0) ? 'list' : 'string'

  const styles = cn(s.alert, [s[props.type]], { [s.exit]: exit })

  const handleClick = () => {
    props.onClick?.()
  }

  const getFileIcon = (file) => {
    switch (file.type) {
      case 'video':
        return <IconVideo />
      case 'audio':
        return <IconAudio />
      case 'image':
        return <IconImage />
      case 'word':
        return <IconWord />
      case 'excel':
        return <IconExcel />
      case 'pdf':
        return <IconPdf />
      default:
        return <></>
    }
  }

  const text = props.text ? String(props.text) : '';

  return <div onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer} onClick={handleClick} className={styles}>
    <button className={s.btnClose} onClick={handleClickClose}><GetIco icon='cross' /></button>
    <h3 className={s.title}>{props.title}</h3>
    {typeText === 'string'
      ? <p className={s.text}>{text.slice(0, 70)}{text.length > 70 && '...'}</p>
      : <ul className={s.list}>
        {textOriginal?.map((text, i) => <li key={i}>{text}</li>)}
      </ul>
    }

    {props.links?.map((link, i) => <a key={i} href={link.url} className={s.link}>
      {link?.title || link?.url}
    </a>)}

    {props.files?.map((file, i) => <span key={i} className={s.file}>
      {getFileIcon(file)}
      {file?.name || 'Файл'}
    </span>)}

    {props.copy && props.type === 'error' && <button className={s.btnCopy} onClick={handleClickCopy}>Скопировать данные ошибки</button>}
    <div className={s.bar} style={{ width: `${width}%` }}></div>
  </div >
}

import classNames from 'classnames'
import { jsonConvert } from '../../../../helpers/jsonConvert'
import { AnswerButton } from '../AnswerButton/AnswerButton'
import { useTaskHomeWork } from '../hooks/useTaskHomeWork'
import s from './Missing.module.css'


export const Missing = ({ type,  ...props }) => {
  switch (type) {
    case 'decided': return <Decided {...props} />
    case 'correction': return <Correction {...props} />
    default: return <Task hist={props?.hist}  {...props} />
  }
}

const Correction = ({ omissions }) => {
  return <div className={s.answerContainer}>
    <p className={classNames(s.text, s.textCorrection)}>
      {omissions?.map((el, i) => {
        if (el.answer === 1) return <span key={i} >
          {el.question}
          <input className={s.booble} disabled={true} placeholder='Напишите ответ' />
        </span>
        return <span key={i} >{el.question}</span>
      })}
    </p>
  </div >
}

const Decided = ({ userRole, ...props }) => {
  const answer = jsonConvert(props?.params?.result?.answer, 'json')
  const correctAnswer = jsonConvert(props?.params?.result?.correct_answer, 'json')

  return <div className={s.answerContainer}>
    <p className={s.text}>
      {props?.answer_options?.map((el, i) => {
        if (el?.answer === 1) return <span key={i} >
          {el.question}
          {correctAnswer.length && <span className={classNames(s.booble, { [s.accent]: correctAnswer[i]?.text === answer[i]?.text }, { [s.red]: correctAnswer[i]?.text !== answer[i]?.text })}>{i + 1} {answer[i]?.text}</span>}

        </span>
        return <span key={i} >{el.question}</span>
      })}
    </p>

    <div className={s.answerWrap}>
      <span className={classNames(s.capsuleLeft)}>Правильные ответы</span>
      {correctAnswer.length > 0 && correctAnswer?.map((el, i) => <span key={i} className={s.capsuleRight}>{`${i + 1} ${el.text}`}</span>)}
    </div>
  </div >
}

const Task = ({  params,hist, ...props }) => {
  const { onAnswer, answer, status, hint, video, correctAnswer } = useTaskHomeWork(params,  { ...props, type: 'arr' })

  const change = (state, index) => {
    const newList = [...answer.get]
    newList[index] = state
    answer.set(newList)
  }


  return <>
    <p className={s.text}>
      {props.answer_options?.map((el, i) => {
        if (el.answer === 1) return <Item key={i} status={status} value={answer.get[i]} change={e => change(e, i)} {...el} />
        return <span key={i} >{el.question}</span>
      })}
    </p>


    {correctAnswer &&  hist !== '?redo=ok' &&  params.type !== 'control' && <div className={s.answerWrapTask}>
      <span className={classNames(s.capsuleLeft)}>Правильные ответы</span>
      {correctAnswer?.map((el, i) => <span key={i} className={s.capsuleRight}>{i + 1} {el.text}</span>)}
    </div>}

    <AnswerButton  {...{  status, answer, onAnswer, hint, video  }} />
  </>
}

const Item = ({ value, answer, change, allowed, status, state, ...props }) => {

  let onChange = e => {
    const input = e.target
    const value = input.value.toLowerCase()
    change({ text: value })

    if (value.length < 50) {
      const div = input.nextSibling
      div.innerText = value
      const width = div.clientWidth
      input.style.width = width + 'px'
    }
  }
  return <>
    {props.question}
    <span className={s.wrapper}>
      <input
        className={classNames(
          s.booble,
          // { [s.accent]: (status === 'chance' || !allowed) && state?.isRight },
          // { [s.red]: (status === 'chance' || !allowed) && !state?.isRight }
        )}
        value={value?.text ?? ''}
        disabled={status > 4}
        placeholder='Напишите ответ'
        onChange={onChange} />
      <span className={s.booble}>Напишите ответ</span>
    </span>
  </>
}

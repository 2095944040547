/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AuthService } from "@/services/AuthService";

export const AuthApp = () => {
  const search = useLocation().search;
  const history = useHistory();

  const login = async () => {
    const params = new URLSearchParams(search);
    const app_id = params.get("app_id");
    const uuid = params.get("uuid");
    // const redirect = params.get('redirect');
    const group = params.get("group");
    const course = params.get("course");

    const { status } = await AuthService.appLogin(app_id, uuid);
    if (status === "ok") {
      history.push(`/courses/my/${group}/${course}`);
    }
  };
  useEffect(() => {
    login();
  }, []);
  return null;
};

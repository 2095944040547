import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import { selectIsAuth } from '@store/profile.reducer'
import AuthPlaceholder from './AuthPlaceholder'
import s from './LayoutAuth.module.css'
import Logo from './Logo/Logo'
import { useScreenWidth } from '@hooks/useScreenWidth'

const LayoutAuth = ({ children }) => {
  const isAuth = useSelector(selectIsAuth)
  const isMobile = useScreenWidth(1024)

  if (isAuth) return <Redirect to='/' />

  return <div className={s.body}>
    <Logo />
    <div className={s.wrapper}>
      {children}
      {!isMobile && <AuthPlaceholder />}
    </div>

  </div>
}

export default LayoutAuth

import { useEffect, useRef, useState } from 'react';

export const useOutside = (initialVisible) => {
	const [isShow, setIsShow] = useState(initialVisible);
	const ref = useRef(null);
	const handleClickOutside = (event) => {
		// @ts-ignore
		if (ref.current && !ref.current.contains(event.target)) setIsShow(false);
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, true);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside, true);
		};
	});

	return [ref, isShow, setIsShow];
};

import noPhoto from '../images/noPhoto.png';
import noPhotoFemale from '../images/noPhotoFemale.png';

export const getAvatar = (user, src=false, srcPath='') => {

	if (src && srcPath) {
		return /^http(s):\/\/.*/.test(srcPath)
			? srcPath
			: process.env.REACT_APP_PATH_FILE + srcPath
	}
	
	const photo = user?.userProfile?.sex === 'Female' ? noPhotoFemale : noPhoto;

	if (user?.avatar_path) {
		return /^http(s):\/\/.*/.test(user.avatar_path)
			? user.avatar_path
			: process.env.REACT_APP_PATH_FILE + user.avatar_path
	}

	return photo;
};

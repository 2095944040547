import { useRef, useState } from "react";
import s from "./index.module.css";
import { GetIco } from "@/utils/icons";
import track from "./treck.mp3";

function convertTime(timestamp) {
  let hours = Math.floor(timestamp / 60 / 60);
  let minutes = Math.floor(timestamp / 60) - hours * 60;
  let seconds = Math.floor(timestamp % 60);
  if (seconds < 10) seconds = "0" + seconds;
  let result = "";

  if (hours > 0) result += hours + ":";
  result += minutes + ":";
  result += seconds;

  return result;
}

const AudioPlayer = ({ audioTrack, className }) => {
  let [statusPlay, setStatus] = useState(false);
  let [dragFlag, setDragFlag] = useState(false);
  let [time, setTime] = useState("0:00");

  const audio = useRef();
  const trackTime = useRef();

  let audioPlay;

  const Play = (e) => {
    e.stopPropagation()
    let audioI = audio.current;
    let trackTimeI = trackTime.current;
    setStatus(true);
    audioI.play();
    audioPlay = setInterval(() => {
      let audioTime = Math.round(audioI.currentTime);
      let audioLength = Math.round(audioI.duration);
      setTime(convertTime(audioI.currentTime));
      trackTimeI.style.cssText = `--value: ${(audioTime * 100) / audioLength}%`;
      if (audioI.ended) {
        setStatus(false);
        clearInterval(audioPlay);
      }
    }, 100);
  };

  const Pause = (e) => {
    e.stopPropagation()
    let audioI = audio.current;
    setStatus(false);
    audioI.pause();
    clearInterval(audioPlay);
  };



  const setTrackerProgress = (e) => {
    e.stopPropagation()
    let audioI = audio.current;
    let trackTimeI = trackTime.current;
    let audioLength = Math.round(audioI.duration);
    setTime(convertTime(audioI.currentTime));

    let offset = Math.round(e.nativeEvent.layerX)
    let itemWidth = Math.round(e.target.clientWidth)
    let positionInPercent = (offset / itemWidth) * 100
    let positionOffset = Math.round(audioLength * (positionInPercent / 100))
    if (positionInPercent === 'Infinity' || !e.target.getAttribute('data-audio-track')) {
      return
    }
    audioI.currentTime = positionOffset
    trackTimeI.style.cssText = `--value: ${positionInPercent}%; cursor: ${dragFlag ? 'grabbing' : 'pointer'};`;
  }


  return (
    <div className={s.wrapper}>
      <div
        ref={trackTime}
        onMouseDown={() => setDragFlag(dragFlag = true)}
        onMouseMove={(e) => dragFlag ? setTrackerProgress(e) : ''}
        onMouseUp={() => setDragFlag(dragFlag = false)}
        onClick={(e) => setTrackerProgress(e)}
        className={s.trackTime}
        style={{ "--value": "0%" }}
        data-audio-track

      >
        <div className={s.text}>
          {statusPlay ? (
            <button
              onClick={Pause}
              className={`${s.button} ${s.play}`}
              data-audio-play
            >
              <GetIco icon="pause" />
            </button>
          ) : (
            <button
              onClick={Play}
              className={`${s.button} ${s.play}`}
              data-audio-play
            >
              <GetIco icon="play" />
            </button>
          )}
        </div>
        <audio ref={audio} src={audioTrack ? audioTrack : track} className={s.audio}></audio>
      </div>

      <span className={s.timer} data-audio-timer>
        {time}
      </span>
    </div>
  );
};

export default AudioPlayer;

import '../styles.css';
import s from './CreateChat.module.css'
import {CloseIcon} from "@/utils/icons";
import CustomRadioElem from "@common/Chat/components/CustomRadioElem/CustomRadioElem";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Input, Spin} from "antd";
import classNames from "classnames";
import {useInputNew} from "@UI/InputNew/useInputNew";
import {chatApi} from "@/api";
import Participant from "@common/Chat/components/Participant";
import * as InputNew from "@UI/InputNew/Input";
import '../styles.css';
import {useAddAlert} from "@/hooks/useAddAlert";

const CreateChat = ({setPage, chat, typeAction}) => {
    const [participants, setParticipants] = useState([])
    const addAlert = useAddAlert();
    const [users, setUsers] = useState([])
    const chatTitle = chat?.title ? chat.title : null;
    const [title, setTitle] = useState(chatTitle);
    const [file, setFile] = useState(null);
    const [isFocus, setIsFocus] = useState(false);
    const [params, setParams] = useState({
        search: '',
        count: 20
    })
    const elem = useRef(false)

    const getUsers = () => {
        chatApi.getUserList(params)
            .then(response => {
               if (response?.data) {
                   setUsers(response?.data.slice(0,10))
               }
            })
        ;
    }

    useEffect(() => {
        getUsers()
    }, [params]);
    const edit = (key, val) => setFile(val);
    const [bindFile] = useInputNew({
        name: "file",
        value: file,
        onChange: (e) => edit("file", e),
        placeholder: "",
        typeValid: "img",
    });

    const save =(e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('file',file);
        if (typeAction === 'create') {
            chatApi.createChat({
                name: title,
                users: participants
            })
                .then((res) => {
                    if (res.status === 200) {
                        setPage('inChat', res?.data?.id)
                        chatApi.updateChatImage(res?.data?.id, formData)
                    } else {
                        addAlert({text: res.statusText})
                    }
                })
        }
        if (typeAction === 'add') {
                chatApi.addMember({
                    chatId: chat.id,
                    users: participants,
                }).then((res) => {
                    setPage('info', chat.id)
                })
        }
    }

    const isSubmitBtnActive = () => {
        if (typeAction === 'create') {
            return participants?.length > 1 && title?.length > 0
        } else if (typeAction === 'add') {
            return participants?.length >=1
        }
    }

    const parentHeight = elem?.current?.parentElement?.offsetHeight || 0

    return (
        <div ref={elem} className={classNames(s.page, 'create-chat')} style={{height: parentHeight - 120}}>
            <div className={s.header}>
                <span className={s.title}>{chat !== null ? 'Добавление новых участников' : 'Создание чата'}</span>
                <button className={s.close} onClick={() => setPage('main')}>
                    <CloseIcon/>
                </button>
            </div>
            <form className={classNames(s.pageForm)} style={{height: parentHeight - 160}}>
                <div className={s.multipleSelect}>
                    {participants.map((participant) => (
                        <Participant participant={participant} setParticipants={setParticipants} participants={participants} key={participant.id}/>
                    ))}
                    {(!isFocus && participants?.length === 0)  && <label htmlFor={'search'} className={s.label}>Введите ФИО </label>}
                    <input
                        id={'search'}
                        className={s.inputSearch}
                        value={params.search}
                        onChange={(e) => setParams({...params, search: e.target.value})}
                        onFocus={()=>setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                    />
                </div>
                <div className={classNames(s.users, 'new-design-scroll')}>
                    {users && users.map(user => (
                        <div className={s.user} key={user.id}>
                            <CustomRadioElem
                                user={user}
                                addUsers={setParticipants}
                                participants={participants}
                                setParams={setParams}
                            />
                        </div>
                    ))}
                </div>
                <div className={s.footer}>
                    {chat == null && <div className={s.inputWr}>
                        <InputNew.InputFileNewDesign
                            className={s.inputFile}
                            {...bindFile}
                            disabled={typeAction === 'add'}
                            newDesign
                            placeholder=''
                            icon='picture'
                        />
                        <div className={s.title}>
                            <Input
                                disabled={typeAction === 'add'}
                                className={s.input}
                                value={title}
                                placeholder={'Введите название чата'}
                                onChange={e => setTitle(e.target.value)}
                            />
                        </div>
                    </div>}

                    <button type='submit'
                            className={classNames(chat !== null ? s.center : '', s.submitBtn)}
                            onClick={save}
                            disabled={!isSubmitBtnActive()}
                    >
                        {chat !== null ? 'Обновить данные' : 'Создать чат'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreateChat;
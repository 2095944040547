import {SettingsService} from "@/services/SettingsService";
import {SETTINGS_TYPES} from "@/constant";

const setSettingsFromServer = (setSettings, type=SETTINGS_TYPES.GENERAL) => {
    const fetchSettings = async () => {
        const fetchedSettings = await SettingsService.getCorrected(type);
        if (fetchedSettings === null) {
            setTimeout(fetchSettings, 500); // Повторная попытка через 0.5 секунды
        } else {
            setSettings(fetchedSettings); // Устанавливаем полученные настройки в состояние
        }
    };
    fetchSettings();
}
export default setSettingsFromServer;

import {
  directionAPI,
  directionGroupApi,
  staticDataAPI,
  supportAPI,
  thematicsAPI,
} from "../api";
import { sorting } from "../helpers/sorting";

const GET_ROLE = "impulse/staticData/GET_ROLE";

const GET_THEMATICS = "impulse/staticData/GET_THEMATICS";
const ADD_THEMATICS = "impulse/staticData/ADD_THEMATICS";
const UPD_THEMATICS = "impulse/staticData/UPD_THEMATICS";
const DEL_THEMATICS = "impulse/staticData/DEL_THEMATICS";

const DEL_GROUPS = "impulse/staticData/DEL_GROUPS";

const UPD_GROUPNAME = "impulse/staticData/UPD_GROUPNAME";
const UPD_GROUPTEACHERNAME = "impulse/staticData/UPD_GROUPTEACHERNAME";

const GET_DIRECTIONS = "impulse/staticData/GET_DIRECTIONS";
const ADD_DIRECTIONS = "impulse/staticData/ADD_DIRECTIONS";
const DEL_DIRECTIONS = "impulse/staticData/DEL_DIRECTIONS";

const GET_SUPPORT_TYPES = "impulse/staticData/GET_SUPPORT_TYPES";

let initialState = {
  roles: [],
  thematics: [],
  directions: [],
  supportTypes: {},
  groups: [],
};

const staticDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLE:
      return { ...state, roles: action.roles };
    case GET_SUPPORT_TYPES:
      return { ...state, supportTypes: action.types };
    case GET_THEMATICS:
      return { ...state, thematics: action.thematics };
    case ADD_THEMATICS:
      return { ...state, thematics: [...state.thematics, action.thematic] };
    case DEL_THEMATICS:
      return {
        ...state,
        thematics: [...state.thematics.filter(({ id }) => id !== action.id)],
      };
    case DEL_GROUPS:
      return {
        ...state,
        groups: [...state.groups.filter(({ id }) => id !== action.id)],
      };
    case UPD_THEMATICS:
      return {
        ...state,
        thematics: [
          ...state.thematics.map((thematic) =>
            thematic.id === action.thematic.id ? action.thematic : thematic
          ),
        ],
      };

    case UPD_GROUPNAME:
      return {
        ...state,
        groups: [
          ...state.groups?.map((group) =>
            group.id === action.group.id ? action.group : group
          ),
        ],
      };

    case UPD_GROUPTEACHERNAME:
      return { ...state };

    case GET_DIRECTIONS:
      return { ...state, directions: action.directions };
    case ADD_DIRECTIONS:
      return { ...state, directions: [...state.directions, action.direction] };
    case DEL_DIRECTIONS:
      return {
        ...state,
        directions: [...state.directions.filter(({ id }) => id !== action.id)],
      };
    default:
      return state;
  }
};

const setRole = (roles) => ({ type: GET_ROLE, roles });
export const getRolesAction = () => (dispatch) =>
  staticDataAPI.getRole().then((res) => {
    dispatch(
      setRole(
        res.data.map((role) => ({ label: role.description, value: role.name }))
      )
    );
  });

export const setThematics = (thematics) => ({ type: GET_THEMATICS, thematics });
export const getThematicsAction = (conf) => (dispatch) =>
  thematicsAPI
    .getAll(conf)
    .then((res) => dispatch(setThematics(res.data.data?.sort(sorting))));

const addThematic = (thematic) => ({ type: ADD_THEMATICS, thematic });
export const addThematicAction = (data) => (dispatch) =>
  thematicsAPI
    .add({ data: data })
    .then((res) => dispatch(addThematic(res.data)));

const editThematic = (thematic) => ({ type: UPD_THEMATICS, thematic });
const removeThematic = (id) => ({ type: DEL_THEMATICS, id });

export const editThematicAction = (data) => (dispatch) =>
  thematicsAPI
    .upd({ id: data.id, data: data })
    .then((res) => dispatch(editThematic(res.data)));

export const removeThematicAction = (id) => (dispatch) =>
  thematicsAPI.remove({ id }).then((res) => {
    if (res.status === 204) {
      dispatch(removeThematic(id));
    }
  });

// group
const removeGroup = (id) => ({ type: DEL_GROUPS, id });

export const removeGroupAction = (id) => (dispatch) =>
  directionGroupApi.removeGroup({ id }).then((res) => {
    if (res.status === 204) {
      dispatch(removeGroup(id));
    }
  });

const editGroup = (group) => ({ type: UPD_GROUPNAME, group });

export const editGroupAction = (data) => (dispatch) => {
  directionGroupApi
    .updGroupName({ id: data.id, data: data })
    .then((res) => dispatch(editGroup(res.data)));
};

//const editTeacher = (group) => ({ type: UPD_GROUPTEACHERNAME, group });

export const editTeacherAction = (data) => (dispatch) => {
  directionGroupApi.updGroupTeacher({
    id: data.group_id,
    teacher_id: data.teacher_id,
  });
  // .then((res) => {
  //   dispatch(editTeacher(res.data));
  // });
};

export const setDirections = (directions) => ({
  type: GET_DIRECTIONS,
  directions,
});
export const getDirectionsAction = (conf) => (dispatch) =>
  directionAPI
    .getAll(conf)
    .then((res) => dispatch(setDirections(res.data.data?.sort(sorting))));

const removeDirection = (id) => ({ type: DEL_DIRECTIONS, id });
export const removeDirectionAction = (id) => (dispatch) =>
  directionAPI.remove({ id }).then(() => dispatch(removeDirection(id)));

const getSupportTypes = (types) => ({ type: GET_SUPPORT_TYPES, types });
export const actionGetSupportTypes = () => (dispatch) =>
  supportAPI.getTypes().then((res) => dispatch(getSupportTypes(res.data.data)));
export const selectorSupportTypes = (state) => state.staticData.supportTypes;

export default staticDataReducer;

import s from './index.module.css'
import style from '../../index.module.css'
import Range from '../../../../../UI/Range'
import { useEffect } from 'react'



export const Complexity = ({ data, edit }) => {
  const defaultVal = data?.complexity ? data.complexity : 3
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { edit(prev => ({ ...prev, complexity: defaultVal })) }, [])
  const onChange = e => edit(prev => ({ ...prev, complexity: +e.target.value }))
  return <div className={s.wrapper}>
    <span className={style.heading}>Уровень сложности:</span>
    <Range className={s.range} min={1} max={5} value={defaultVal} onChange={onChange} />
  </div>
}

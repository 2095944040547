import { useSelector } from 'react-redux'
import { getInArr } from '../../../helpers/getInArr'
import { getTasks } from '../../../selectors'
import Task from './Task'
import s from './TaskList.module.css'


export const TaskList = ({ list, data, ...props }) => {
  const tasks = useSelector(getTasks).filter(el => list === 'all' ? true : getInArr(el.id, list))

  const category = index => {
    const task = props?.tasks?.length && props?.tasks[index]
    if (props?.category) return props.category
    else if (task?.category === 'check') return 'check'
    else if (!task?.childResponse && props?.task?.category) return props?.task?.category
    else if (props?.work?.status !== "notCompleted") return 'decided'
    return 'dz'
  }

  if (!list?.length) return <div className={s.warning}>Передайте id заданий! (list=[0, 2, 3, ...])</div>


  return <div className={s.list}>
    {tasks?.map((task, index) => <Task key={task.id} {...props}
      props={{
        workId: props.workId,
        title: `Задание ${index + 1}`,
        ...task,
        ...data,
        category: category(index),
        ...(props?.tasks?.length && props?.tasks.find(taskP => taskP.id === task.id)),
      }} />)}
  </div>
}

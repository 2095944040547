import s from './ChatView.module.css';
import {ClipIcon, DeleteChatIcon, DotsIcon, SoundOffIcon, SoundOnIcon} from "@/utils/icons";
import {useRef, useState} from "react";
import classNames from "classnames";
import {useOutside} from "@/hooks";
import {useClickAway} from 'react-use';
import UserImage from "@common/Chat/components/UserImage/UserImage";
import {useSelector} from "react-redux";
import {selectProfile} from "@store/profile.reducer";
import {chatApi} from "@/api";
import {useAddAlert} from "@/hooks/useAddAlert";

const ChatView = ({chat, setPage, setHeader, setIsVisiblePopup, setType, setChatId, unReadChats}) => {
    const addAlert = useAddAlert()

    const [isActive, setIsActive] = useState(false);
    const [muted, setMuted] = useState(chat.muted)
    const menuRef = useRef(null)
    const [ref, isShow, setIsShow] = useOutside();
    const profile = useSelector(selectProfile);
    const myId = chat?.members.find(member => member.name === profile.name)

    const getPhoto = () => {
        if (chat?.members.length >= 2) {
            return chat.avatar;
        } else {
            const img = (chat?.members.find(member => member.id !== myId))
            return img?.avatar;
        }
    }

    const photo = getPhoto()

    const isFixing = false;
    const openMenu = (e) => {
        e.stopPropagation()
        setIsActive(!isActive)
    }

    const handleDelete = (e) => {
        e.stopPropagation()
        setChatId(chat.id)
        setType('remove')
        setHeader('Вы хотите удалить данный чат?')
        setIsVisiblePopup(true)
    }


    const handleClip = (e) => {
        e.stopPropagation()
        setType('clip')
        setHeader('Вы хотите закрепить данный чат?')
        setChatId(chat.id)
        setIsVisiblePopup(true)
    }

    const handleUnfix = (e) => {
        e.stopPropagation()
        setType('unfix')
        setHeader('Вы хотите открепить данный чат?')
        setChatId(chat.id)
        setIsVisiblePopup(true)
    }

    const handleMute = (e) => {
        e.stopPropagation()

        chatApi.mute(chat.id)
          .then(() => {
              setMuted(true)

              addAlert({
                  text: 'Уведомления были отключены'
              })
          })
          .catch(() => setMuted(false))
    }

    const handleUnmute = (e) => {
        e.stopPropagation()

        chatApi.unmute(chat.id)
          .then(() => {
              setMuted(false)

              addAlert({
                  text: 'Уведомления были включены'
              })
          })
          .catch(() => setMuted(true))
    }

    useClickAway(menuRef, () => {
        setIsActive(false);
    });

    const isUnreadMessage = unReadChats.find(el => el.chatId == chat.id);
    return (
        <>
            <div className={s.chat} onClick={() => setPage('inChat', chat.id)}>
                {chat.isFixed && <div className={s.clip}>
                    <ClipIcon width={'24'} heeight={'24'}/>
                </div>}
                <UserImage path={photo} previewDisabled={false}/>
                {chat.members.length > 1 && (
                    <div className={s.chatBody}>
                        <div className={s.title}>
                            {chat.name}
                        </div>
                        {chat?.lastMessage?.message && (
                            <div className={s.infoMember}>
                                <UserImage path={chat.lastMessage?.authorAvatar} customClass={s.photoMember} width={32}
                                           height={32} previewDisabled={false}/>
                                <div className={s.message}>
                                    {JSON.parse(chat?.lastMessage?.message)}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {chat.members.length === 1 && <div className={s.chatBody}>
                    <div className={s.title}>
                        {chat?.members[0].name}
                    </div>
                    <div className={s.message}>
                        {chat?.lastMessage?.message ? JSON.parse(chat?.lastMessage?.message) : ''}
                    </div>
                </div>}
                <div className={s.info}>
                    <div className={s.infoFooter}>
                        {isUnreadMessage && <div className={s.unread}>
                            {isUnreadMessage.count}
                        </div>}
                        {isFixing && <span className={s.clip}><ClipIcon/></span>}
                    </div>
                </div>
                <div className={s.dots} onClick={(e) => openMenu(e)}><DotsIcon/></div>
                <div className={classNames(s.menu, isActive ? s.active : '')} ref={menuRef}
                     onMouseLeave={() => setIsActive(false)}>
                    <div className={s.menuRow} onClick={handleDelete}>
                        <div className={s.menuIcon}><DeleteChatIcon/></div>
                        <div className={s.menuName}>Удалить чат</div>
                    </div>
                    {!chat?.isFixed && <div className={s.menuRow} onClick={handleClip}>
                        <div className={s.menuIcon}><ClipIcon width={'16'} height={'16'}/></div>
                        <div className={s.menuName}>Закрепить в списке чатов</div>
                    </div>}
                    {chat?.isFixed && <div className={s.menuRow} onClick={handleUnfix}>
                        <div className={s.menuIcon}><ClipIcon width={'16'} height={'16'}/></div>
                        <div className={s.menuName}>Открепить чат</div>
                    </div>}

                    {muted && <div className={s.menuRow} onClick={handleUnmute}>
                        <div className={s.menuName}>Включить уведомления</div>
                    </div>}

                    {!muted && <div className={s.menuRow} onClick={handleMute}>
                        <div className={s.menuName}>Выключить уведомления</div>
                    </div>}

                </div>
            </div>
        </>)
}

export default ChatView;
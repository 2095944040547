import Sidebar from '@common/Sidebar/SidebarNewDesign.jsx'
import Header from '@common/Header/HeaderNewDesign/index.jsx';
import BottomMenu from '@common/BottomMenu'
import Chat from '@common/Chat';
import classNames from "classnames";
import s from './LayoutNewDesign.css';
import {SettingsService} from "@/services/SettingsService";
import {SETTINGS_TYPES} from "@/constant";

const LayoutNewDesign = ({ children }) => {
  const settings = SettingsService.get(SETTINGS_TYPES.GENERAL)
  const chatEnabled = settings?.isChatEnabled === '1'

  return <div className={classNames('app-wrapper app-wrapper__new', s.sideWrapper)} >
    <Sidebar />
    <Header />
    {children}
    <BottomMenu />
    {chatEnabled && <Chat />}
  </div>
}

export default LayoutNewDesign;

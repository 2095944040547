import Modal from "@common/Modal"
import Supports from "@/components/modals/Supports/Supports"

// import ProblemSite from './ProblemSite'
// import CommunicationParent from './CommunicationParent'
// import EquipmentRequest from './EquipmentRequest'
// import SuggestionsImprovement from './SuggestionsImprovement'
// import ProposeTask from './ProposeTask'
// import Another from './Another'
// import PresentationSeminar from './PresentationSeminar'



const QModals = ({ type, active, setActive }) => {
  // const list = {   PresentationSeminar }
  // const Component = list[type]
  const close = () => setActive(false)

  return <Modal active={active} setActive={setActive}>

    {type === 'ProblemSite' && <Supports {...{
      title: 'Проблемы с сайтом?',
      description: 'Если что-то не работает - напиши нам об этом, мы сделаем все возможное, чтобы это исправить!',
      placeholder: 'Опишите проблему',
      support_type: 'site-problems',
      close
    }} />}

    {type === 'CommunicationParent' && <Supports {...{
      title: 'Связь с родителем',
      placeholder: 'Опишите причину связи с родителем',
      support_type: 'contact-parent',
      close
    }} />}

    {type === 'EquipmentRequest' && <Supports {...{
      title: 'Запрос оборудования',
      placeholder: 'Опишите ваш запрос',
      support_type: 'equipment',
      close
    }} />}

    {type === 'SuggestionsImprovement' && <Supports {...{
      title: 'Предложения по улучшению',
      description: 'Если вы хотите предложить улучшить конкретную тему, направление или курс - укажите это в письме',
      placeholder: 'Опишите ваш комментарий',
      support_type: 'improvement',
      close
    }} />}

    {type === 'ProposeTask' && <Supports {...{
      title: 'Предложить задачу/ошибка в задаче',
      placeholder: 'Опишите задачу',
      support_type: 'task',
      close
    }} />}

    {type === 'Another' && <Supports {...{
      title: 'Другая проблема',
      placeholder: 'Опишите проблему',
      support_type: 'other',
      close
    }} />}


    {type === 'check' && <Supports {...{
      title: 'Загрузите чек об оплате',
      placeholder: 'Комментарий',
      support_type: 'check',
      close
    }} />}

    {/* {Component && <Component setActive={setActive} />} */}
  </Modal>
}

export default QModals

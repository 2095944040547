import s from './index.module.css'
import style from '../../index.module.css'
import { examNumberAPI } from '../../../../../../api'
import { InputSelect } from '../../../../../UI/InputNew'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { useGetApi } from '../../../../../../hooks/useGetApi'
import { useMemo } from 'react'


export const ExamNumber = ({ data, edit, direction }) => {

  const filter = useMemo(() => {
    const res = []
    if (data?.direction_id) res.push([`[direction_id][in][]=${data?.direction_id}`])
    if (direction) res.push([`[direction_id][in][]=${direction}`])
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [bindSelect] = useInputNew({
    name: 'number_exam_id',
    value: data?.number_exam_id,
    onChange: e => edit(prev => ({ ...prev, 'number_exam_id': e })),
    placeholder: 'Номер',
    options: useGetApi(
      e => examNumberAPI.getAll(e),
      { filter },
      res => res?.data?.data?.map(item => ({ label: item.number, value: item.id }))
    )
  })

  return <div className={s.wrapper}>
    <span className={style.heading}>Номер из экзамена:</span>
    <InputSelect className={style.inputNew} {...bindSelect} />
  </div>
}

const SET_POPUP = 'impulse/popup/SET_POPUP';

let initialState = {
	profile: {
		isShow: false,
		title: '',
		text: '',
		position: { x: 0, y: 0 },
	},
};

const popupReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_POPUP:
			return {
				isShow: action.data.isShow,
				title: action.data.title,
				text: action.data.text,
				position: action.data.position,
			};
		default:
			return state;
	}
};

export const setPopup = (data) => ({ type: SET_POPUP, data });

export default popupReducer;

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import pageReducer from './page-reducer';
import usersReducer from './users-reducer';
import bannerReducer from './banners-reducer';
import popupReducer from './popup-reducer';
import communicationReducer from "./communication-reducer";

import profileReducer from './profile.reducer';
import staticDataReducer from './staticData';
import courseEditorReducer from './courseEditor-reducer';
//import groupEditorReducer from "./groupEditor-reducer";

import profileUserReducer from './profileUser.reducer';
import myGroupsReducer from './children/myGroups.reducer';
import seminarianReducer from './seminarian/seminarian.reducer';
import childrenReducer from './children/children.reducer';
import alertsReducer from './alerts.reducer';
import commonReducer from './common/common.reducer';
import downloadExcelReducer from "./excel.reducer";
import menuReducer from "./menu.reducer";
import menuShowReducer from './menuShowReducer-reducer';
import confirmReducer from "./confirm-reducer";
import lessonPreviewReduser from "./lessonPreview-reduser"
import languagesReduser from "./languages-reduser"

let reducers = combineReducers({
	page: pageReducer,
	users: usersReducer,
	banners: bannerReducer,
	communication: communicationReducer,
	popup: popupReducer,

	profile: profileReducer,
	profileUser: profileUserReducer,
	courseEditor: courseEditorReducer,
	//groupEditor: groupEditorReducer,
	staticData: staticDataReducer,
	myGroups: myGroupsReducer,
	seminarianR: seminarianReducer,
	childrenR: childrenReducer,
	alerts: alertsReducer,
	common: commonReducer,
	excel: downloadExcelReducer,
	menu: menuReducer,
	menuShow: menuShowReducer,
	confirm: confirmReducer,
	lessonPreview: lessonPreviewReduser,
	languages: languagesReduser
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducers,
	composeEnhancers(applyMiddleware(thunkMiddleware))
);

store.subscribe(() => (document.title = store.getState().page.titlePage));

export default store;

import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { DragItem } from './DragItem'


export const DragList = ({ state, setState, ...props }) => {
  const moveCard = React.useCallback((dragIndex, hoverIndex) => setState((prevCards) =>
    update(prevCards, { $splice: [[dragIndex, 1], [hoverIndex, 0, prevCards[dragIndex]],], })
  ), [setState])

  const renderCard = React.useCallback((card, index) => {
    const key = Math.floor(Math.random() * 1000)

    return <DragItem key={`${card.id}_${key}`} {...{ ...card, index, moveCard, ...props }} />
  }, [props.select.values])

  return <DndProvider backend={HTML5Backend}>{state.map((card, i) => renderCard(card, i))}</DndProvider>
}

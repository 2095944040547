import {useState, useRef, useEffect} from "react";
import {useClickAway} from "react-use";
import {createPortal} from "react-dom";
import s from './Popup.module.css';
import classNames from "classnames";
import {CloseIcon} from "@/utils/icons";
import {useInputNew} from "@UI/InputNew/useInputNew";
import * as InputNew from "@UI/InputNew/Input";
import ProgressBar from "@UI/ProgressBar";
const Popup = ({
   header,
   closeCallback,
   setAction,
   file,
   setFile,
//    setImage,
   action,
   updateFile = false,
   uploading = false,
   progress = 0,
}) => {
    const [isClosePopup, setIsClosePopup] = useState(false);
    const delay = 1000;
    const modalRef = useRef(null);
    const timerRef = useRef();
    const [bindFile] = useInputNew({
        name: 'file',
        value: file,
        onChange: (e) => setFile(e),
    });

    useClickAway(modalRef, () => {
        setIsClosePopup(true);
    });

    useEffect(() => {
        if (isClosePopup) {
            timerRef.current = setTimeout(() => {
                closeCallback();
            }, delay);
        }

        return () => clearTimeout(timerRef.current);
    }, [closeCallback, isClosePopup]);


    return createPortal(
        <>
            <div className={classNames(s.blur, { [s.blurOut]: isClosePopup })} />
            <div className={s.popupWrapper}>
                <div ref={modalRef} className={classNames(s.popup, {[s.popupOut]: isClosePopup})}>
                    <div className={s.popupHeader}>
                        <span dangerouslySetInnerHTML={{__html: header}}/>
                        <button className={s.close} type="button" onClick={() => setIsClosePopup(true)}>
                            <CloseIcon width={'24'} height={'24'}/>
                        </button>
                    </div>

                    {updateFile && !uploading && (
                      <div className={s.childrenWrapper}>
                          <InputNew.InputFileNewDesign
                            className={s.inputFile}
                            {...bindFile}
                            placeholder={'Выбрать файл'}
                            newDesign
                            isCameraIcon={true}
                          />
                      </div>
                    )}

                    {uploading && (
                        <>
                            <h3 className={s.modalMessage}>Подождите, идет сохранение данных</h3>
                            <ProgressBar value={progress}/>
                        </>
                    )}
                    <div className={s.actions}>
                      {!uploading &&
                        <button type={'button'} className={s.btn} onClick={() => setAction(true)}>
                          Да
                        </button>
                      }

                      <button type={'button'} className={s.btn} onClick={() => closeCallback(false)}>Отмена</button>
                    </div>
                </div>
            </div>
        </>,
      document.body
    );
}

export default Popup;
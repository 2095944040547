const SET_USER_PROFILE = "impulse/profile/SET_USER_PROFILE";
const UPD_USER_PROFILE = "impulse/profile/UPD_USER_PROFILE";
const REMOVE_USER_PROFILE = "impulse/profile/REMOVE_USER_PROFILE";

const profileLS = localStorage.getItem("profile");
const tokenLS = localStorage.getItem("userToken");
//console.log("JSON.parse(tokenLS)", JSON.parse(tokenLS));
const initialState = {
  profile: profileLS ? JSON.parse(profileLS) : {},
  isAuth: tokenLS ? true : false,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE: {
      localStorage.setItem("profile", JSON.stringify(action.profile));
      return { isAuth: true, profile: action.profile };
    }

    case UPD_USER_PROFILE: {
      const newProfile = { ...state.profile, ...action.profile };
      localStorage.setItem("profile", JSON.stringify(newProfile));
      return { isAuth: true, profile: newProfile };
    }

    case REMOVE_USER_PROFILE:
      return { isAuth: false, profile: {} };

    default:
      return state;
  }
};

export default profileReducer;

export const actionSetProfile = (profile) => ({
  type: SET_USER_PROFILE,
  profile,
});
export const actionUpdProfile = (profile) => ({
  type: UPD_USER_PROFILE,
  profile,
});
export const actionLogout = () => ({ type: REMOVE_USER_PROFILE });

export const selectIsAuth = (state) => state.profile.isAuth;
export const selectProfile = (state) => state.profile.profile;

import {SettingsService} from "@/services/SettingsService";
import {SETTINGS_TYPES} from "@/constant";

export const rubberLayout = () => {
    const baseSettings = SettingsService.get(SETTINGS_TYPES.GENERAL);
    if(baseSettings?.isShowNewDesign === '1'){
      document.querySelector('body').classList.add('rubberLayout');
      return function() {
        document.querySelector('body').classList.remove('rubberLayout');
      };
    }
}
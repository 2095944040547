const SET_UNVERIFIED_HM = 'impulse/seminarian/SET_UNVERIFIED_HM';

const initialState = {
	unverifiedHM: [],
	totalCountUnverifiedHM: 0,
};

const seminarianReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_UNVERIFIED_HM:
			return {
				...state,
				unverifiedHM: action.state?.list ?? [],
				totalCountUnverifiedHW: action.state?.count,
			};
		default:
			return state;
	}
};

export default seminarianReducer;

export const setUnverifiedHM = (state) => ({ type: SET_UNVERIFIED_HM, state });

export const selectorTotalCountUHW = (state) =>
	state.seminarianR.totalCountUnverifiedHW;

import { useCalendar } from "../../../hooks"
import { GetIco } from "../../../utils/icons"
import s from "./Calendar.module.css"
import cn from 'classnames'
import { checkDateIsEqual, checkIsToday, compareDates } from "../../../helpers/date"

const Calendar = ({ locale = 'default', selectedDate = new Date(), selectDate = function (date) { }, min, max }) => {
  const { state, functions } = useCalendar({ selectedDate, firstWeekDay: 2, locale })

  return <div className={s.wrapper}>
    <div className={s.heed}>
      <button onClick={() => functions.onClickArrow('left')} className={cn(s.btn, 'focus')}><GetIco className={s.btnIco} icon='angle_arrow_left' /></button>
      {state.mode === 'days' &&
        <span tabIndex={0} className={cn('focus', s.headMode, s.headModeSelect)} onClick={() => functions.setMode('monthes')}>{state.monthNames[state.selectedMonth.monthIndex].month} {state.selectedYear}</span>}
      {state.mode === 'monthes' &&
        <span tabIndex={0} className={cn('focus', s.headMode, s.headModeSelect)} onClick={() => functions.setMode('years')}>{state.selectedYear}</span>}
      {state.mode === 'years' &&
        <span className={s.headMode}>{state.selectedYearInterval[0]} - {state.selectedYearInterval[state.selectedYearInterval.length - 1]}</span>}
      <button onClick={() => functions.onClickArrow('right')} className={cn(s.btn, 'focus')}><GetIco className={s.btnIco} icon='angle_arrow_right' /></button>
    </div>
    <div className={cn(s.body, { [s.modeDays]: state.mode === 'days' }, { [s.modeMonths]: state.mode === 'monthes' || state.mode === 'years' })}>
      {state.mode === 'days' && <>
        {state.weekDaysNames.map(weekDaysNames => <span key={weekDaysNames.dayShort} className={s.calendarWeekDay}>{weekDaysNames.dayShort}</span>)}
        {state.calendarDays.map(day => {
          const checkMinMaxMonth = () => {
            if (min && compareDates(day.date, min) <= -2) return true
            if (max && compareDates(day.date, max) >= 0) return true
            if (day.monthIndex !== state.selectedMonth.monthIndex) return true
            return false
          }
          const isAdditional = checkMinMaxMonth()
          const className = cn(
            s.calendarBtn,
            'focus',
            { [s.today]: checkIsToday(day.date) },
            { [s.active]: checkDateIsEqual(day.date, state.selectedDate.date) },
            { [s.dayAdditional]: isAdditional }
          )

          return <button disabled={isAdditional} onClick={() => {
            if (isAdditional) return false

            functions.setSelectedDay(day)
            selectDate(day.date)
          }} key={`${day.dayNumber}-${day.monthIndex}`} className={className}>{day.dayNumber}</button>
        })}
      </>}

      {state.mode === 'monthes' &&
        state.monthNames.map(month => {
          const className = cn(
            s.calendarBtn,
            'focus',
            { [s.today]: new Date().getMonth() === month.monthIndex && new Date().getFullYear() === state.selectedYear },
            { [s.active]: month.monthIndex === state.selectedMonth.monthIndex },
          )
          return <button onClick={() => {
            functions.setMode('days')
            functions.setSelectedMonthByIndex(month.monthIndex)
          }} key={month.monthShort} className={className}>{month.monthShort}</button>
        })
      }

      {state.mode === 'years' && <>
        <button className={cn(s.calendarBtn, s.dayAdditional)} disabled>{state.selectedYearInterval[0] - 1}</button>

        {state.selectedYearInterval.map(year => {
          const className = cn(
            s.calendarBtn,
            'focus',
            { [s.today]: new Date().getFullYear() === year },
            { [s.active]: year === state.selectedYear },
          )
          return <button onClick={() => {
            functions.setMode('monthes')
            functions.setSelectedYear(year)
          }} key={year} className={className}>{year}</button>
        })}
        <button className={cn(s.calendarBtn, s.dayAdditional)} disabled>{state.selectedYearInterval[state.selectedYearInterval.length - 1] + 1}</button>
      </>
      }


    </div>


  </div>
}

export default Calendar

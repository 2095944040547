import { NavLink } from "react-router-dom";
import s from "./index.module.css";
import { GetIco } from "../../../utils/icons";
import { useSelector } from "react-redux";
import { useScreenWidth } from "@hooks/useScreenWidth";
import { selectProfile } from "@store/profile.reducer";
import {menuApi} from "@/api";
import {useState, useEffect} from 'react'

import cx from "classnames";


 

const BottomMenu = ({demo=false, role=false}) => {

  let [arMenu, setArMenu] = useState();

  const fetchMenu = () => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');

    if(role!=false){
      menuApi
      .getByRoleName(role, selectedLanguage, 'mobile')
      .then(({data}) => {
        setArMenu(data)
      })
    }
    else{
      menuApi
      .getMenu(selectedLanguage, 'mobile')
      .then(({data}) => {
        setArMenu(data)
      })
    }
  };
  useEffect(()=>{
    fetchMenu()
  }, [])
  
  const userRole = useSelector(selectProfile)?.role;

  const isMobile = useScreenWidth(992);
  // const isMobileFive = useScreenWidth(580);


  // let arMenuShow = [];
  // if(arMenu[userRole]?.length > 0){
  //   arMenuShow = arMenu[userRole];
  // }
    //если есть массив с ролью и isMobileFive есть и роль children, то берем arMenu["childrenMobile"]
    //если есть массив с ролью и нету чегото из (isMobileFive && userRole === "children"), то берем arMenu[userRole]
    // если нету arMenu[userRole] то берем  arMenu.student
  // useEffect(()=>{
  //   if(role !== false){
  //     const sort = arMenu?.filter((el)=> el?.is_active == '1');
  //     setArMenu(sort) 
  //   }
  // }, [role]);
  let arMenuSort = arMenu;
  if(role != false){
    arMenuSort = arMenu?.filter((el)=> el?.is_active == '1');
  }
  if ((!isMobile || userRole === undefined) && demo==false) return false;
  
  return (
    <nav className={`app-bottom-menu ${s.wrapper}`}>
      <ul className={cx(s.list)}>
        {arMenuSort?.map((el, i) => {
          if (el.url.startsWith("http"))
            return (
              <li key={i}>
                <a href={el.url} className={s.link} target="_black">
                  <GetIco icon={el.icon} />
                  <span>{el.title}</span>
                </a>
              </li>
            );

          return (
            <li key={i}>
              <NavLink
                exact={el.url === "/" && true}
                to={el.url}
                className={s.link}
                activeClassName={s.active}
              >
                {el.ico==='logo' ? <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18.7832 0.83454C19.45 0.860942 20.0257 1.30972 20.214 1.94992L24.1027 15.1718C24.3451 15.996 23.8735 16.8606 23.0493 17.103C22.2252 17.3454 21.3605 16.8738 21.1181 16.0496L18.5293 7.2475L15.1192 16.1663C14.8919 16.7606 14.3255 17.1567 13.6893 17.1661C13.053 17.1755 12.4751 16.7964 12.2304 16.209L9.77747 10.322L7.32455 16.209C7.09577 16.7581 6.5739 17.1286 5.9801 17.1636C5.38631 17.1985 4.82457 16.8918 4.53295 16.3733L1.03305 10.1513C0.611873 9.40251 0.87743 8.45409 1.62619 8.03291C2.37494 7.61173 3.32336 7.87729 3.74454 8.62605L5.68009 12.067L8.34161 5.67935C8.58313 5.09969 9.14951 4.72211 9.77747 4.72211C10.4054 4.72211 10.9718 5.09969 11.2133 5.67935L13.6042 11.4175L17.2687 1.8333C17.507 1.20999 18.1164 0.808138 18.7832 0.83454Z" fill="url(#paint0_linear_1413_4456)"/>
                  <defs>
                  <linearGradient id="paint0_linear_1413_4456" x1="25.3323" y1="12.4997" x2="-4.61128" y2="7.83317" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#9828D3"/>
                  <stop offset="1" stopColor="#ED6B6A"/>
                  </linearGradient>
                  </defs>
                  </svg> : <GetIco icon={el?.icon} />
                }
                
                <span>{el.title}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default BottomMenu;

import { useTask } from "../../../../hooks";
import Button from "../../../UI/Button";
import Preloader from "../../../UI/Preloader";
import { TypeTask } from "./components/TypeTask";
import s from "./index.module.css";
import { listOptions } from "./listOptions";
import { directionAPI } from "../../../../api";
import { useEffect, useState } from "react";

export const EditMode = ({
  data,
  save,
  saveLS,
  cancelLS,
  removeLS,
  direction,
}) => {
  const [directionInfo, setDirectionInfo] = useState(null);
  const [
    state,
    setState,
    onSave,
    onCancel,
    del,
    setErrorsList,
    showErrors,
    loading,
  ] = useTask(data, save, saveLS, cancelLS, removeLS);

  useEffect(() => {
    directionAPI.get({ id: data.direction_id }).then((res) => {
      setDirectionInfo(res.data);
    });
  }, []);

  return (
    <>
      <TypeTask type={data.type} />
      <h3 className={s.title}>{data.title}</h3>
      <div className={s.list}>
        {state &&
          listOptions(state.type, directionInfo?.flag_easily_creating).map(
            (El, index) => {
              return (
                <El
                  key={index}
                  data={state}
                  edit={setState}
                  direction={direction}
                  setErrorsList={setErrorsList}
                  showErrors={showErrors}
                />
              );
            }
          )}
      </div>

      <div className={s.buttonsWrap}>
        {loading ? (
          <Preloader />
        ) : (
          <Button styles="yellow" action={onSave}>
            {data.id ? "Сохранить" : "Добавить"}
          </Button>
        )}
        <Button action={onCancel}>{data.id ? "Отменить" : "Удалить"}</Button>
        {data.id && (
          <Button styles="red" action={del}>
            {removeLS ? "Удалить из списка" : "Удалить из дз"}
          </Button>
        )}
        {/* <Button styles='gray'>Архивировать</Button> */}
      </div>
    </>
  );
};

import LayoutAuth from '@common/LayoutAuth'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import s from './index.module.css'
import s2 from '../Auth/index.module.css'
import { SetTitlePage } from '@common/SetTitlePage'
import { Button, Form, Input, Modal, Select, Checkbox } from 'antd'
import classNames from 'classnames'
import { usersAPI } from '../../../api'
import {Config, Connect} from "@vkontakte/superappkit";
import {SETTINGS_TYPES} from "@/constant";
import { Redirect } from 'react-router-dom';
import { settingApi } from "@/api";
import { MyPdfViewer } from "@common/Probes/Probes";
import userAgreementPDF from "../Auth/file/userAgreement.pdf"
import privacyPolicyPDF from "../Auth/file/privacyPolicy.pdf"
import {IcoRemove} from "@pages/Admin/constsIcon";

const Register = () => {
  const [settings, setSettings] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [showUserAgreement, setShowUserAgreement] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  useEffect(() => {
    settingApi.getAll()
      .then(response => {
          const items = response.data.filter(item => item.type === SETTINGS_TYPES.GENERAL);
          const generalSettings = {};
          items.forEach(item => Object.assign(generalSettings, { [item.key]: item.value }));
          setSettings(generalSettings);
      });
  }, []);
  
  const hist = useHistory()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const [isUseEmail, setIsUseEmail] = useState(true)

  useEffect(() => {
    if(settings?.isDisableEmailRegistration === '1'){
      setIsUseEmail(false)
    } 
  }, [settings?.isDisableEmailRegistration]);

  const getValueFrom = (e) => {
    const name = e.target.id === 'passwordRepeat' ? 'password' : e.target.id
    setError(prev => ({ ...prev, [name]: null }))
  }

  const register = async (data) => {
    
    let newData = {};

    if(data?.prefix){
      newData = {
        ...data,
        phone: `${data?.prefix} ${data?.phone?.replace(/[^\d\+\(\)\-' ']/g, '')}`
      }
    }
    else{
      newData = {...data}
    }
    
    delete newData?.prefix;
    

    setLoading(true)
    let type = isUseEmail ? 'email' : 'phone';
    const result = await usersAPI.register({ data: { ...newData, type: type } })
    setLoading(false)

    if (result?.data?.status === 'success') {
      Modal.success({
        autoFocusButton: null,
        centered: true,
        afterClose: () => hist.push('/auth'),
        title: 'Регистрация успешна!',
        content: 'Введите свой логин и пароль для авторизации в личном кабинете',
      })
    } else {
      const validateList = {}
      const errors = result.response.data.error
      for (let key in errors)
        validateList[key] = {
          validateStatus: "error",
          help: errors[key][0],
          getValueFromEvent: getValueFrom
        }
      setError(validateList)
    }
  }

  const VkAuth = () => {
    Config.init({
      appId: process.env.REACT_APP_VK_APP_ID,
    });

    let url = `${window.location.protocol}//${window.location.hostname}`

    if (window.location.port) {
      url = url + ':' + window.location.port
    }

    Connect.redirectAuth({
      url: `${url}/signup-vk`,
      state: ''
    })
  }

  const onFinish = (data) => register(data)

  const { Option } = Select;
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select disabled={loading} style={{ width: 80, background:'#fff'}} >
        <Option value="+7">+7</Option>
        <Option value="+375">+375</Option>
        <Option value="+997">+997</Option>
        <Option value="+374">+374</Option>
        <Option value="+996">+996</Option>
        <Option value="+998">+998</Option>
        <Option value="+44">+44</Option>
        <Option value="+33">+33</Option>
        <Option value="+34">+34</Option>
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );

  if(settings?.isDisableRegistration === '1'){
    return <Redirect to="/auth" />
  }

  return <LayoutAuth>
    <SetTitlePage title='Регистрация' />

    <div className={classNames('new_card', s.card)}>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: '1rem',
        textDecoration: 'underline',
        cursor: "pointer"
      }}>
        <h2 className={s.title}>Регистрация нового пользователя</h2>
        {isUseEmail && <span style={{width: '30%'}} onClick={() => setIsUseEmail(false)}>по номеру телефона</span>}
        {!isUseEmail && settings?.isDisableEmailRegistration === '0' && <span style={{width: '30%'}} onClick={() => setIsUseEmail(true)}>использовать e-mail</span>}
      </div>

      <Form initialValues={{ prefix: '+7' }} {...{onFinish, layout: 'vertical'}}>

      <Form.Item name='name' rules={[{required: true, message: 'Пожалуйста, введите ФИО'}]}>
          <Input placeholder='Фамилия Имя Отчество' disabled={loading}/>
        </Form.Item>

        {isUseEmail && <>
          <Form.Item name='email' rules={[
            {required: true, message: 'Пожалуйста, введите e-mail'},
            {type: 'email', message: 'Введите верный e-mail'}]}
                     {...error?.email}>
            <Input placeholder='Электронная почта' disabled={loading}/>
          </Form.Item>
        </>
        }

        {!isUseEmail && <>
          {/* <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            width: '100%',
            paddingBottom: '1rem',
            textDecoration: 'underline',
            cursor: "pointer"
          }}>
            <span onClick={() => setIsUseEmail(true)}>использовать e-mail</span>
          </div> */}
          {/* <Form.Item name='phone' rules={[
            {required: true, message: 'Пожалуйста, введите номер телефона'},
            {type: 'string', message: 'Введите верный номер телефона'}]}
                     {...error?.phone}>
            <Input placeholder='Номер телефона' disabled={loading}/>
          </Form.Item> */}

        

          <Form.Item
            className="stuleSelektKod"
            name="phone"
            rules={[{ required: true, message: 'Пожалуйста, введите номер телефона' },  {type: 'string', message: 'Введите верный номер телефона'},
            ({getFieldValue}) => ({
              validator(_, value) {
                
                if (value.search(/[^\d\+\(\)\-' ']/g) != -1 ) {
                  return Promise.reject(new Error('Можно вводить только цифры, пробел, скобки и дефис'));
                }
                return Promise.resolve();
               
              },
            })
          ]}
          >
            <Input disabled={loading} addonBefore={prefixSelector} style={{ width: '100%' }} />
          </Form.Item>
        </>}

        <Form.Item name='password' rules={[{required: true, message: 'Пожалуйста, введите пароль'}]}>
          <Input placeholder='Пароль' type="password" disabled={loading}/>
        </Form.Item>
        <Form.Item name='passwordRepeat' dependencies={['password']} rules={[
          {required: true, message: 'Пожалуйста, введите пароль повторно'},


          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Пароли не совпадают'));
            },
          }),


        ]} {...error?.password}>
          <Input placeholder='Повторите пароль' type="password" disabled={loading}/>
        </Form.Item>

        {error.status && <Form.Item>
          <div className="ant-form-item-explain-error">{error.text}</div>
        </Form.Item>}


        <Form.Item>
          <div className={s.buttons}>
            <Button disabled={!disabled} htmlType="submit" className={s.buttonLogin} {...{loading}}>
              Зарегистрироваться
            </Button>
            {
              settings?.isDisableloginViaVK === '0' ?  <Button disabled={!disabled} htmlType="button" className={s.buttonLoginVk} onClick={VkAuth}>
              <span>
                Зарегистрироваться через <span className={classNames('social social--vk', s.buttonLoginVkIco)}></span>
              </span>
            </Button> : <></>
            }
           
          </div>
        </Form.Item>

        <div className={s2.agreement}>
          <Checkbox
            className={s2.disabledStyle}
            checked={disabled}
            onChange={(e) => setDisabled(e.target.checked)}
          />
          <div>
            Принимаю 
            <span onClick={()=>setShowUserAgreement(true)} >
              пользовательское соглашение
            </span>и  
            <span onClick={()=>setShowPrivacyPolicy(true)}>
              политику конфиденциальности
            </span>
          </div>
        </div>

        <div>
          Уже есть аккаунт? <Button type='link' onClick={() => {
          hist.push('/auth')
        }}>Войти</Button>
        </div>

      </Form>

       {(showUserAgreement || showPrivacyPolicy) && <div onClick={(e)=>{e.target==e.currentTarget && setShowUserAgreement(false); setShowPrivacyPolicy(false)}} className={s2.modal}>
       <div className={s2.modalContent}>
          <IcoRemove 
            onClick={
              ()=>{
                setShowUserAgreement(false)
                setShowPrivacyPolicy(false)
              }
            } 
            className={s2.icoRemove} 
          />

          { showUserAgreement && <MyPdfViewer
              presentation={true}
              file={userAgreementPDF}
            />}
          { showPrivacyPolicy && <MyPdfViewer
            presentation={true}
            file={privacyPolicyPDF}
          />}
        </div>
      </div>}

    </div>
  </LayoutAuth>
}

export default Register

import { profileAPI } from '../api';
import {actionSetProfile, actionLogout, selectIsAuth} from '@store/profile.reducer';
import store from '@store/redux.store';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import {unsubscribeFcmMessaging} from "@hooks/useFcmMessaging";

export const AuthService = {
	auth: (user, token = null, refresh_token = null) => {
		if (user.roles?.length > 0) {
			user.roles = user.roles?.map((i) => i?.item_name);
		}

		if (token) {
			const tokenDecode = jwt_decode(token);

			user.role = tokenDecode?.role;
			user.directionIdsSS = tokenDecode?.directionIds ?? [];
		}

		localStorage.setItem('userToken', token);
		// localStorage.setItem('userRefreshToken', refresh_token);
		if(refresh_token){
			localStorage.setItem('userRefreshToken', refresh_token);
		}
		
		store.dispatch(actionSetProfile(user));
	},

	login: async (email, password) => {
		await profileAPI.login({ email, password }).then((result) => {
			if (result?.status === 200) {
				const user  = result?.data?.user
				const token = result.data.token
				const refresh_token = result.data?.refresh_token

				if (user) {
					AuthService.auth(user, token, refresh_token)

					return { status: 'ok' };
				}
			}

			return { status: 'error' };
		});
	},

	appLogin: async (app_id, uuid) => {
		return await profileAPI.appLogin({ app_id, uuid }).then((result) => {
			if (result?.status === 200) {
				if (result?.data?.user) {
					const data = { ...result.data.user };
					if (result?.data?.user?.roles.length > 0)
						data.roles = result?.data?.user?.roles?.map((i) => i?.item_name);
					if (result?.data?.token) {
						const tokenDecode = jwt_decode(result.data.token);
						data.role = tokenDecode?.role;
						data.directionIdsSS = tokenDecode?.directionIds ?? [];
					}
					localStorage.setItem('userToken', result.data.token);
					store.dispatch(actionSetProfile(data));
					return { status: 'ok' };
				}
			}
			return { status: 'error' };
		});
	},

	logout: async () => {
		unsubscribeFcmMessaging();
		await localStorage.clear();
		store.dispatch(actionLogout());
	},

	refreshToken: async () =>
		await axios
			.post(
				`${process.env.REACT_APP_URL_API}auth/refresh-token`,
				{refresh_token: localStorage.getItem('userRefreshToken')},
				{ withCredentials: true }
			)
			.then((res) => { 
				if (res.data.statusCode === 401) AuthService.logout();
				localStorage.setItem('userToken', res.data.token);
				if (res.data?.refresh_token){
					localStorage.setItem('userRefreshToken', res.data.refresh_token);
				}
			}),
};

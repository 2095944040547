import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Component from './Component.jsx';

const Math = Node.create({
	name: 'math',

	group: 'block',
	content: 'inline*',

	atom: true,

	addAttributes() {
		return {
			value: {
				default: ' ',
			},
		};
	},

	addOptions() {
		return {
			HTMLAttributes: {},
		};
	},

	parseHTML() {
		return [
			{
				tag: 'math',
			},
		];
	},

	addCommands() {
		return {
			toggleMath:
				() =>
				({ chain }) => {
					return chain().insertContent('<p/>').setNode(this.name).run();
				},
		};
	},

	renderHTML({ HTMLAttributes }) {
		return ['math', HTMLAttributes, 0];
	},

	addNodeView() {
		return ReactNodeViewRenderer(Component);
	},
});

export default Math;

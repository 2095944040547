import store from '@store/redux.store';
import { setAlert } from '@store/alerts.reducer';
import lang from './lang.json';

const ErrorHandling = {
	showAlert(data) {
		if (typeof data?.error === 'object') {
			for (var key in data?.error?.length > 1 ? data?.error[0] : data?.error) {
				const title = lang[key] ?? (key === '0' ? '' : key);
				const errorList =
					data?.error?.length > 1 ? data?.error[0][key] : data?.error[key];
				this._addAlert(`Ошибка ${title}`, errorList);
			}
		}
	},

	showDefaultAlert(role) {
		switch (role) {
			case 'children':
				return this._addAlert(
					'Упс! Ошибка. Попробуйте ещё раз или обратитесь к педагогу'
				);
			default:
				return this._addAlert(
					'Упс! Ошибка. Попробуйте ещё раз или обратитесь к администратору'
				);
		}
	},

	_addAlert(title, text) {
		store.dispatch(setAlert({ type: 'error', title, text }));
	},
};

export default ErrorHandling;

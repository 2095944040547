import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { getInArr } from '../../../../helpers/getInArr';
import { selectProfile } from '@store/profile.reducer';

export const UsersRedirect = () => {
	const userRole = useSelector(selectProfile).role;
	if (getInArr(userRole, ['seminarian', 'personal-manager']))
		return <Redirect to={'/users/students'} />;
	return <Redirect to={'/users/colleges'} />;
};

import { jsonConvert } from '../helpers/jsonConvert';
import { newId } from '../helpers/nextId';

const SET_ALERTS = 'impulse/alerts/SET_ALERTS';
const REMOVE_ALERTS = 'impulse/alerts/REMOVE_ALERTS';

const initialState = [
	// {
	//   id: 0,
	//   type: 'error',
	//   title: 'Пример ошибки',
	//   text: 'Очень большое описание ошибки труляляляляля',
	//   timestamp: new Date(),
	//  timer: true,
	// },
];

const alertsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ALERTS:
			return [
				...state,
				{
					timer: true,
					timestamp: new Date(),
					...action.data,
					text: jsonConvert(action.data.text),
					id: newId(),
				},
			];
		case REMOVE_ALERTS:
			return [...state.filter(({ id }) => id !== action.id)];
		default:
			return state;
	}
};

export default alertsReducer;

export const setAlert = (data) => ({ type: SET_ALERTS, data });
export const removeAlert = (id) => ({ type: REMOVE_ALERTS, id });

export const selectGetAlerts = (state) => state.alerts;

import s from "./index.module.css";
import { GetIco } from "../../../utils/icons";
import onCorKey from "../../../utils/onKeyUp";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

const UploadFile = ({
  value = '',
  noIco = false,
  className = "",
  title = "Загрузите файл",
  icon = "loading",
  onInput = () => { },
  onChange = (file) => { },
  allowed = true,
  showMode = false,
  mediaRecorder = false, // Новый проп для состояния медиа записываю
  ...props
}) => {
  const inputFile = useRef();
  const [setting, setSetting] = useState({ label: value?.name ? value.name : title, file: value });
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const [audioURL, setAudioURL] = useState(""); // Для хранения URL записанного аудио

  useEffect(() => {
    setSetting({ label: value?.name ? value.name : title, file: value });
  }, [value, title]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = event => {
      audioChunks.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
      const audioFile = new File([audioBlob], 'recorded-audio.wav', { type: 'audio/wav' });
      setSetting({ label: audioFile.name, file: audioFile });
      onChange(audioFile);
      audioChunks.current = [];
      
      // Создаем URL для прослушивания
      const url = URL.createObjectURL(audioBlob);
      setAudioURL(url);
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  const input = (e) => {
    if (e.target.value) {
      const file = e.target.files[0];
      setSetting({ label: file.name, file: file });
      onInput(e);
      onChange(file);
    }
  };

  const reset = (e) => {
    setSetting({ label: title, file: null });
    onInput(e);
    onChange('');
    inputFile.current.value = '';
    setAudioURL(""); // очищаем URL
  };

  const setOpen = (e) => {
    e.target.firstChild.click();
  };

  if (showMode) return <ShowMode value={value} className={className} />

  return (
    <div className={classNames(
      s.wrapperAdditionalFeatures,
      {[s.wrapperAdditionalFeaturesActive]:mediaRecorder}
    )}>
      <div className={classNames(s.wrapper, { [s.disable]: !allowed }, { [className]: className })}
        data-file={setting.file ? true : false} tabIndex={0} onKeyUp={(e) => onCorKey(e) && setOpen(e)} >
        <label>
          <span>{setting.label}</span>
          <input ref={inputFile} type="file" onInput={input} disabled={!allowed} {...props} />
        </label>

        {setting.file ? 
          !noIco && 
          <button className={classNames({ [s.disable]: !allowed })} disabled={!allowed}> 
            <GetIco icon="cross" onClick={reset} /> 
          </button> 
          : <GetIco icon={icon} />
        }
      </div>
      {mediaRecorder && (
        <>
        {!audioURL && isRecording && <button className={s.audioButton} onClick={stopRecording}>Остановить</button>}
        {!audioURL &&!isRecording && <div className={s.audioTextAndAudioButtonWrapper}><span className={s.audioText}>Или</span> <button className={s.audioButton} onClick={startRecording}>Начать запись</button></div>}
        </>
      )}

      {audioURL && (
        <div>
          <span className={s.audioText}>Прослушать записанное Аудио</span>
          <audio className={s.audio} controls>
            <source src={audioURL} type="audio/wav" />
            Ваш браузер не поддерживает teg аудио 
          </audio>
        </div>
      )}
    </div>
  );
}

const ShowMode = ({ value, className }) => {
  return <div className={classNames(s.wrapper, s.showMode, { [className]: className })}>
    <a href={process.env.REACT_APP_PATH_FILE + value} target='_blank' rel="noreferrer" >Открыть файл</a>
    <GetIco icon='paper' />
  </div>
}

export default UploadFile;

import { Link, useLocation } from "react-router-dom";
import { routes } from "@/routes/list";
import cn from "classnames";
import s from "./Breadcrumbs.module.css";
import { useSelector } from "react-redux";
import { RootState } from "src/store/redux.store";
import { Props_Breadcrumbs } from "src/types/type/propsTypes";

const Breadcrumbs = ({ part, hide }: Props_Breadcrumbs) => {
  const pathnames = useLocation()
    .pathname.split("/")
    .filter((el: string) => el);
  const pageData = useSelector((state: RootState) => state?.page);

  return (
    <span className={s.list}>
      <Link className={s.item} to="/">
        Главная
      </Link>

      {pathnames.map((_: any, index: number) => {
        const path = pathnames.slice(0, index + 1).join("/");
        const routeTo = `/${path}`;
        const routeTo2 = `/${pathnames.slice(index, index + 1)[0]}`;

        const isLast = index === pathnames.length - 1;
        let name = routes.find((el) => el.path === routeTo)?.label
          ? routes.find((el) => el.path === routeTo)?.label
          : routes.find((el) => el.pathToLabel === routeTo2)?.label;
        if (part?.length > index && part[index]) name = part[index];

        const pathTitle = name
          ? name
          : pageData.currentPagePart[index]
          ? pageData.currentPagePart[index]
          : "Страница не найдена";

        if (hide?.includes(index + 2)) return false;
        if (isLast)
          return (
            <span className={cn(s.item, s.last)} key={index}>
              {pathTitle}
            </span>
          );
        return (
          <Link className={s.item} key={index} to={routeTo}>
            {pathTitle}
          </Link>
        );
      })}
    </span>
  );
};

export default Breadcrumbs;

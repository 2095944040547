import s from "./Match.module.css"
import classNames from "classnames"
import JsonPars from "../../../UI/JsonPars/JsonPars"

export const AnswerItem = ({ text, img, isImg, id }) => {

  if (img) return <div
    // className={classNames(s.booble, s.boobleImg, { [s.red]: (!stat && isShow) }, { [s.accent]: (stat && isShow) })}
    className={classNames(s.booble, s.boobleImg)}
  >
    <a href={img}><img src={img} alt='' /></a>
  </div>
  return <div
    className={classNames(s.booble, { [s.boobleNoImg]: isImg })}
  // className={classNames(s.booble, { [s.boobleNoImg]: isImg }, { [s.red]: (!stat && isShow) }, { [s.accent]: (stat && isShow) })}
  >
    {process.env.DEV_MODE && <span className="error">id=({id}) </span>}
    <JsonPars json={text} />
  </div>
}

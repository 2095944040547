// @ts-ignore

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Props_HeaderPage } from "@/types/type/propsTypes";
import { routes } from "@/routes/list";
import Breadcrumbs from "../Breadcrumbs";
import { SetTitlePage } from "../SetTitlePage";

const HeaderPage = (props: Props_HeaderPage) => {
  const pathnames = useLocation()
    .pathname.split("/")
    .filter((el: any) => el);
  const path = `/${pathnames.slice(0, pathnames.length).join("/")}`;
  const name = routes.find((el) => el.path === path)?.label;
  const title = (props?.title ? props.title : name) as string;

  return (
    <header className="page-header">
      <h1>{title}</h1>
      <SetTitlePage title={title} part={props?.part} />
      <Breadcrumbs part={props?.part} hide={props?.hide} />
    </header>
  );
};

export default HeaderPage;

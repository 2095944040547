import s from './index.module.css'
import style from '../../index.module.css'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { InputNew } from '../../../../../UI/InputNew'

export const Sequence = ({ data, edit, setErrorsList, showErrors }) => {

  const [bindName] = useInputNew({
    name: 'correct_answer',
    value: data?.correct_answer,
    onChange: e => edit(prev => ({ ...prev, 'correct_answer': e.replace(/[^\d]/g, "") })),
    onError: setErrorsList,
    placeholder: '74563',
    validate: showErrors,
    errorText: 'Не верный формат или не заполнено поле'
  })

  return <div className={s.wrapper}>
    <span className={style.heading}>Ответ:<br /><small>(без пробелов и запятых)</small></span>
    <InputNew className={style.inputNew} {...bindName} />
  </div>
}

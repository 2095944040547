import User from "@common/Chat/components/User/User";
import '../styles.css';
const UserList = ({users, setPage, activeUsers}) => {
    return (
        <div className={'new-design-scroll'}>
            {users && users.map((el) => (
                <User user={el} key={el.id} setPage={setPage} activeUsers={activeUsers}/>
            ))}
        </div>

    )
}

export default UserList;
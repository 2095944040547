import { SETTINGS_TYPES } from "@/constant";
import { settingApi } from "@/api";
let isFetching = false; 

export const SettingsService = {
    get: (type) => {
        const key = getKey(type);
        let storeData = localStorage.getItem(key);

        if (!storeData) {
            settingApi.getAll()
                .then(response => {
                    const items = response.data.filter(item => item.type === type);
                    const generalSettings = {};
                    items.forEach(item => Object.assign(generalSettings, { [item.key]: item.value }));
                    localStorage.setItem(key, JSON.stringify(generalSettings));
                });

            // If fetching from API, return the existing data from localStorage if available
            return storeData ? JSON.parse(storeData) : null;
        }

        // Return the data from localStorage
        return JSON.parse(storeData);
    },

    getCorrected: async (type) => {
        const key = getKey(type);
        let storeData = localStorage.getItem(key);
        if(storeData){
            return JSON.parse(storeData); 
        }
        
        if (!isFetching) {
            isFetching = true;
            try {
                const response = await settingApi.getAll(); // Ожидаем выполнения API вызова
                const items = response.data.filter(item => item.type === type);
                const generalSettings = {};
                items.forEach(item => Object.assign(generalSettings, { [item.key]: item.value }));
                localStorage.setItem(key, JSON.stringify(generalSettings));
                return generalSettings; // Возвращаем полученные настройки после загрузки
            } catch (error) {
                console.error('Ошибка при получении настроек:', error); // Обработка ошибки
                return null; // Возвращаем null в случае ошибки
            } finally {
                isFetching = false; // Сбрасываем флаг загрузки после завершения
            }
        }
        return null;

    },

};

const getKey = (type) => {
    let key;
    switch (type) {
        case SETTINGS_TYPES.GENERAL:
            key = SETTINGS_TYPES.GENERAL;
            break;
        case SETTINGS_TYPES.CUSTOM:
            key = SETTINGS_TYPES.CUSTOM;
            break;
        default:
            key = SETTINGS_TYPES.GENERAL;
    }

    return key;
};

import { generateHTML } from '@tiptap/core'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import StarterKit from '@tiptap/starter-kit'
import { jsonConvert } from '../../../helpers/jsonConvert'
import Underline from '@tiptap/extension-underline'
import Math from '../Tiptap/extension-math'
import Latex from 'react-latex'
import Link from '@tiptap/extension-link'

const JsonPars = ({ json }) => {
  const data = jsonConvert(json, 'json')
  if (typeof data === 'string') return data
  if (typeof data === 'number') return data
  else if (data.type === undefined) return ''
  return <Html data={data} />
}


const Html = ({ data }) => {
  return <>
    {data?.content?.map((item, i) => {
      if (item?.type === 'math') return <Latex key={i}>{`$${item?.attrs?.value}$`}</Latex>
      return <span className='tiptapStyle' key={i} dangerouslySetInnerHTML={{ __html: generateHTML({ type: 'doc', content: [item] }, [StarterKit, Color, Underline, TextStyle, Math, Link]) }} />
    })}
  </>
}


export default JsonPars
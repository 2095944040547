import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { examNumberAPI, homeTaskApi, subThemeAPI, themeAPI } from '../../api';
import { getInArr } from '../../helpers/getInArr';
import {
	getDirectionsAction,
	getRolesAction,
	getThematicsAction,
} from '@store/staticData';
import { getDirectionsSD, getRolesSD, getThematicsSD } from '../../selectors';

export const selectors = {
	useDirections: (data) =>
		useBodySelectors({
			title: 'Направление',
			...data,
			selector: getDirectionsSD,
			dispatch: getDirectionsAction,
			filter: filterDirection,
		}),
	useRoles: (data) =>
		useBodySelectors({
			title: 'Должность',
			...data,
			selector: getRolesSD,
			dispatch: getRolesAction,
		}),
	useTariffs: (data) => useBodySelectors({ title: 'Тариф', ...data }),
	useThematics: (data) =>
		useBodySelectors({
			title: 'Тематика',
			...data,
			selector: getThematicsSD,
			dispatch: getThematicsAction,
		}),
	useExamNumber: (data) =>
		useBodySelectorsLS({
			title: '№ в экзамене',
			...data,
			getList: getExamNumber,
		}),
	useTheme: (data) =>
		useBodySelectorsLS({ title: 'Тема', ...data, getList: getTheme }),
	useSubTheme: (data) =>
		useBodySelectorsLS({ title: 'Подтема', ...data, getList: getSubTheme }),
	useType: (data) =>
		useBodySelectorsLS({ title: 'Тип задания', ...data, getList: getType }),
	useGender: (data) => useBodySelectorsGender(data),
};

const useBodySelectors = (data) => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const { exclude, include, type } = useMemo(() => setHeader(data), []);
	const d = useDispatch();
	const listAll = useSelector(data.selector);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (listAll?.length === 0) d(data?.dispatch());
	}, []);

	const [list, setList] = useState([]);

	const getList = () =>
		setList(
			listAll
				?.filter((d) => {
					if (data.filter) return data.filter(d, exclude, include);
					return exclude.length === 0 ? true : !getInArr(d.value, exclude);
				})
				?.map((d) => (d.label ? d : { label: d.name, value: d.id }))
		);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		getList();
	}, [include]);
	const [title, setTitle] = useState(data.title);
	const [val, setVal] = useState(type === 'multi' ? [] : '');
	const bind = setData({ title, list, setVal, type });
	const reset = () => {
		setTitle({ label: data.title, value: data.title });
		setVal(type === 'multi' ? [] : '');
	};
	return [bind, val, reset];
};

const useBodySelectorsLS = (data) => {
	const { exclude, include, type } = setHeader(data);
	const [list, setList] = useState([]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		data.getList(setList, exclude, include);
	}, [include]);
	const [title, setTitle] = useState(data.title);
	const defaultValue = useMemo(() => data.default, [data.default]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (data?.default)
			setTitle(list?.find((item) => item?.value === data.default));
	}, [list]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		data?.default
			? setTitle(list?.find((item) => item?.value === data.default))
			: reset();
	}, [defaultValue]);

	const [val, setVal] = useState(type === 'multi' ? [] : '');
	const bind = setData({ title, list, setVal, type });
	const reset = () => {
		setTitle({ label: data.title, value: data.title });
		setVal(type === 'multi' ? [] : '');
	};
	return [bind, val, reset];
};

const useBodySelectorsGender = (data) => {
	const list = [
		{ label: 'М', value: 'male' },
		{ label: 'Ж', value: 'female' },
	];
	const t = data.default
		? list.find((item) => item.value === data.default)
		: 'Пол';

	const [title, setTitle] = useState(t);
	const [val, setVal] = useState('');
	const bind = {
		...setData({ title, list, setVal, type: 'single' }),
		arrow: false,
	};
	const reset = () => setTitle('Пол');
	return [bind, val, reset];
};

const setData = (data) =>
	data.type === 'multi'
		? {
				data: { title: data.title, list: data.list },
				onChange: (v) => data.setVal(v),
		  }
		: {
				title: data.title,
				options: data.list,
				onChange: (e) => data.setVal(e.value ? e.value : e.label),
		  };

const setHeader = (data) => ({
	title: data.title,
	exclude: data?.exclude ? data.exclude : [],
	include: typeof data.include === 'object' ? data.include : false,
	type: data?.type ? data.type : 'single',
	default: data?.default ? data.default : false,
});

const getExamNumber = (setList, exclude, include) => {
	const filter = (item) => {
		if (exclude.length > 0) return !getInArr(item, exclude);
		else if (include !== false) return getInArr(item, include);
		return true;
	};
	return examNumberAPI
		.getAll({})
		.then((res) =>
			setList(
				res?.data?.data
					?.filter((item) => filter(item))
					?.map((item) => ({ label: item.number, value: item.id }))
			)
		);
};
const getTheme = (setList, exclude, include) => {
	const filter = (item) => {
		if (exclude.length > 0) return !getInArr(item, exclude);
		else if (include !== false) return getInArr(item, include);
		return true;
	};
	return themeAPI
		.getAll()
		.then((res) =>
			setList(
				res?.data?.data
					?.filter((item) => filter(item))
					?.map((item) => ({ label: item.name, value: item.id }))
			)
		);
};

const getSubTheme = (setList, exclude, include) => {
	const filter = (item) => {
		if (exclude.length > 0) return !getInArr(item, exclude);
		else if (include !== false) return getInArr(item['task_topic_id'], include);
		return true;
	};
	return subThemeAPI
		.getAll()
		.then((res) =>
			setList(
				res?.data?.data
					?.filter((item) => filter(item))
					?.map((item) => ({ label: item.name, value: item.id }))
			)
		);
};

const getType = (setList, exclude, include) => {
	const filter = (item) => {
		if (exclude.length > 0) return !getInArr(item, exclude);
		else if (include !== false) return getInArr(item['task_topic_id'], include);
		return true;
	};
	return homeTaskApi.getTypes().then((res) => {
		let list = [];
		for (let key in res?.data) {
			list.push({ value: key, label: res.data[key] });
		}
		setList(list?.filter((item) => filter(item.value)));
	});
};

const filterDirection = (item, exclude, include) => {
	if (exclude.length > 0) return !getInArr(item.value, exclude);
	else if (include !== false) return getInArr(item?.filter_id, include);
	return true;
};

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { routes } from "@/routes/list";

export const GetTitle = () => {
    const pathnames = useLocation()
    .pathname.split("/")
    .filter((el) => el);

    const path = `/${pathnames.slice(0, pathnames.length).join("/")}`;
    const title = routes.find((el) => el.path === path)?.label;
    return title;
}

import s from './CustomRadioElem.module.css';
import '../and-design.css';
import {Select,} from "antd";
import React, { useState} from "react";
import classNames from "classnames";
import UserImage from "@common/Chat/components/UserImage/UserImage";

const CustomRadioElem = ({user, addUsers, participants, setParams}) => {
    const [role, setRole] = useState('participant');
    const [isSelect, setSelected] = useState(false)

    let isItSelectedParticipant = participants.find(el => el.id === user.id)
    const options = [
        {
            label: 'Администратор',
            value: 'admin'
        },
        {
            label: 'Участник',
            value: 'participant'
        }]

    const toggleAddUser = () => {
        setSelected(!isSelect)
        isItSelectedParticipant ? addUsers([...participants.filter(el => el.id !== user.id)]) : addUsers([...participants, {
            id: user.id,
            name: user.name,
            avatar: user.avatar_path,
            role: role
        }])
        setParams({search: ''})
    }

    return (
        <div className={classNames(s.row, 'custom-radio-element')}>
            <div className={s.rowHead}>
                <UserImage path={user.avatar_path}/>
                <div className={s.rowName}>{user.name}</div>
            </div>
            <div className={s.controls}>
                <Select
                    onChange={(value) => setRole(value)}
                    options={options} value={role}
                    popupClassName={'custom-dropdown'}
                    size={'middle'}
                    dropdownStyle={{ fontSize: '12px', background: "#ffffff" }}
                >
                    <Select.Option value="admin" className={s.option}>Администратор</Select.Option>
                    <Select.Option value="participant">Участник</Select.Option>
                </Select>
                <div className={s.selectContainer} onClick={toggleAddUser}>
                    <div className={isItSelectedParticipant ? s.selected : s.select}></div>
                </div>
                {isSelect}
            </div>

        </div>
    )
}

export default CustomRadioElem;
import s from './User.module.css';
import React from "react";
import {chatApi} from "@/api";
import UserImage from "@common/Chat/components/UserImage/UserImage";
import {useAddAlert} from "@/hooks/useAddAlert";
const User = ({user, setPage, activeUsers}) => {
    const isUserOnline = activeUsers.find(el => el.id === user.id)
    const addAlert=useAddAlert();
    const save =(e) => {
        chatApi.createChat({
            name: null,
            chatImage: null,
            users: [{...user, role: 0}]
        }).then((res) => {
            if (res.status === 200) {
                setPage('inChat', res?.data?.id)
            } else {
                addAlert({text: res?.response?.data?.error})
            }
        })
    }
    return (
        <div className={s.user} onClick={(e) => save(e)}>
            <div className={s.photo}>
                <UserImage path={user.avatar_path}/>
                {isUserOnline && <div className={s.isOnline}></div>}
            </div>

            <div className={s.name}> {user.name}</div>
        </div>
    )
}

export default User;
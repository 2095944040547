import { Config } from "@/config";

const MOBILE_MODE = 'impulse/page/MOBILE_MODE';
const TOGGLE_SIDEBAR = 'impulse/page/TOGGLE_SIDEBAR';
const SET_CURRENT_PAGE = 'impulse/page/SET_CURRENT_PAGE';
const TOGGLE_NO_SCROLL = 'impulse/page/TOGGLE_NO_SCROLL';

let initialState = {
	mobileMode: false,
	showSidebar: false,
	hideScroll: false,
	currentPagePart: ['-'],
	titlePage: Config.PROJECT_NAME,
};

const pageReducer = (state = initialState, action) => {
	switch (action.type) {
		case MOBILE_MODE:
			return { ...state, mobileMode: action.value };
		case TOGGLE_SIDEBAR:
			return {
				...state,
				showSidebar: action.value,
				hideScroll: action.value,
			};
		case TOGGLE_NO_SCROLL:
			return { ...state, hideScroll: action.value };
		case SET_CURRENT_PAGE:
			return {
				...state,
				titlePage: action.data.title,
				currentPagePart: action.data.part,
			};
		default:
			return state;
	}
};

export const editMobileMode = (value) => ({ type: MOBILE_MODE, value });
export const toggleShowSidebar = (value) => ({ type: TOGGLE_SIDEBAR, value });
export const toggleNoScroll = (value) => ({ type: TOGGLE_NO_SCROLL, value });
export const setCurrentPage = (data) => ({ type: SET_CURRENT_PAGE, data });

export default pageReducer;

import { useEffect, useState } from "react";
import { formateTime } from "../../../helpers/formateTime";
import { useInterval } from "../../../hooks";
import Button from "../Button";
import s from "./index.module.css";

const Timer = ({ setStatus, setTimeOut, status, ...props }) => {
  const [time, setTime] = useState(props?.time ? props.time : 14400);
  // const [count, setCount] = useState(3)
  const [timeLeft, setTimeLeft] = useInterval(time, 1000);

  useEffect(() => {
    if (status === "start") setTimeLeft(time);
  }, [status, time, setTimeLeft]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (timeLeft === 0) setTimeOut(true);
  }, [timeLeft]);

  const pauseTimer = () => {
    props.pause();
    setStatus("pause");
    setTime(timeLeft);
    setTimeLeft(0);
  };

  const start = () => {
    props.start();
    setStatus("start");
  };

  return (
    <div className={s.timer}>
      {status === "go" && (
        <Button styles="yellow" action={start}>
          {props.type === "probe"
            ? "Начать решать пробник"
            : "Начать контрольную работу"}
        </Button>
      )}
      {/* {status === 'start' && (props.type === 'probe' ? <span></span> : <Button styles='red' action={pauseTimer}>Пауза ({count}) </Button>)} */}
      {status === "start" &&
        (props.type === "probe" ? (
          <span></span>
        ) : (
          <Button styles="red" action={pauseTimer}>
            Пауза{" "}
          </Button>
        ))}
      {status === "pause" && (
        <Button styles="yellow" action={start}>
          Старт
        </Button>
      )}
      <span className={s.time}>
        {status === "start" ? formateTime(timeLeft) : formateTime(time)}
      </span>
    </div>
  );
};

export default Timer;

import s from "./StatRow.module.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, FreeMode, Mousewheel } from "swiper";
import { useRef, useState } from "react";

import "swiper/css";
import "swiper/css/scrollbar";
import classNames from "classnames";

const StatRow = (props) => {
  const ref = useRef(null);

  const [cssTooltip, setCssTooltip] = useState({
    display: "none",
    position: "fixed",
    top: 0,
    left: 0,
  });

  let containerClass = "statisticContainer";
  switch (props.type) {
    case "one":
      containerClass = s.single;
      break;
    case "two":
      containerClass = s.double;
      break;
    default:
      containerClass = s.double;
      break;
  }

  const showTooltip = (event) => {
    const widthWindow = window.innerWidth;
    const block = ref.current;
    const widthBlock = block.getBoundingClientRect().width;

    let x = event.target.parentNode.getBoundingClientRect().left;
    let y = event.target.parentNode.getBoundingClientRect().top + 35;

    const rightBlock = x + widthBlock;

    if (rightBlock > widthWindow) x = x + widthWindow - rightBlock - 50;

    setCssTooltip({ visibility: "visible", top: y, left: x });
  };

  const hideTooltip = (event) => {
    setCssTooltip({ visibility: "hidden" });
  };

  return (
    <div className={s.statisticRow} style={{ position: "relative" }}>
      <div ref={ref} className={s.tooltipContainer} style={cssTooltip}>
        <h2>КУРС 1 ПУНКТУАЦИЯ</h2>
        <p>Тема: №1 Знаки препинания в простых предложениях</p>
        <p>Урок: №1</p>
      </div>
      {props.title && <h2 className={s.statisticRowTitle}>{props.title}</h2>}
      <div className={classNames(containerClass, s.classContainer)}>
        <Swiper
          className={s.swiper}
          slidesPerView={"auto"}
          scrollbar={{ el: `.${s.statisticRowScrollbar}`, draggable: true }}
          modules={[Scrollbar, FreeMode, Mousewheel]}
          freeMode={true}
          mousewheel={true}
        >
          {props.values?.map((el, index) => {
            let classItem = props.type === "one" ? s.grayZone : s.purpleZone;
            // if ((el.value && props.isRedoHomework === 1 && props.role === 'teacher') || (el.value && props.isRedoHomework === 0 ) ){
            if (el.value) {
              if (el.value >= 85) {
                classItem = s.purpleZone;
              } else if (el.value <= 65) {
                classItem = s.redZone;
              } else if (65 < el.value < 85) {
                classItem = s.yellowZone;
              }
              if (el.id !== undefined && el.id === props.select) {
                classItem = s.purpleZone;
              }
            }

            return (
              <SwiperSlide
                key={index}
                className={classNames(
                  s.statBoxItemWrapper,
                  classItem,
                  { [s.big]: props?.size === "big" },
                  { [s.widthBig]: props?.width }
                )}
              >
                {props.type === "two" ? (
                  <>
                    <div
                      onClick={() => props.onClick(el.id)}
                      className={s.statBoxItem}
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e) => showTooltip(e)}
                      onMouseLeave={(e) => hideTooltip(e)}
                    >
                      {el.title}
                    </div>
                    <div className={s.statBoxItem}>{el.value}%</div>
                  </>
                ) : (
                  <div
                    onClick={() => props.onClick(el.id)}
                    className={s.statBoxItem}
                  >
                    {el.title}
                  </div>
                )}
              </SwiperSlide>
            );
          })}
          <div className={s.statisticRowScrollbar}></div>
        </Swiper>
      </div>
    </div>
  );
};

export default StatRow;

import { GetIco } from '../../../utils/icons'
import s from './index.module.css'
import classNames from "classnames";

const Search = ({ className = '', bind, onSubmit, placeholder = 'Поиск',withClose = true, ...props }) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(bind.value ?? '')
  }

  const handleReset = () => {
    bind.onChange({ target: { value: '' } })
    onSubmit('')
  }


  return <form onSubmit={handleSubmit} className={`${s.search} ${className}`} {...props}>
    <input {...{ ...bind, value: bind?.value === null ? '' : bind?.value }} placeholder={placeholder} disabled={props.disabled} />
    <button type='submit'><GetIco icon='magnifier_new_design' /></button>
    {bind?.value &&  withClose && <button type='button' onClick={handleReset} className={s.close}><GetIco icon='cross' /></button>}
  </form>
}

export default Search

import JsonPars from '../../../UI/JsonPars/JsonPars'
import s from './Hint.module.css'

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const Hint = ({ hint }) => {
  const chescJson = IsJsonString(hint);
  if(chescJson){
    const hintObj = JSON.parse(hint)
    if(hintObj?.content){
      return <span className={s.wrapper}>
              <button type='button' className='btn btn--yellow' style={{ opacity: 1 }} disabled>Получить подсказку</button>
              <div className={s.hint}><JsonPars json={hint} /></div>
            </span>
    }
    else{
      return <></>;
    }
  }
  else{
    return <></>;
  }
  
 
}

import React from "react";
import { API } from "../api";
import { getHeaders } from "../api/getHeaders";
import { useLazyLoading } from "./useLazyLoading";

export const useLoadMore = (data) => {
  const { last = true, mode = "lazyLoading", key = null } = data;
  // элементы
  const [state, setState] = React.useState([]);

  const [result, setResult] = React.useState({});

  // пагинация
  const [params, setParams] = React.useState({
    nextLink: "",
    totalCount: 0,
    pageCount: 0,
    currentPage: 0,
    perPage: 0,
  });

  const [totalCount, setTotalCount] = React.useState(0);
  const [sum, setSum] = React.useState(0);

  // индикация загрузки
  const [loading, setLoading] = React.useState(false);

  const [loadingAll, setLoadingAll] = React.useState(last);

  // метод загрузки
  const getState = () => {
    setState([]);
    setLoadingAll(true);
    setLoading(true);
    data.api(data.params).then((res) => {
      setLoadingAll(false);
      setLoading(false);
      let listData;
      if (res?.data?.payments) {
        listData = res?.data.payments;
      } else {
        listData = key ? res?.data[key] : res?.data;
      }

      if (key) setResult(res?.data);

      if (listData?.data !== undefined) {
        setTotalCount(listData?._meta?.totalCount ?? listData?.data?.length);
        setState(listData.data);
        setSum(res?.data?.sum);
        setParams({ ...listData?._meta, nextLink: listData?._links?.next });
      }
    });
  };

  // метод для дозагрузки
  const getNext = () => {
    if (params?.nextLink) {
      setLoading(true);
      const href = params.nextLink?.href;

      API.get(href, getHeaders()).then((res) => {
        setLoading(false);
        let listData;
        if (res.data?.payments) {
          listData = res?.data.payments;
        } else {
          listData = key ? res?.data[key] : res?.data;
        }
        // const listData = key ? res?.data[key] : res?.data
        if (key) setResult(res?.data);
        if (listData?.data !== undefined) {
          setState([...state, ...listData.data]);
          setParams({
            ...listData?._meta,
            nextLink: listData?._links?.next ?? false,
          });
        }
      });
    }
  };

  // есть ли ещё элементы
  const notLast = params?.nextLink ? true : false;

  // первая загрузка
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    last && data.api && state.length < 1 && getState();
  }, []);

  //ленивая загрузка
  const settingLazyLoading =
    mode === "lazyLoading" ? { getNext, loading, notLast, state } : {};
  const [ref] = useLazyLoading(settingLazyLoading);

  // загрузка пока не найдены данные
  React.useEffect(() => {
    if (mode === "search") {
      if (!state.find(data.search)) {
        getNext();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    state,
    loading,
    getState,
    setState,
    ref,
    getNext,
    notLast,
    loadingAll,
    totalCount,
    result,
    sum,
  };
};

export default useLoadMore;

// import React, { useEffect, useState } from "react";
// import { API } from "../api";
// import { getHeaders } from "../api/getHeaders";
// import { useLazyLoading } from "./useLazyLoading";
// import { useSelector } from "react-redux";
// import { selectProfile } from "../store/profile.reducer";
// import { useGetDirection } from "./useGetDirection";
// import { getInArr } from "../helpers/getInArr";
//
// export const useLoadMore = (data) => {
//   const profile = useSelector(selectProfile);
//   const direction = useGetDirection(getInArr(profile.role, ["payments"]));
//   const initFilter = {
//     direction: getInArr(profile.role, ["payments"])
//       ? direction.select[0]?.value
//       : "",
//   };
//   const [filter, setFilter] = useState(initFilter);
//   const setUp = (e) => setFilters((prev) => ({ ...prev, endDate: e }));
//   const setFrom = (e) => setFilters((prev) => ({ ...prev, startDate: e }));
//   const [filters, setFilters] = useState({
//     startDate: null,
//     endDate: null,
//     direction: "",
//     tariffs: "",
//     search: "",
//   });
//   const paramsFilter = {
//     directionId: filters.direction.value,
//     groupId: filters.tariffs,
//   };
//   if (filters.startDate) paramsFilter["startDate"] = filters.startDate;
//   if (filters.endDate) paramsFilter["endDate"] = filters.endDate;
//   if (filter.search) paramsFilter["search"] = filter.search;
//   const { last = true, mode = "lazyLoading", key = null } = data;
//   // элементы
//   const [state, setState] = React.useState([]);
//
//   const [result, setResult] = React.useState({});
//   // пагинация
//   const [params, setParams] = React.useState({
//     nextLink: "",
//     totalCount: 0,
//     pageCount: 0,
//     currentPage: 0,
//     perPage: 0,
//   });
//
//   const [totalCount, setTotalCount] = React.useState(0);
//
//   // индикация загрузки
//   const [loading, setLoading] = React.useState(false);
//
//   const [loadingAll, setLoadingAll] = React.useState(last);
//
//   // метод загрузки
//   const getState = () => {
//     setState([]);
//     setLoadingAll(true);
//     setLoading(true);
//     data.api(data.params).then((res) => {
//       setLoadingAll(false);
//       setLoading(false);
//       const listData = key ? res?.data[key] : res?.data;
//       console.log(res)
//       if (key) setResult(res?.data);
//       if (listData?.data !== undefined) {
//         setTotalCount(listData?._meta?.totalCount ?? listData?.data?.length);
//         getDataState(listData?.data);
//         setParams({ ...listData?._meta, nextLink: listData?._links?.next });
//       }
//     });
//   };
//
//   // метод для дозагрузки
//   const getNext = () => {
//     if (params?.nextLink) {
//       setLoading(true)
//       API.get(params.nextLink?.href, getHeaders()).then(res => {
//
//         setLoading(false)
//         const listData = key ? res?.data[key] : res?.data
//         if (key) setResult(res?.data)
//         if (listData?.data !== undefined) {
//
//           // if(paramsFilter.directionId ) {
//           // } else{
//           setState([...state, ...listData.data])
//           // }
//           setParams({ ...listData?._meta, nextLink: listData?._links?.next ?? false })
//         }
//       })
//     }
//   };
//
//   function getDataState(data) {
//     let newState;
//     let fromParse;
//     let toParse;
//     if (paramsFilter.startDate && paramsFilter.endDate) {
//       let from = new Date(paramsFilter.startDate);
//       fromParse = Date.parse(from) / 1000;
//       let to = new Date(paramsFilter.endDate);
//       to.setDate(to.getDate() + 1);
//       toParse = Date.parse(to) / 1000;
//     }
//     if (paramsFilter.groupId === "Все") {
//       paramsFilter.groupId = "";
//     }
//     if (
//       paramsFilter.directionId &&
//       paramsFilter.groupId === "" &&
//       paramsFilter.startDate === undefined &&
//       paramsFilter.endDate === undefined &&
//       paramsFilter.search === undefined
//     ) {
//       newState = data.filter(
//         (el) => el?.order?.direction_id == paramsFilter.directionId
//       );
//       setState(newState);
//     }
//     if (
//       paramsFilter.directionId &&
//       paramsFilter.groupId === "" &&
//       paramsFilter.startDate === undefined &&
//       paramsFilter.endDate === undefined &&
//       paramsFilter.search
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.order?.direction_id == paramsFilter.directionId &&
//           el?.order?.children.name
//             .toLowerCase()
//             .includes(paramsFilter.search.toLowerCase())
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId == undefined &&
//       paramsFilter.groupId &&
//       paramsFilter.startDate === undefined &&
//       paramsFilter.endDate === undefined &&
//       paramsFilter.search === undefined
//     ) {
//       newState = data.filter(
//         (el) => el?.order?.tariff.name == paramsFilter.groupId
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId == undefined &&
//       paramsFilter.groupId === "" &&
//       paramsFilter.startDate === undefined &&
//       paramsFilter.endDate === undefined &&
//       paramsFilter.search
//     ) {
//       newState = data.filter((el) =>
//         el?.order?.children.name
//           .toLowerCase()
//           .includes(paramsFilter.search.toLowerCase())
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId == undefined &&
//       paramsFilter.groupId &&
//       paramsFilter.startDate === undefined &&
//       paramsFilter.endDate === undefined &&
//       paramsFilter.search
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.order?.tariff.name == paramsFilter.groupId &&
//           el?.order?.children.name
//             .toLowerCase()
//             .includes(paramsFilter.search.toLowerCase())
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId == undefined &&
//       paramsFilter.groupId == "" &&
//       paramsFilter.startDate &&
//       paramsFilter.endDate &&
//       paramsFilter.search === undefined
//     ) {
//       setState([]);
//       newState = data.filter(
//         (el) =>
//           el?.payment.created_at >= fromParse &&
//           el?.payment.created_at < toParse
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId == undefined &&
//       paramsFilter.groupId == "" &&
//       paramsFilter.startDate &&
//       paramsFilter.endDate &&
//       paramsFilter.search
//     ) {
//       setState([]);
//       newState = data.filter(
//         (el) =>
//           el?.payment.created_at >= fromParse &&
//           el?.payment.created_at < toParse &&
//           el?.order?.children.name
//             .toLowerCase()
//             .includes(paramsFilter.search.toLowerCase())
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId == undefined &&
//       paramsFilter.groupId &&
//       paramsFilter.startDate &&
//       paramsFilter.endDate &&
//       paramsFilter.search === undefined
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.payment.created_at >= fromParse &&
//           el?.payment.created_at < toParse &&
//           el?.order?.tariff.name == paramsFilter.groupId
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId == undefined &&
//       paramsFilter.groupId &&
//       paramsFilter.startDate &&
//       paramsFilter.endDate &&
//       paramsFilter.search
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.payment.created_at >= fromParse &&
//           el?.payment.created_at < toParse &&
//           el?.order?.tariff.name == paramsFilter.groupId &&
//           el?.order?.children.name
//             .toLowerCase()
//             .includes(paramsFilter.search.toLowerCase())
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId &&
//       paramsFilter.groupId == "" &&
//       paramsFilter.startDate &&
//       paramsFilter.endDate &&
//       paramsFilter.search === undefined
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.payment.created_at >= fromParse &&
//           el?.payment.created_at < toParse &&
//           el?.order?.direction_id == paramsFilter.directionId
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId &&
//       paramsFilter.groupId == "" &&
//       paramsFilter.startDate &&
//       paramsFilter.endDate &&
//       paramsFilter.search
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.payment.created_at >= fromParse &&
//           el?.payment.created_at < toParse &&
//           el?.order?.direction_id == paramsFilter.directionId &&
//           el?.order?.children.name
//             .toLowerCase()
//             .includes(paramsFilter.search.toLowerCase())
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId &&
//       paramsFilter.groupId &&
//       paramsFilter.startDate === undefined &&
//       paramsFilter.endDate === undefined &&
//       paramsFilter.search === undefined
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.order?.tariff.name == paramsFilter.groupId &&
//           el?.order?.direction_id == paramsFilter.directionId
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.directionId &&
//       paramsFilter.groupId &&
//       paramsFilter.startDate === undefined &&
//       paramsFilter.endDate === undefined &&
//       paramsFilter.search
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.order?.tariff.name == paramsFilter.groupId &&
//           el?.order?.direction_id == paramsFilter.directionId &&
//           el?.order?.children.name
//             .toLowerCase()
//             .includes(paramsFilter.search.toLowerCase())
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.groupId &&
//       paramsFilter.directionId &&
//       paramsFilter.startDate &&
//       paramsFilter.endDate &&
//       paramsFilter.search === undefined
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.order?.direction_id == paramsFilter.directionId &&
//           el?.order?.tariff.name == paramsFilter.groupId &&
//           el?.payment.created_at >= fromParse &&
//           el?.payment.created_at < toParse
//       );
//       setState(newState);
//     } else if (
//       paramsFilter.groupId &&
//       paramsFilter.directionId &&
//       paramsFilter.startDate &&
//       paramsFilter.endDate &&
//       paramsFilter.search
//     ) {
//       newState = data.filter(
//         (el) =>
//           el?.order?.direction_id == paramsFilter.directionId &&
//           el?.order?.children.name
//             .toLowerCase()
//             .includes(paramsFilter.search.toLowerCase()) &&
//           el?.order?.tariff.name == paramsFilter.groupId &&
//           el?.payment.created_at >= fromParse &&
//           el?.payment.created_at < toParse
//       );
//
//       setState(newState);
//     } else {
//       setState(data);
//     }
//   }
//
//   // есть ли ещё элементы
//   const notLast = params?.nextLink ? true : false;
//
//   // первая загрузка
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   // React.useEffect(() => { last && data.api && state.length < 1 && getState() }, [paramsFilter.directionId])
//   useEffect(() => {
//     last && data.api && state?.length < 1 && getState();
//   }, []);
//   useEffect(() => {
//     getState();
//     // getNext()
//   }, [
//     paramsFilter.directionId,
//     paramsFilter.groupId,
//     paramsFilter.startDate,
//     paramsFilter.endDate,
//     paramsFilter.search,
//   ]);
//
//   //ленивая загрузка
//   const settingLazyLoading =
//     mode === "lazyLoading" ? { getNext, loading, notLast, state } : {};
//   const [ref] = useLazyLoading(settingLazyLoading);
//
//   // загрузка пока не найдены данные
//   React.useEffect(() => {
//     if (mode === "search") {
//       if (!state.find(data.search)) {
//         getNext();
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [state]);
//   return {
//     state,
//     loading,
//     getState,
//     setState,
//     ref,
//     getNext,
//     notLast,
//     loadingAll,
//     totalCount,
//     result,
//     filter,
//     setFilter,
//     direction,
//     setUp,
//     setFrom,
//     setFilters,
//     paramsFilter,
//   };
// };
//
// export default useLoadMore;

import React from 'react'
import s from './index.module.css'
import classNames from "classnames";

const Row = (props) => {
  return <div className={classNames(s.row, props.className)}>
    {props.children}
  </div>
}

export default Row

export const geStatRowList = (data) => {
	const newArr = [];
	const sort = (a, b) => (a.sorting > b.sorting ? 1 : -1);
	const lessonTask = data?.lesson?.lessonTasks?.length
		? [...data?.lesson?.lessonTasks]
		: [];
	lessonTask?.sort(sort)?.forEach((task, index) => {
		const result = data?.homeWorkResults?.find(
			(e) => e.task_id === task?.task?.id
		);
		const percent = result ? (result.decided_right === 1 ? 100 : 20) : 0;
		newArr.push({ title: index + 1, value: percent, id: task?.task?.id });
	});
	return newArr;
};

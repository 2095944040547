import React, {useEffect, useState} from "react";
import {Config} from "@/config";
import s from "./index.module.css";
import { GetIco } from "@/utils/icons";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import {connect, useDispatch, useSelector} from "react-redux";
import { ButtonEdit } from "@UI/Button";
import { selectProfile } from "@store/profile.reducer";
import { formateName } from "@helpers/formateName";
import { getAvatar } from "@helpers/getAvatar";
import { selectorOwnBacklogCount } from "@store/children/children.reducer";
import { selectorTotalCountUHW } from "@store/seminarian/seminarian.reducer";
import {convertNumberCost} from "@/helpers/convertNumberCost";
import {SettingsService} from "@/services/SettingsService";
import {SETTINGS_TYPES} from "@/constant";
import { fetchMenu } from "@store/menu.reducer";

const Sidebar = () => {
  const profile = useSelector(selectProfile);
  const isShow = useSelector((state) => state.page.showSidebar);
  const [logo, setLogo] = useState('/logo.svg')

  const balance = profile?.balance !== undefined ? profile.balance : 0;
  const settings = SettingsService.get(SETTINGS_TYPES.GENERAL);

  useEffect(() => {
    if (settings?.mainLogo) {
      setLogo(process.env.REACT_APP_PATH_FILE + (settings?.mainLogo))
    }
  }, [settings])

  return (
    <>
      <aside className={cn({ [s.show]: isShow }, s.wrapper, "app-sidebar")}>
        <div className={s.logoWrapper}>
          <Link to="/" title="На главную">
            <img src={logo} alt={`Школа ${Config.PROJECT_NAME}`} />
          </Link>
        </div>

        <div className={s.userInfo}>
          <div className={s.avatarWrapper}>
            <img
              className={s.avatar}
              src={getAvatar(profile)}
              alt={profile?.name}
            />
            <Link to="/personal" className={s.linkEdit}>
              <ButtonEdit className={s.btnEdit} />
            </Link>
          </div>

          <div className={s.userName}>{formateName(profile?.name, "F I")}</div>

          <span className="hintCenter" hintb={profile?.email}>
            <div className={s.userMail}>{profile?.email}</div>
          </span>
        </div>

        <NavigationApp />

        {profile?.role === "children" && settings?.isShowRating === '1' && (
            <div className={s.rating}>
              {settings?.isFreeCourses === '0' && (
                <div className={s.balance}>
                  Баланс <span>{convertNumberCost(balance ?? 0)} руб</span>
                </div>
              )}
              <Link to="/rating" className={s.ratingLink}>
                <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M15.146 5.41843L10.829 4.79043L8.90203 0.879427C8.81046 0.721892 8.67914 0.591152 8.52121 0.500277C8.36327 0.409401 8.18425 0.361572 8.00203 0.361572C7.81982 0.361572 7.64079 0.409401 7.48286 0.500277C7.32492 0.591152 7.1936 0.721892 7.10203 0.879427L5.17503 4.79143L0.858032 5.41843C0.673167 5.44494 0.499422 5.52269 0.356474 5.64287C0.213526 5.76306 0.10709 5.92087 0.0492202 6.09843C-0.00864935 6.27599 -0.0156393 6.46622 0.029042 6.64755C0.0737234 6.82888 0.168291 6.99408 0.302032 7.12443L3.42503 10.1694L2.68803 14.4694C2.65652 14.6533 2.67709 14.8424 2.74741 15.0152C2.81773 15.188 2.935 15.3377 3.08596 15.4474C3.23692 15.557 3.41554 15.6223 3.60164 15.6357C3.78774 15.6491 3.97388 15.6103 4.13903 15.5234L8.00203 13.4924L11.863 15.5214C12.0282 15.6083 12.2143 15.6472 12.4004 15.6337C12.5865 15.6203 12.7651 15.555 12.9161 15.4454C13.0671 15.3357 13.1843 15.186 13.2547 15.0132C13.325 14.8404 13.3455 14.6513 13.314 14.4674L12.577 10.1674L15.702 7.12443C15.8354 6.99417 15.9298 6.82921 15.9744 6.64817C16.019 6.46713 16.0121 6.27722 15.9545 6.09989C15.8969 5.92256 15.7908 5.76488 15.6483 5.64464C15.5058 5.52439 15.3325 5.44639 15.148 5.41943L15.146 5.41843Z"
                      fill="#F2C94C"
                  />
                </svg>
                Мой рейтинг
              </Link>
            </div>
        )}
      </aside>
    </>
  );
};

export default Sidebar

const MenuItem = ({ onClose, ...props }) => {
  const item = props.item;
  const title = props.item?.title;

  if (item.url.startsWith("http"))
    return (
      <a
        href={item.url}
        onClick={onClose}
        className={s.navLink}
        title={title}
        target="_blank"
        rel="noreferrer"
      >
        <GetIco icon={item.icon} className={s.navIco} />
        {title}
      </a>
    );

  return (
    <NavLink
      exact={item.url === "/" && true}
      to={item.url}
      onClick={onClose}
      className={s.navLink}
      activeClassName={s.active}
      title={title}
    >
      <GetIco icon={item.icon} className={s.navIco} /> {title}
      {/*{item.has_notify && <Notify url={item.url} />}*/}
      {item.noticeCount
          ? <span className={cn("notice", s.notice)}>{item.noticeCount}</span>
          : ''
      }
    </NavLink>
  );
};

export const NavigationApp = connect(
    state => ({ menu: state.menu.items }),
    { fetchMenu}
)(({ menu, fetchMenu, onClose }) => {
  useEffect(_ => {
    fetchMenu()
  }, []);

  return (
    <nav className={cn(s.navList, "list-reset")}>
      {menu && menu?.map((item) => (
        <MenuItem key={item.id} item={item} onClose={onClose} />
      ))}
    </nav>
  );
});

const Notify = ({ url }) => {
  let count = 0;
  const unverifiedHWCount = useSelector(selectorTotalCountUHW);
  if (url === "/dz-students" && unverifiedHWCount > 0)
    count = unverifiedHWCount;

  const ownBacklogCount = useSelector(selectorOwnBacklogCount);
  if (url === "/home-work" && ownBacklogCount > 0) count += ownBacklogCount;

  if (count === 0) return <></>;
  return <span className={cn("notice", s.notice)}>{count}</span>;
};

import s from './index.module.css';
import MainChatPage from "@common/Chat/MainChatPage/MainChatPage";
import CreateChat from "@common/Chat/CreateChat/CreateChat";
import ChatView from "@common/Chat/components/ChatView/ChatView";
import {useEffect, useState} from "react";
import DefaultView from "@common/Chat/DefaultView/DefaultView";
import InChatView from "@common/Chat/InChatView/InChatView";
import ChatInfo from "@common/Chat/ChatInfo/ChatInfo";
import {chatApi} from "@/api";
import { Resizable } from 're-resizable';
import {checkHeight, checkMinWidth, checkWidth} from "@common/Chat/helpers";
import classNames from "classnames";
import {useAddAlert} from "@/hooks/useAddAlert";
const pages = {
    default: DefaultView,
    main: MainChatPage,
    chat: ChatView,
    create: CreateChat,
    inChat: InChatView,
    info: ChatInfo,
}

const Component = ({page,setPage, chat = null, typeAction = 'create', setTypeAction, unReadChats, activeUsers, height}) => {
    const Component = pages[page]
    return (
        <>
            {Component && <Component
                setPage={setPage}
                height={height}
                chat={chat}
                page={page}
                setTypeAction={setTypeAction}
                typeAction={typeAction}
                unReadChats={unReadChats}
                activeUsers={activeUsers}
            />}
        </>
    )
}
const Chat = () => {

    const [updateComponent, setUpdateComponent] = useState(false)
    useEffect(()=>{
        
        const onScroll = (event) => {
            setUpdateComponent(!updateComponent)
        }
      
        window.addEventListener('resize', onScroll);
        
        return () => {
            window.removeEventListener('resize', onScroll);
        }

    }, [])
    
    const showAlert = useAddAlert()

    const [page, setPage] = useState('default')
    const [typeAction, setTypeAction] = useState('create');
    const screenWidth = window.innerWidth;
    const [chat, setChat] = useState(null)
    const widthChatFromLS = JSON.parse(localStorage.getItem('widthChat'));
    const [width, setWidth] = useState(widthChatFromLS ? widthChatFromLS : screenWidth * 0.8)
    const [unReadChats, setUnReadChats] = useState([])
    const [activeUsers, setActiveUsers] = useState([])

    if (widthChatFromLS === null) {
        localStorage.setItem('widthChat', JSON.stringify(width))
    }

    const saveWidthSettings =(width) => {
        setWidth(width)
    }

    const fetchChatData = () => {
        chatApi.getActiveUsers()
          .then((res) => {
              if (res?.data) {
                  setUnReadChats(res?.data?.noReadMessages)
                  setActiveUsers(res?.data?.activeUsers)

                  if (page === 'default') {
                      res.data.notifications?.map(showNewMessageNotification)
                  }
              }
          })
    }

    useEffect(() => {
        fetchChatData()
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            fetchChatData()
        }, 7000);

        // очистка интервала
        return () => clearInterval(timer);
    });

    const showNewMessageNotification = (newMessage) => {
        showAlert({
            title: newMessage.user,
            text: newMessage.message || '',
            files: newMessage.files,
            sound: true,
            onClick: () => {
                changePage('inChat', newMessage.chat_id)
            }
        })
    }

    const changePage = (page, chat = null, typeAction = 'create') => {
        setPage(page)
        setChat(chat)
        setTypeAction(typeAction)
    }

    const minWidth = checkMinWidth(page, unReadChats)
    const maxWidthChat = checkWidth(page, unReadChats)
    const heightChat = checkHeight(page)

    return (
        <Resizable
            className={classNames( s.chats)}
            defaultSize={{
                width: width,
            }}
            minWidth={minWidth}
            maxWidth={ maxWidthChat}
            enable={{bottom:false, top: false, left: true, right: false}}
            minHeight={heightChat}
            maxHeight={heightChat}
            onResizeStop={(e, direction, ref, d) => {
                saveWidthSettings(width + d.width)
            }}
        >
            <Component
                page={page}
                height={heightChat}
                setPage={changePage}
                chat={chat}
                setTypeAction={setTypeAction}
                typeAction={typeAction}
                unReadChats={unReadChats}
                activeUsers={activeUsers}
            />
        </Resizable>
    )
}

export default Chat;
import React, { createRef, useEffect, useState } from 'react'
import s from './index.module.css'

const Range = ({ min = 0, max = 100, step = 0, value = min, className = '', onChange = () => false, ...props }) => {

  return <div className={`${s.rangeWrapper}  ${className}`}><input
    type='range'
    onChange={onChange}
    className={`${s.range}`}
    style={{ '--min': min, '--max': max, '--value': value, '--values': `'${value}'` }}
    value={value}
    min={min} max={max} step={step}
    {...props} />
    <span className={s.value} style={{ '--min': min, '--max': max, '--value': value, '--values': `'${value}'` }}>{value}</span>
  </div>
}
export default Range

export const DoubleRange = ({ name, min, max, stem, valuemin = min, valuemax = max }) => {
  let [rangeValueMin, setRangeValueMin] = useState(valuemin)
  let [rangeValueMax, setRangeValueMax] = useState(valuemax)

  let refRange = createRef()

  useEffect(() => {
    refRange.current.style.setProperty(`--track-w`, `${refRange.current.clientWidth}px`)
  })

  return <div ref={refRange} className={`${s.rangeBt}`} style={{ "--min": min, "--max": max, "--a": rangeValueMin, "--b": rangeValueMax, "--track-w": '148px' }}>
    <input onInput={(e) => setRangeValueMin(e.target.value)} id={name + 'first'} type="range" min={min} max={max} value={rangeValueMin} step={stem} />
    <output htmlFor={name + 'first'} style={{ "--c": "var(--a)" }}></output>
    <input onInput={(e) => setRangeValueMax(e.target.value)} id={name + '-last'} type="range" min={min} max={max} value={rangeValueMax} step={stem} />
    <output htmlFor={name + '-last'} style={{ "--c": "var(--b)" }}></output>
  </div>
}

import { useEffect } from "react";
import { useRef } from "react";

export const useInputNew = ({
  onChange = (v) => {},
  value = "",
  name = "",
  onError = (v, n) => {},
  type,
  setDefaultValue = false,
  defaultValue = false,
  ...props
}) => {
  const ref = useRef();

  const setError = (val) =>
    onError((prev) => {
      const items = { ...prev.items, [name]: val };
      let flag = true;
      for (let key in items) {
        if (items[key].value) flag = false;
      }
      return { items, valid: flag };
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (props?.validate === "remove") setError(false);
  }, [props?.validate]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      setDefaultValue &&
      props?.options?.length > 0 &&
      !props?.options.find((item) => item.value === value)
    )
      onChange(props?.options[0].value);

    if(defaultValue && !setDefaultValue && props?.options.find((item) => item.value === value) !== undefined){
      onChange(value);
    }
  }, [props?.options]);

  const reset = () => onChange("");
  const bind = {
    value: value || value === 0 ? value : "",
    name,
    setError: (val) => setError(val),
    onChange: (e) =>
      onChange(
        type === "number"
          ? e.target.value.replace(/[^\d]/g, "")
          : e.target.value
      ),
    refkey: ref,
    type: type === "number" ? "text" : type,
    ...props,
  };
  return [bind, reset, ref];
};

export const checkFile = {
	img: (path) => check(path, ['jpg', 'png', 'svg']),
	audio: (path) => check(path, ['mp3', 'wav']),
};

const check = (name, arr) => {
	if (!name) return false;
	let flag = false;
	arr?.forEach((item) => {
		if (name.endsWith(item)) flag = true;
	});
	return flag;
};

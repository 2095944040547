import s from './index.module.css'
import style from '../../index.module.css'
import { themeAPI } from '../../../../../../api'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { useGetApi } from '../../../../../../hooks/useGetApi'
import { InputSelect } from '../../../../../UI/InputNew'
import { useMemo } from 'react'



export const Theme = ({ data, edit, setErrorsList, showErrors, direction }) => {
  const filter = useMemo(() => {
    const res = []
    if (data?.direction_id) res.push([`[direction_id][in][]=${data?.direction_id}`])
    if (direction) res.push([`[direction_id][in][]=${direction}`])
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [bindSelect] = useInputNew({
    name: 'topic_id',
    value: data?.topic_id,
    onChange: e => edit(prev => ({ ...prev, 'topic_id': e, 'subtopic_id': null })),
    placeholder: 'Тема',
    validate: showErrors,
    onError: setErrorsList,
    errorText: 'Не выбрана тема',
    options: useGetApi(
      e => themeAPI.getAll(e),
      { filter },
      res => res?.data?.data?.map(item => ({ label: item.name, value: item.id }))
    )
  })

  return <div className={s.wrapper}>
    <span className={style.heading}>Тема задачи: </span>
    <InputSelect className={style.inputNew} {...bindSelect} />
  </div>
}

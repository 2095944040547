import {languagesApi} from "@/api";

const initialState = [
    // {
    //     language: "Русский",
    //     id: 0,
    //     is_active: 0,
    //     rank: 0
    // },
    // {
    //     language: "Беларусский",
    //     id: 1,
    //     is_active: 0,
    //     rank: 1
    // },
    // {
    //     language: "Казахский",
    //     id: 2,
    //     is_active: 0,
    //     rank: 2
    // },
    // {
    //     language: "Армянский",
    //     id: 3,
    //     is_active: 0,
    //     rank: 3
    // },
    // {
    //     language: "Киргизский",
    //     id: 4,
    //     is_active: 0,
    //     rank: 4
    // },
    // {
    //     language: "Узбекский",
    //     id: 5,
    //     is_active: 0,
    //     rank: 5
    // },
    // {
    //     language: "Английский",
    //     id: 6,
    //     is_active: 0,
    //     rank: 6
    // },
    // {
    //     language: "Французский",
    //     id: 7,
    //     is_active: 0,
    //     rank: 7
    // },
    // {
    //     language: "Испанский",
    //     id: 8,
    //     is_active: 0,
    //     rank: 8
    // },
    // {
    //     language: "Индийский",
    //     id: 9,
    //     is_active: 0,
    //     rank: 9
    // },
];

const languagesReduser = (state = initialState, action) => {
	switch (action.type) {
		case 'CHANGEACTIVE':
			return action.data;
		default:
			return state;
	}
};

export default languagesReduser;

export const changeLanguages = (data) => {
    return {
        type: 'CHANGEACTIVE', data
    }
};

export const setLanguage = (data, setLoadingLanguage) => (dispatch) => {
    languagesApi.save({data}).then((res) => {
        setLoadingLanguage(false)
        dispatch(changeLanguages(res.data))
    });
}
export const IconImage = () => {
   return <>
     <svg height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
       <path fill="#4ACFD9" d="M499.604,0H12.396C7.931,0,4.312,3.62,4.312,8.084v495.832c0,4.465,3.62,8.084,8.084,8.084h487.208 c4.465,0,8.084-3.62,8.084-8.084V8.084C507.688,3.62,504.069,0,499.604,0z"/>
       <path fill="#0295AA" d="M12.396,0C7.931,0,4.312,3.62,4.312,8.084v495.832c0,4.465,3.62,8.084,8.084,8.084h244.837V0H12.396z"/>
       <path fill="#CDD4D5" d="M505.203,407.541L397.413,304.063c-3.104-2.981-8-3.006-11.135-0.059l-101.072,95.008
        l-132.589-184.38c-1.519-2.113-3.962-3.364-6.563-3.364c-0.013,0-0.026,0-0.039,0c-2.616,0.013-5.063,1.29-6.57,3.428L5.787,404.405
        c-0.96,1.364-1.476,2.99-1.476,4.657v94.855c0,4.465,3.62,8.084,8.084,8.084h487.208c4.465,0,8.084-3.62,8.084-8.084v-90.543
        C507.688,411.172,506.79,409.065,505.203,407.541z"/>
       <path fill="#FFD1A9" d="M335.764,155.217c-37.741,0-68.446,30.705-68.446,68.446s30.705,68.446,68.446,68.446 s68.446-30.705,68.446-68.446S373.506,155.217,335.764,155.217z"/>
       <g>
         <path fill="#FF8C29" d="M267.318,223.663c0,37.741,30.705,68.446,68.446,68.446V155.217 C298.023,155.217,267.318,185.922,267.318,223.663z"/>
         <path fill="#FF8C29" d="M507.688,431.158H4.312v72.758c0,4.465,3.62,8.084,8.084,8.084h487.208 c4.465,0,8.084-3.62,8.084-8.084V431.158z"/>
       </g>
       <g>
        <path fill="#FFFFFF" d="M452.177,98.088c-4.465,0-8.084-3.62-8.084-8.084c0-9.807-7.979-17.785-17.785-17.785
          s-17.785,7.979-17.785,17.785c0,4.465-3.62,8.084-8.084,8.084c-4.465,0-8.084-3.62-8.084-8.084c0-9.807-7.979-17.785-17.785-17.785
          c-9.807,0-17.785,7.979-17.785,17.785c0,4.465-3.62,8.084-8.084,8.084c-4.465,0-8.084-3.62-8.084-8.084
          c0-9.807-7.979-17.785-17.785-17.785s-17.785,7.979-17.785,17.785c0,4.465-3.62,8.084-8.084,8.084s-8.084-3.62-8.084-8.084
          c0-18.722,15.232-33.954,33.954-33.954c10.351,0,19.637,4.657,25.869,11.985c6.232-7.329,15.517-11.985,25.869-11.985
          c10.351,0,19.637,4.657,25.869,11.985c6.232-7.329,15.518-11.985,25.869-11.985c18.722,0,33.954,15.232,33.954,33.954
          C460.261,94.469,456.641,98.088,452.177,98.088z"/>
        <path fill="#FFFFFF" d="M171.924,167.074c-4.465,0-8.084-3.62-8.084-8.084c0-9.807-7.979-17.785-17.785-17.785
          c-9.807,0-17.785,7.979-17.785,17.785c0,4.465-3.62,8.084-8.084,8.084s-8.084-3.62-8.084-8.084c0-9.807-7.979-17.785-17.785-17.785
          s-17.785,7.979-17.785,17.785c0,4.465-3.62,8.084-8.084,8.084s-8.084-3.62-8.084-8.084c0-18.722,15.232-33.954,33.954-33.954
          c10.352,0,19.637,4.657,25.869,11.985c6.232-7.329,15.517-11.985,25.869-11.985c18.722,0,33.954,15.232,33.954,33.954
          C180.008,163.454,176.389,167.074,171.924,167.074z"/>
      </g>
     <path fill="#9BAAAB" d="M152.618,214.631c-1.519-2.113-3.962-3.364-6.563-3.364c-0.013,0-0.026,0-0.039,0
c-2.616,0.013-5.063,1.29-6.57,3.428L5.787,404.405c-0.96,1.364-1.476,2.99-1.476,4.657v94.855c0,4.465,3.62,8.084,8.084,8.084
h244.837V360.112L152.618,214.631z"/>
       <path fill="#FD6A33" d="M4.312,431.158v72.758c0,4.465,3.62,8.084,8.084,8.084h244.837v-80.842H4.312z"/>
       <path fill="#CDD4D5" d="M152.618,214.631c-1.519-2.113-3.962-3.364-6.563-3.364c-0.013,0-0.026,0-0.039,0
	c-2.616,0.013-5.063,1.29-6.57,3.428l-48.847,69.33h111.919L152.618,214.631z"/>
       <path fill="#E6EAEA"
             d="M397.413,304.063c-3.104-2.981-8-3.006-11.135-0.059l-61.309,57.63h132.413L397.413,304.063z"/>
    </svg>
   </>
}

export const IconVideo = () => {
  return <>
    <svg width="24px" height="24px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27 4H5C3.34315 4 2 5.34315 2 7V25C2 26.6569 3.34315 28 5 28H27C28.6569 28 30 26.6569 30 25V7C30 5.34315 28.6569 4 27 4Z"
        fill="#B71C1C"/>
      <path
        d="M25 24H7C6.73478 24 6.48043 23.8946 6.29289 23.7071C6.10536 23.5196 6 23.2652 6 23C6 22.7348 6.10536 22.4804 6.29289 22.2929C6.48043 22.1054 6.73478 22 7 22H25C25.2652 22 25.5196 22.1054 25.7071 22.2929C25.8946 22.4804 26 22.7348 26 23C26 23.2652 25.8946 23.5196 25.7071 23.7071C25.5196 23.8946 25.2652 24 25 24Z"
        fill="#EEEEEE"/>
      <path
        d="M19 25C18.7348 25 18.4804 24.8946 18.2929 24.7071C18.1054 24.5196 18 24.2652 18 24V22C18 21.7348 18.1054 21.4804 18.2929 21.2929C18.4804 21.1054 18.7348 21 19 21C19.2652 21 19.5196 21.1054 19.7071 21.2929C19.8946 21.4804 20 21.7348 20 22V24C20 24.2652 19.8946 24.5196 19.7071 24.7071C19.5196 24.8946 19.2652 25 19 25Z"
        fill="#EEEEEE"/>
      <path
        d="M20.45 12.67L13.45 9.16996C13.2978 9.09325 13.1285 9.05673 12.9581 9.06386C12.7878 9.071 12.6222 9.12155 12.4769 9.21072C12.3316 9.2999 12.2115 9.42473 12.1281 9.57336C12.0446 9.722 12.0005 9.8895 12 10.06V17.94C12.0013 18.1182 12.0502 18.2928 12.1416 18.4457C12.233 18.5987 12.3637 18.7244 12.52 18.81C12.6648 18.897 12.831 18.942 13 18.94C13.1872 18.9406 13.3709 18.8886 13.53 18.79L20.53 14.41C20.6816 14.3156 20.8051 14.1823 20.8877 14.024C20.9704 13.8658 21.0091 13.6883 21 13.51C20.9905 13.3339 20.9347 13.1635 20.8381 13.0159C20.7415 12.8684 20.6076 12.7491 20.45 12.67Z"
        fill="#EEEEEE"/>
      <path
        d="M5 4C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V25C2 25.7956 2.31607 26.5587 2.87868 27.1213C3.44129 27.6839 4.20435 28 5 28H16V4H5Z"
        fill="#E53935"/>
      <path
        d="M7 22C6.73478 22 6.48043 22.1054 6.29289 22.2929C6.10536 22.4804 6 22.7348 6 23C6 23.2652 6.10536 23.5196 6.29289 23.7071C6.48043 23.8946 6.73478 24 7 24H16V22H7Z"
        fill="#FAFAFA"/>
      <path
        d="M13.45 9.16996C13.2978 9.09325 13.1285 9.05673 12.9581 9.06386C12.7878 9.071 12.6222 9.12155 12.4769 9.21072C12.3316 9.2999 12.2115 9.42473 12.1281 9.57336C12.0446 9.722 12.0005 9.8895 12 10.06V17.94C12.0013 18.1182 12.0502 18.2928 12.1416 18.4457C12.233 18.5987 12.3637 18.7244 12.52 18.81C12.6648 18.897 12.831 18.942 13 18.94C13.1872 18.9406 13.3709 18.8886 13.53 18.79L16 17.24V10.44L13.45 9.16996Z"
        fill="#FFEBEE"/>
    </svg>
  </>
}

export const IconAudio = () => {
  return <>
    <svg width="24px" height="24px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
      <g id="Sound_On">
        <path
          d="M18.6,5.2C18.3,5,18,5,17.7,5L5.8,9H2c-0.5,0-1,0.5-1,1v6v6c0,0.5,0.5,1,1,1h3.8l11.8,4c0.1,0,0.2,0,0.3,0   c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.4-0.5,0.4-0.8V16V6C19,5.7,18.8,5.4,18.6,5.2z"
          fill="#FFC10A"/>
        <g>
          <path
            d="M25.8,16c0.1-2.9-0.8-5.6-2.8-7.8c-0.4-0.4-1-0.5-1.4-0.1c-0.4,0.4-0.5,1-0.1,1.4c1.6,1.8,2.4,4.1,2.2,6.5    c0,0,0,0.1,0,0.1c-0.2,2.4-1.3,4.7-3.1,6.3c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.5-0.1,0.7-0.3    c2.2-2,3.6-4.7,3.8-7.6C25.8,16.2,25.8,16.1,25.8,16z"
            fill="#eee"/>
          <path
            d="M27.3,5.5c-0.4-0.4-1-0.5-1.4-0.1c-0.4,0.4-0.5,1-0.1,1.4c2.3,2.6,3.4,6,3.2,9.2c-0.2,3.4-1.7,6.7-4.4,9.1    c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.5-0.1,0.7-0.3C29.1,23.8,30.8,20,31,16C31.1,12.3,29.9,8.5,27.3,5.5z"
            fill="#eee"/>
          <path
            d="M5,10c0-0.4,0.3-0.8,0.7-1l0.2,0H2c-0.5,0-1,0.5-1,1v6v6c0,0.5,0.5,1,1,1h3.8l-0.2,0C5.3,22.8,5,22.4,5,22    v-6V10z"
            fill="#fa6607"/>
        </g>
      </g>
    </svg>
  </>
}

export const IconWord = () => {
  return <>
    <svg width="24px" height="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.536,2.323V4.868c3.4.019,7.12-.035,10.521.019a.783.783,0,0,1,.912.861c.054,6.266-.013,12.89.032,19.157-.02.4.009,1.118-.053,1.517-.079.509-.306.607-.817.676-.286.039-.764.034-1.045.047-2.792-.014-5.582-.011-8.374-.01l-1.175,0v2.547L2,27.133Q2,16,2,4.873L18.536,2.322"
        fill="#283c82"/>
      <path
        d="M18.536,5.822h10.5V26.18h-10.5V23.635h8.27V22.363h-8.27v-1.59h8.27V19.5h-8.27v-1.59h8.27V16.637h-8.27v-1.59h8.27V13.774h-8.27v-1.59h8.27V10.911h-8.27V9.321h8.27V8.048h-8.27V5.822"
        fill="#fff"/>
      <path
        d="M8.573,11.443c.6-.035,1.209-.06,1.813-.092.423,2.147.856,4.291,1.314,6.429.359-2.208.757-4.409,1.142-6.613.636-.022,1.272-.057,1.905-.1-.719,3.082-1.349,6.19-2.134,9.254-.531.277-1.326-.013-1.956.032-.423-2.106-.916-4.2-1.295-6.314C8.99,16.1,8.506,18.133,8.08,20.175q-.916-.048-1.839-.111c-.528-2.8-1.148-5.579-1.641-8.385.544-.025,1.091-.048,1.635-.067.328,2.026.7,4.043.986,6.072.448-2.08.907-4.161,1.352-6.241"
        fill="#fff"/>
    </svg>
  </>
}

export const IconExcel = () => {
  return <>
    <svg width="24px" height="24px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.781,4.405H18.651V2.018L2,4.588V27.115l16.651,2.868V26.445H28.781A1.162,1.162,0,0,0,30,25.349V5.5A1.162,1.162,0,0,0,28.781,4.405Zm.16,21.126H18.617L18.6,23.642h2.487v-2.2H18.581l-.012-1.3h2.518v-2.2H18.55l-.012-1.3h2.549v-2.2H18.53v-1.3h2.557v-2.2H18.53v-1.3h2.557v-2.2H18.53v-2H28.941Z"
        fill="#20744a"
        fillRule="evenodd"/>
      <rect x="22.487" y="7.439" width="4.323" height="2.2" fill="#20744a"/>
      <rect x="22.487" y="10.94" width="4.323" height="2.2" fill="#20744a"/>
      <rect x="22.487" y="14.441" width="4.323" height="2.2" fill="#20744a"/>
      <rect x="22.487" y="17.942" width="4.323" height="2.2" fill="#20744a"/>
      <rect x="22.487" y="21.443" width="4.323" height="2.2" fill="#20744a"/>
      <polygon
        points="6.347 10.673 8.493 10.55 9.842 14.259 11.436 10.397 13.582 10.274 10.976 15.54 13.582 20.819 11.313 20.666 9.781 16.642 8.248 20.513 6.163 20.329 8.585 15.666 6.347 10.673"
        fill="#ffffff"
        fillRule="evenodd" />
    </svg>
  </>
}

export const IconPdf = () => {
  return <>
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 56 64">
      <g>
        <path fill="#8C181A" d="M5.1,0C2.3,0,0,2.3,0,5.1v53.8C0,61.7,2.3,64,5.1,64h45.8c2.8,0,5.1-2.3,5.1-5.1V20.3L37.1,0H5.1z"/>
        <path fill="#6B0D12" d="M56,20.4v1H43.2c0,0-6.3-1.3-6.1-6.7c0,0,0.2,5.7,6,5.7H56z"/>
        <path opacity="0.5" fill="#FFFFFF" enableBackground="new" d="M37.1,0v14.6c0,1.7,1.1,5.8,6.1,5.8H56L37.1,0z"/>
      </g>
      <path fill="#FFFFFF" d="M14.9,49h-3.3v4.1c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7V42.9c0-0.6,0.5-1.1,1.1-1.1h3.7
        c2.4,0,3.8,1.7,3.8,3.6C18.7,47.4,17.3,49,14.9,49z M14.8,43.1h-3.2v4.6h3.2c1.4,0,2.4-0.9,2.4-2.3C17.2,44,16.2,43.1,14.8,43.1z
         M25.2,53.8h-3c-0.6,0-1.1-0.5-1.1-1.1v-9.8c0-0.6,0.5-1.1,1.1-1.1h3c3.7,0,6.2,2.6,6.2,6C31.4,51.2,29,53.8,25.2,53.8z M25.2,43.1
        h-2.6v9.3h2.6c2.9,0,4.6-2.1,4.6-4.7C29.9,45.2,28.2,43.1,25.2,43.1z M41.5,43.1h-5.8V47h5.7c0.4,0,0.6,0.3,0.6,0.7
        s-0.3,0.6-0.6,0.6h-5.7v4.8c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7V42.9c0-0.6,0.5-1.1,1.1-1.1h6.2c0.4,0,0.6,0.3,0.6,0.7
        C42.2,42.8,41.9,43.1,41.5,43.1z"/>
    </svg>
  </>
}

import s from './index.module.css'
import style from '../../index.module.css'
import { useEffect, useState } from 'react'
import UploadFile from '../../../../../UI/UploadFile'
import { ButtonDel } from '../../../../../UI/Button'
import classNames from 'classnames'
import { GetIco } from '../../../../../../utils/icons'
import { homeTaskApi } from '../../../../../../api'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { InputFormate } from '../../../../../UI/InputNew'



export const Key = ({ data, edit }) => {
  const [fileList, setFilterList] = useState(data?.homeTaskFiles?.filter(item => item.type === 'key'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setFilterList(data?.homeTaskFiles?.filter(item => item.type === 'key')) }, [])
  const removeFile = id => homeTaskApi.removeFile(id).then(res => {
    if (res.data.status === 'success') setFilterList(prev => [...prev.filter(item => item.id !== id)])
  })


  const input = (val, i) => edit(prev => ({ ...prev, 'key_files': [...prev.key_files.map((item, index) => (index === i) ? { file: val } : item)] }))
  const remove = i => edit(prev => ({ ...prev, 'key_files': [...prev.key_files.filter((_, index) => index !== i)] }))
  const add = () => edit(prev => ({ ...prev, 'key_files': [...prev.key_files, { file: '' }] }))


  const [bindFormate] = useInputNew({
    name: 'key_text',
    value: data?.key_text,
    onChange: e => edit(prev => ({ ...prev, 'key_text': e })),
    placeholder: 'Введите ключ',
  })


  return <div className={s.wrapper}>
    <span className={style.heading}>Введите ключ к заданию:</span>
    <div className={s.list}>
      <InputFormate className={style.inputNew} {...bindFormate} />
      {fileList?.map((item, index) => <Item key={index} index={index} value={item.file_path} showMode={true} remove={() => removeFile(item.id)} />)}
      {data['key_files']?.map((item, index) => <Item key={index} index={index} value={item.file} input={input} remove={() => remove(index)} />)}
      {data['key_files']?.length === 0
        ? <button className={s.btnImg} onClick={add}><GetIco icon='picture' /></button>
        : <button className={classNames(style.btnAdd, s.btnAdd)} onClick={add}>+</button>
      }
    </div>

  </div>
}

const Item = ({ index, value, input, remove, showMode = false }) => {
  return <div className={s.row}>
    <UploadFile className={s.file} value={value} onChange={val => input(val, index)} showMode={showMode} />
    <ButtonDel className={s.btnRemove} onClick={remove} />
  </div>
}

import { getInArr } from '../helpers/getInArr';

export const getHeaders = async (arr) => {
	const token = (await localStorage.getItem('userToken')) ?? '';

	const result = {};
	if (token) result['Authorization'] = `Bearer ${token}`;
	if (getInArr('FormData', arr)) result['Content-type'] = 'multipart/form-data';
	return { headers: result };
};

/* eslint-disable no-useless-escape */
export const getIdUrlYoutube = (url) => {
  if (url) {
    // eslint-disable-next-line no-useless-escape
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) return match[2];
    else return "error";
  }
  return "";
};

import classNames from "classnames";
import cn from "classnames";
import { useState } from "react";
import s from "./index.module.css";

const CheckBox = ({
  className = "",
  value = false,
  hardValue = false,
  disabled,
  ...props
}) => {
  let [state, setState] = useState(value);
  let actualState = hardValue ? value : state;

  return (
    <div
      className={cn(
        s.wrapper,
        { [s.active]: actualState },
        { [className]: className },
        { [s.disabled]: disabled }
      )}
      onClick={() => setState(!state)}
      data-checkbox={actualState}
      {...props}
    ></div>
  );
};

export default CheckBox;

export const CheckBoxLabel = ({ classNameCheckBox = '', className = '', label = '', value, onClick, ...props }) => {
  return <button className={classNames(s.btn, { [className]: className })} onClick={() => onClick(!value)} {...props}>
    <CheckBox className={classNames(s.checkboxLabel, { [classNameCheckBox]: classNameCheckBox })} value={value} hardValue={true} />
    <span>{label}</span>
  </button>
}

import { checkFile } from '../../../../helpers/checkFile'
import AudioPlayer from '../../../UI/AudioPlayer'
import s from './Audios.module.css'


export const Audios = ({ audios }) => {
  const check = item => checkFile.audio(item.file_path)
  return <>
    <h3 className={s.title}>{audios.length > 1 ? 'Прослушайте аудиодорожки' : 'Прослушайте аудиодорожку'}</h3>
    <ul className={s.list}>{audios?.filter(check)?.map((audio, i) => <li key={i} className={s.item}><AudioPlayer audioTrack={process.env.REACT_APP_PATH_FILE + audio.file_path} /></li>)}</ul>
  </>
}

import classNames from 'classnames'
import Preloader from '../../../UI/Preloader'
import { Hint } from '../Hint/Hint'
import { VideoAnswer } from '../VideoAnswer/VideoAnswer'
import s from './AnswerButton.module.css'

export const AnswerButton = ({ status, onAnswer, hint, video }) => {


  return <div className={s.actionWrapper}>
    <Button status={status} onAnswer={onAnswer} />
    {hint && <Hint hint={hint} />}
    {video && <VideoAnswer video={video} />}
  </div>
}

const Button = ({ status, onAnswer: { handler, loading, isAnswer } }) => {
  switch (status) {
    case 0: return <button type='submit' disabled={!isAnswer} className={classNames('btn')} onClick={handler}>{loading ? <Preloader /> : 'Ответить'}</button>
    case 2: return (<>
      <div className={s.clue}>Выберите верный ответ и затем отправьте его</div>
      <button type='submit' disabled={!isAnswer} className={classNames('btn btn--red')} onClick={handler}>{loading ? <Preloader /> : 'Ответить еще раз'}</button>
      </>)
    case 7: return <button type='button' className='btn btn--red' style={{ opacity: 1 }} disabled>Ответ принят</button>
    default: return <button type='button' className='btn btn--violet' style={{ opacity: 1 }} disabled>Ответ принят</button>
  }
}

import s from './Default.module.css';
import {AvatarDefaultIcon, MessagaChatIcon, OpenViewChatIcon} from '@/utils/icons';
import UserImage from "@common/Chat/components/UserImage/UserImage";
import {chatApi} from "@/api";
import {useEffect} from 'react';
import logo from '../../../../images/logo.png';
const DefaultView = ({setPage,  unReadChats}) => {
   const unreadCound = unReadChats.reduce((accumulator, curr) => Number(accumulator) + Number(curr.count), 0)
    return (
        <div className={s.page}>
            <div className={s.openIcon} onClick={() => setPage('main')}>
                <OpenViewChatIcon width={'24'} height={'24'}/>
            </div>
            <div className={s.wrapper}>
                <div className={s.chats}>
                    {unReadChats && unReadChats.slice(0,3).map(el => (
                        <div className={s.unreadEl} key={el.chatId} onClick={() => setPage('inChat', el.chatId)}>
                            <UserImage path={el.chatAvatar} customClass={s.chatImage} previewDisabled={false}/>
                            <div className={s.purple}>{el.count}</div>
                        </div>
                    ))}
                </div>
                <div className={s.messages} onClick={() => setPage('main')}>
                    <MessagaChatIcon/>
                    {unreadCound > 0 && <div
                        className={s.purple}>{unreadCound}</div>}
                </div>
            </div>
        </div>
    )
}

export default DefaultView;
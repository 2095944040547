
import classNames from 'classnames'
import HeaderPage from '../../common/HeaderPage'

const NoAccess = ({ ...props }) => {
  return <>
    <HeaderPage title='нет доступа' />
    <main className={classNames('page-content')}>
      нет доступа
    </main>
  </>
}


export default NoAccess

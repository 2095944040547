import {communicationApi} from "../api";

const SET_COMMUNICATIONS_COUNT= 'impulse/communication/SET_COMMUNICATIONS_COUNT';

const initialState = {
	count: 0
};

const communicationReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_COMMUNICATIONS_COUNT:
			const count = action.count
			return {
				...state,
				count
			}
		default:
			return state;
	}
};

export const setCommunicationsCount = (count) => ({ type: SET_COMMUNICATIONS_COUNT, count });

export const loadCommunicationsCount = () => (dispatch) =>
	communicationApi
		.count()
		.then(({ data }) => dispatch(
			setCommunicationsCount(Number(data?.data?.count || 0)))
		);

export default communicationReducer;

import s from "./index.module.css";
import bg from "./bg.png";

const NoMobile = () => {
  return (
    <>
      <main className={`page-content ${s.wrapper}`}>
        <p className={s.descr}>В мобильной версии эта страница недоступна!</p>
        <span className={s.bg}>
          <img src={bg} alt="" />
        </span>
      </main>
    </>
  );
};

export default NoMobile;

import s from './index.module.css'
import style from '../../index.module.css'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { InputNew } from '../../../../../UI/InputNew'



export const Video = ({ data, edit }) => {

  const [bindName] = useInputNew({
    name: 'video_solution',
    value: data?.video_solution,
    onChange: e => edit(prev => ({ ...prev, 'video_solution': e })),
    placeholder: 'Ссылка на видео',
  })

  return <div className={s.wrapper}>
    <span className={style.heading}>Ссылка на видеорешение:</span>
    <InputNew className={style.inputNew} {...bindName} />
  </div>
}

import s from './InfoList.module.css'


export const InfoList = (props) => {
  return <div className={s.list}>
    {props?.number && <Item title='Экз №' value={props?.number} />}
    {props?.complexity && <Item title='Сложность' value={props.complexity} />}
    {props?.ball !== null && props?.ball !== undefined && <ItemBalls value={props?.ball} weight={props?.weight} />}
  </div>
}

const Item = ({ title, value }) => <div className={s.capsule}><span className={s.capsuleLeft}>{title}</span><span className={s.capsuleRight}>{value}</span></div>

const ItemBalls = ({ value, weight }) => {
  return <>
    <div className={s.capsule}>
      <span className={s.capsuleLeft} style={{ zIndex: 4 }}>Баллы</span>
      <span className={s.capsuleRight} style={{ zIndex: 3 }} data-hint={weight ? weight?.find(el => el.score === value)?.description : ''}>
        {value}
        <i className={s.i}>i</i>
      </span>
    </div>
  </>
}

import fileDoc from '../images/dev/doc.pdf';
import ava1 from '../images/dev/teacher.png';

import ava4 from '../images/dev/student.png';
import ava5 from '../images/dev/admin.png';
import ava6 from '../images/dev/student2.png';

import avaR from '../images/dev/rt.admin.png';
import avaM from '../images/dev/manager.png';
import avaH from '../images/dev/HRDirector.png';
import avaG from '../images/dev/guest.png';

const ADD_EMPLOYEE_PROFILE = 'impulse/profile/ADD_EMPLOYEE_PROFILE';
const ADD_STUDENT_PROFILE = 'impulse/profile/ADD_STUDENT_PROFILE';
const EDIT_USER_PROFILE = 'impulse/profile/EDIT_USER_PROFILE';

const initialState = [
	{
		id: 0,
		created_at: 1659421607,
		name: 'Гость',
		role: 'other',
		ava: avaG,
		email: '',
		phone: '',
		birthdate: '',
	},
	{
		id: 1,
		created_at: 1659421607,
		name: 'Иванов Миша Дмитрович',
		role: 'children',
		ava: ava4,
		email: 'm.ivanov@mail.com',
		emailStatus: 'noVerified',
		phone: '79992006969',
		telStatus: 'noVerified',
		birthdate: '14.05.2000',
		gender: 'm',
		lastOnline: '15.01.2022 16:00',
		class: '9',
		lvl: 1,
		startDate: '18.04.2019',
		direction: [
			{
				value: 'russian',
				tariff: 'personal',
				teacher: 1,
			},
			{
				value: 'mathematics',
				tariff: 'personal',
				teacher: 3,
			},
		],
		vk: 'https://vk.com',
		parent: {
			name: 'Михайлова Алена Игоревна',
			phone: '+7(921)678-78-90',
			email: 'mail@mail.ru',
			communicationMethod: ['telegram'],
			timeZone: '+6',
		},
		city: 'Москва',
		spent: 89990,
		ltv: 80,
		finance: {
			pulse: 156,
			balance: 10000,
		},
		promocode: 'IMPULS8967',

		works: [
			{
				id: 0,
				name: 'Домашняя работа 1',
				theme: 'Экономические задачи',
				lesson: 'Урок 1',
				course: 'Неорганическая химия',
				type: 'dz',
				status: 'verified',
				accomplishment: {
					onTime: false,
					percent: 90,
					balls: 80,
				},
				direction: 'chemistry',
				tasks: [
					{
						id: 0,
						childResponse: 'P',
						ball: 3,
						comment: {
							text: 'Атомы  из указанных в ряду элементов имеют один неисправный электрон на s-подуровне',
							file: fileDoc,
						},
						percent: 100,
						status: 'accepted',
					},
					{
						id: 1,
						childResponse: '5',
						ball: 3,
						comment: {
							text: 'Атомы  из указанных в ряду элементов имеют один неисправный электрон на s-подуровне',
							file: fileDoc,
						},
						percent: 50,
						status: 'accepted',
					},
					{
						id: 2,
						childResponse: ['1-2', '2-1', '3-3', '4-4', '5-5'],
						ball: 3,
						comment: {
							text: 'Атомы  из указанных в ряду элементов имеют один неисправный электрон на s-подуровне',
							file: fileDoc,
						},
						percent: 50,
						status: 'accepted',
					},
					{
						id: 3,
						childResponse: '213',
						ball: 3,
						comment: {
							text: 'Атомы  из указанных в ряду элементов имеют один неисправный электрон на s-подуровне',
							file: fileDoc,
						},
						percent: 50,
						status: 'accepted',
					},
					{
						id: 4,
						childResponse: '213',
						ball: 3,
						comment: {
							text: 'Атомы  из указанных в ряду элементов имеют один неисправный электрон на s-подуровне',
							file: fileDoc,
						},
						percent: 50,
						status: 'accepted',
					},
					{
						id: 5,
						childResponse: ['321', 'правильный ответ', 'правильный ответ'],
						ball: 3,
						comment: {
							text: 'Атомы  из указанных в ряду элементов имеют один неисправный электрон на s-подуровне',
							file: fileDoc,
						},
						percent: 50,
						status: 'accepted',
					},
					{
						id: 6,
						childResponse: [fileDoc],
						trueStatement: false,
						ball: 3,
						comment: {
							text: 'Атомы  из указанных в ряду элементов имеют один неисправный электрон на s-подуровне',
							file: fileDoc,
						},
						percent: 70,
						status: 'accepted',
					},
					{
						id: 7,
						childResponse: 'Большой и длинный текст',
						trueStatement: false,
						ball: 3,
						comment: {
							text: 'Атомы  из указанных в ряду элементов имеют один неисправный электрон на s-подуровне',
							file: fileDoc,
						},
						percent: 70,
						status: 'accepted',
					},
				],
				date: {
					delivery: '2022-01-15',
					verification: '2022-01-16',
				},
			},
			{
				id: 1,
				name: 'Домашняя работа 2',
				theme: 'Экономические задачи',
				lesson: 'Урок 2',
				course: 'Неорганическая химия',
				type: 'dz',
				status: 'notVerified',
				direction: 'chemistry',
				tasks: [
					{
						id: 0,
						childResponse: 'P',
						ball: 3,
						percent: 100,
						status: 'accepted',
					},
					{
						id: 1,
						childResponse: '5',
						ball: 3,
						percent: 50,
						status: 'accepted',
					},
					{
						id: 6,
						childResponse: [fileDoc],
						status: 'accepted',
					},
					{
						id: 7,
						childResponse: 'Большой и длинный текст',
						status: 'accepted',
					},
				],
				date: {
					delivery: '2022-01-15',
					verification: '2022-01-16',
				},
			},
			{
				id: 2,
				name: 'Домашняя работа 3',
				theme: 'Экономические задачи',
				lesson: 'Урок 2',
				course: 'Неорганическая химия',
				type: 'dz',
				status: 'notCompleted',
				direction: 'chemistry',
				tasks: [{ id: 0 }, { id: 1 }, { id: 6 }],
				date: {
					delivery: '2022-01-15',
					verification: '2022-01-16',
				},
			},
			{
				id: 3,
				name: 'Уникальная домашняя работа',
				type: 'dz',
				status: 'notCompleted',
				direction: 'chemistry',
				tasks: [{ id: 0 }, { id: 1 }, { id: 6 }],
				date: {
					delivery: '2022-01-15',
					verification: '2022-01-16',
				},
			},
			{
				id: 4,
				name: 'Все задания',
				theme: 'Тестовая карточка',
				lesson: '№1',
				course: 'Тест',
				type: 'dz',
				status: 'notCompleted',
				direction: 'chemistry',
				tasks: [
					{ id: 0 },
					{ id: 1 },
					{ id: 2 },
					{ id: 3 },
					{ id: 4 },
					{ id: 5 },
					{ id: 6 },
					{ id: 7 },
				],
				date: {
					delivery: '2022-01-15',
					verification: '2022-01-16',
				},
			},
		],

		sop: [
			{
				id: 0,
				name: 'СОП по биологии',
			},
		],
	},
	{
		id: 2,
		created_at: 1659421607,
		name: 'Егорова Анна Алексеевна',
		role: 'seminarian',
		status: 'high',
		ava: ava1,
		phone: '79216787790',
		email: 'mail@mail.ru',
		birthdate: '02.02.1998',
		rating: 15,
		startDate: '12.12.2018',
		gender: 'f',
		lastOnline: '15.01.2022 16:00',
		address: 'Москва, ул. Красная 78',
		post: [
			'Учитель русского языка',
			'Учитель литературы',
			'HR директор',
			'Личный менеджер',
		],
		contract: [
			{ label: 'Договор сотрудничества', url: fileDoc },
			{ label: 'Договор конфиденциальности', url: fileDoc },
			{ label: 'Договор сотрудничества', url: fileDoc },
			{ label: 'Договор конфиденциальности', url: fileDoc },
			{ label: 'Договор сотрудничества', url: fileDoc },
		],
		social: {
			vk: 'https://vk.com',
		},
		education: 'Университет им. Василия',
		experience: '5 лет',
		information: '',
		heading: [],
		remove: false,
	},
	{
		id: 3,
		created_at: 1659421607,
		name: 'Волкова Мария Алексеевна',
		role: 'senior-seminarian',
		roles: ['senior-seminarian', 'teacher', 'manager', 'HRDirector'],
		ava: avaR,
		email: 'volkova@mail.com',
		phone: '79992006969',
		birthdate: '',
	},
	{
		id: 4,
		created_at: 1659421607,
		name: 'Аракелян Артур Альбертович',
		role: 'admin',
		roleList: 'all',
		ava: ava5,
		email: 'arakelyan@mail.com',
		address: 'Москва, ул. Красная 78',
		phone: '79992006969',
		birthdate: '14.05.2000',
		gender: 'm',
		lastOnline: '15.01.2022 16:00',
		social: {
			vk: 'https://vk.com',
		},
		education: 'Университет им. Василия',
		experience: '5 лет',
		information: '',
		heading: [],
		remove: false,
	},
	{
		id: 5,
		created_at: 1659421607,
		name: 'Агасян Михаил Алексеевич',
		role: 'personal-manager',
		ava: avaM,
		email: 'agasyan@mail.com',
		phone: '79992006969',
		birthdate: '',
	},
	{
		id: 6,
		created_at: 1659421607,
		name: 'Сизова Наталья Алексеевна',
		role: 'hr-manager',
		ava: avaH,
		email: 'sizova@mail.com',
		phone: '79992006969',
		birthdate: '',
	},
];

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_EMPLOYEE_PROFILE:
			return [
				...state,
				{
					status: 'high',
					id: state.length + 1,
					ava: ava1,
					phone: '',
					contract: [],
					...action.profile,
				},
			];
		case ADD_STUDENT_PROFILE:
			const date = new Date();
			return [
				...state,
				{
					status: 'high',
					id: state.length + 1,
					ava: ava6,
					role: 'children',
					spent: 0,
					ltv: 0,
					lvl: 0,
					vk: '',
					emailStatus: 'noVerified',
					telStatus: 'noVerified',
					promocode: 'IMPULS' + state.length + 1,
					startDate: `${date.getDate()}.${
						date.getMonth() + 1
					}.${date.getFullYear()}`,
					...action.profile,
				},
			];
		case EDIT_USER_PROFILE:
			return [
				...state.map((el, index) => {
					if (index === state.indexOf(action.data.profile))
						el = action.data.newProfile;
					return el;
				}),
			];
		default:
			return state;
	}
};

export const setEmployeeProfile = (profile) => ({
	type: ADD_EMPLOYEE_PROFILE,
	profile,
});
export const setStudentProfile = (profile) => ({
	type: ADD_STUDENT_PROFILE,
	profile,
});
export const editUserProfile = (data) => ({ type: EDIT_USER_PROFILE, data });

export default usersReducer;

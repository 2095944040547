import classNames from 'classnames'
import { jsonConvert } from '../../../../helpers/jsonConvert'
import s from './Comment.module.css'


export const Comment = ({ type, ...props }) => {
  switch (type) {
    case 'decided': return <Reading {...props} />
    default: return <></>
  }
}

const Reading = ({ params, ...props }) => {
  const files = jsonConvert(params?.result?.comment_files, 'json')?.length > 0 ? jsonConvert(params?.result?.comment_files, 'json') : []
  return <div className={s.wrapper}>
    <h3 className={s.title}>Комментарий учителя</h3>
    {params?.result?.comment && <p className={s.text}>{params?.result?.comment}</p>}

    <div className={s.list}>
      {files?.map((item, index) => <a href={process.env.REACT_APP_PATH_FILE + item.path} className={classNames(s.btn, 'btn')} target='_blank' rel="noreferrer">Вложение №{index + 1}</a>)}
    </div>

  </div>
}

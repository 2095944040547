import LayoutAuth from '@common/LayoutAuth'
import {useEffect, useState} from 'react'
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import s from './index.module.css'
import { SetTitlePage } from '@common/SetTitlePage'
import classNames from 'classnames'
import { usersAPI } from '../../../api'
import {AuthService} from "../../../services/AuthService";
import Preloader from "../../UI/Preloader";
import ModalComponent from '@common/Modal'
import { Modal } from 'antd'

const SignUpVk = () => {
  const hist = useHistory()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const search = useLocation().search;

  useEffect(() => {
    const params = new URLSearchParams(search);
    const payload = params.get("payload");
    const state = params.get("state");
    const authParams = JSON.parse(payload)

    setLoading(true)

    if (!authParams) {
      hist.push('/auth')
    }

    usersAPI
        .signupVk({
          silent_token: authParams?.token,
          uuid: authParams?.uuid
        })
        .then(({data: {user, token}}) => {
          AuthService.auth(user, token)

          hist.push('/')
        })
        .catch(() => {
          Modal.error({
            autoFocusButton: null,
            centered: true,
            afterClose: () => hist.push('/auth'),
            title: 'Ошибка авторизации',
            content: 'Не удалось авторизироваться через VK. Попробуйте позже',
          })
        })
  }, [])

  return <LayoutAuth>
    <SetTitlePage title='Авторизация через VK' />

    <div className={classNames('new_card', s.card)}>
      <h2 className={s.title}>Авторизация через VK</h2>
    </div>

    <ModalComponent active={true}>
      <div className={s.modalBody}>
        <Preloader />
        <h3 className={s.modalMessage}>Подождите, идет авторизация</h3>
      </div>
    </ModalComponent>

  </LayoutAuth>
}

export default SignUpVk

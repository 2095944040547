import { excelAPI } from "../api";

const DOWNLOAD_EXCEL_REQUEST = 'DOWNLOAD_EXCEL_REQUEST';
const DOWNLOAD_EXCEL_SUCCESS = 'DOWNLOAD_EXCEL_SUCCESS';

const initialState = {
  downloading: false,
};

const downloadExcelReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_EXCEL_REQUEST:
      return { 
        ...state, 
        downloading: true,
      }
    case DOWNLOAD_EXCEL_SUCCESS:
      return { 
        ...state, 
        downloading: false,
      }
    default: return state
  }
}

export default downloadExcelReducer

const downloadExcelRequest = () => ({ type: DOWNLOAD_EXCEL_REQUEST });
const downloadExcelSuccess = () => ({ type: DOWNLOAD_EXCEL_SUCCESS });

export const downloadExcel = dispatch => {
  dispatch(downloadExcelRequest())
  excelAPI.getExcelTable({ responseType: 'blob' })
  .then(res => {
      dispatch(downloadExcelSuccess())
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'data.xlsx')
      document.body.appendChild(link)
      link.click()
    })
}

export const selectorExcelDownloader = state => state.excel

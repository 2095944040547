export const nextId = (arr, type = 'id') => {
	let res = 0;
	arr.forEach((item) => {
		if (item[type] && item[type] > res) res = item[type];
	});
	return res + 1;
};

export const newId = () =>
	+String(performance.now()).replace('.', '') + Date.now();

export const levelImpulse = [
	{ lvl: 1, name: 'Новичок', min: 0, max: 100 },
	{ lvl: 2, name: 'Падаван', min: 100, max: 300 },
	{ lvl: 3, name: 'Ученик', min: 300, max: 600 },
	{ lvl: 4, name: 'Знаток', min: 600, max: 1000 },
	{ lvl: 5, name: 'Профи', min: 1000, max: 1500 },
	{ lvl: 6, name: 'Мастер', min: 1500, max: 2200 },
	{ lvl: 7, name: 'Гуру', min: 2200, max: 3000 },
	{ lvl: 8, name: 'Мыслитель', min: 3000, max: 4000 },
	{ lvl: 9, name: 'Мудрец', min: 4000, max: 5500 },
	{ lvl: 10, name: 'Философ', min: 5500, max: 7500 },
	{ lvl: 11, name: 'Просветленный', min: 7500, max: 10000 },
	{ lvl: 12, name: 'Оракул', min: 10000, max: 14000 },
	{ lvl: 13, name: 'Гений', min: 14000, max: 19000 },
	{ lvl: 14, name: 'ИИ', min: 19000, max: 25000 },
	{ lvl: 15, name: 'Высший разум', min: 25000 },
];
export const SETTINGS_TYPES = {
	// GENERAL_SETTINGS: 'general-settings',
	MENU_SETTINGS: 'menu-settings',
	GENERAL: 'general',
	MENU: 'menu',
	CUSTOM: 'custom',
};

export const homeworkStatus = {
	notPassed: 0,
	notVerified: 5,
	verified: 10,
};

export const courseVisibility = {
	open: 1,
	restricted: 2,
	linear: 3,
};

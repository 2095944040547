const SHOW = 'impulse/confirm/SHOW'
const HIDE = 'impulse/confirm/HIDE'

const initialState = {
	show: false,
	title: null,
	text: null,
	loading: false,
	onConfirm: new Function(),
	onCancel: new Function(),
}

const confirmReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW:
			return {
				show: true,
				title: action.title,
				text: action.text,
				loading: action.loading,
				onConfirm: action.onConfirm,
				onCancel: action.onCancel,
			}
		case HIDE:
			return initialState
		default:
			return state
	}
}

export const show = (params) => ({ type: SHOW, ...params })
export const hide = () => ({ type: HIDE })

export default confirmReducer

import s from './index.module.css'

const ProgressBar = ({ value = 0 }) => {
  let progress = value < 0 ? 0 : value;
  progress = progress > 100 ? 100 : progress;

  return <div id="progress-bar" className={s.progressBar} style={{'--progress': `${progress}%`}}>
    <span>{ progress }%</span>
  </div>
}

export default ProgressBar

import {BalanceIcon} from "@/utils/icons";
import {convertNumberCost} from "@/helpers/convertNumberCost";
import React from "react";
import {SettingsService} from "@/services/SettingsService";
import {SETTINGS_TYPES} from "@/constant";
import s from './index.module.css';
const BalanceBlock = ({profile}) => {
    const settings = SettingsService.get(SETTINGS_TYPES.GENERAL);
    const balance = profile?.balance !== undefined ? profile.balance : 0;
    return (
        <div className={s.balanceBlock}>
            {settings?.isFreeCourses === '0' && (
                <div className={s.balanceWrapper}>
                    <div className={s.balanceIcon}>
                        <BalanceIcon/>
                    </div>
                    <div className={s.balance}>
                        <div className={s.balanceCount}>
                            Баланс
                            <div className={s.money}>{convertNumberCost(balance ?? 0)} ₽</div>
                        </div>
                        <div className={s.balanceFooter}>
                            <span>0 €</span>
                            <span>0 $</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BalanceBlock;
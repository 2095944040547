import axios from "axios";
import jwt_decode from "jwt-decode";
import { AuthService } from "../services/AuthService";
import { getInArr } from "../helpers/getInArr";
import ErrorHandling from "../services/ErrorHandling";
import {publicUrl} from "@/api/index";

class apiCreator {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_URL_API,
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",

        withCredentials: true,
      },
    });

    // Перехватчик ответа от сервера проверка на ошибку авторизации 401
    // Если код ответа был 401 - отправляет запрос на обновление токена на основании cookie
    this.api.interceptors.response.use(
      (res) => {
        const url = res.config.url

        if (!publicUrl.includes(url)) {
          const token = localStorage.getItem("userToken");

          if (!token && !res.data.token) {
            return Promise.reject();
          }
        }

        return res.data || res.status === 204 ? res : Promise.reject();
      },

      function (error) {
        const originalRequest = error.config;

        if (!getInArr(error?.response?.status, [401])) {
          ErrorHandling.showAlert(error.response.data);
          return error;
        }

        // Если базовый URL был равен URL обновлению токена - возвращаем пустой объект ответа
        if (
          error?.config?.url ===
          `${process.env.REACT_APP_URL_API}auth/refresh-token`
        )
          return Promise.reject(AuthService.logout());

        if (error?.response?.status === 401) {
          const token = localStorage.getItem("userToken");
          return new Promise((res) => {
            AuthService.refreshToken().then(() => {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              res(axios(originalRequest));
            });
          });
        }
      }
    );
    // Перехватчик запроса. Перед отправкой запроса проверяет время жизни JWT токена
    // Если вермя жизни осталось меньше 10 секунд - отправляет запрос на обновление токена

    this.api.interceptors.request.use(async (config) => {
      const token = await localStorage.getItem("userToken");
      if (token) {
        const payload = jwt_decode(token);
        const expTime = payload.exp * 1000;
        const curTime = new Date().getTime();

        if (expTime - curTime <= 30000 || curTime > expTime) {
          await axios
            .post(
              `${process.env.REACT_APP_URL_API}auth/refresh-token`,
              {refresh_token: localStorage.getItem('userRefreshToken')},
              {
                withCredentials: true,
                Authorization: `Bearer ${token}`,
              }
            )
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem("userToken", res?.data?.token);
                config.headers["Authorization"] = `Bearer ${res?.data?.token}`;
              }
            });
        }
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          withCredentials: true,
          Authorization: `Bearer ${token}`,
        },
        baseURL: process.env.REACT_APP_URL_API,
      };
    });
  }
}

export default new apiCreator().api;

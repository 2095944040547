import React from 'react';

export const useLazyLoading = (data) => {
	const ref = React.useRef();
	const [view, setView] = React.useState(false);

	let observer = new IntersectionObserver((entries) =>
		entries.forEach((entry) => {
			setView(entry.isIntersecting);
			if (entry.isIntersecting && data.notLast && !data.loading && data.getNext)
				data.getNext();
		})
	);

	React.useEffect(() => {
		if (ref.current?.lastElementChild)
			observer.observe(ref.current.lastElementChild);
		return () => observer.disconnect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.state]);

	return [ref, view];
};

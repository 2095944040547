import { useState, useEffect } from "react";

export const useGetApi = (getApi, data, getData) => {
  const [state, setState] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getApi(data)?.then((res) => {
      setState(getData(res));
    });
  }, []);
  return state;
};

import React from "react"
import cn from 'classnames'
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import ProbeTasksContainer from "./ProbeTasksContainer"
import s from './index.module.css'
import HeaderPage from "../../../common/HeaderPage"
import { additionalProbeApi } from "../../../../api"
import { jsonConvert } from "../../../../helpers/jsonConvert"
import update from 'immutability-helper'
import Button, { ButtonPrev } from "../../../UI/Button"
import { InputFile, InputFormate, InputNew } from "../../../UI/InputNew"
import { useInputNew } from "../../../UI/InputNew/useInputNew"
import { errorListScroll } from "../../../../helpers/errorListScroll"
import { stateList } from "../ThemeEditor/helper/stateList"


export const AdditionalProbeEdit = () => {
  const hist = useHistory()
  const { id, directionId } = useParams()

  const initData = {
    'direction_id': directionId,
    "name": "",
    "description": "",
    "probe_path": "",
    "tasks": []
  }

  const [state, setState] = React.useState(initData)
  const [errorsList, setErrorsList] = React.useState([])
  const [showErrors, setShowErrors] = React.useState(false)

  React.useEffect(() => {
    if (id !== 'add') additionalProbeApi.view({ id, expand: ['tasks', 'tasks.task'] }).then(res => setState({
      ...res?.data,
      description: jsonConvert(res?.data?.description, 'json'),
      tasks: res?.data?.tasks?.map(i => ({ ...(i.task ? i.task : i), sorting: i.sorting }))?.sort((a, b) => a?.sorting > b?.sorting ? 1 : -1)
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const title = id !== 'add' ? state.name ? state?.name : 'Дополнительный пробник (без названия)' : 'Добавить дополнительный пробник'
  const pathName = id !== 'add' ? 'Редактирование' : 'Добавление'


  const [description, editDesc] = React.useState(state?.description)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { if (state) setState(update(state, { description: { $set: description } })) }, [description])


  const [bindName] = useInputNew({
    name: 'name',
    value: state?.name,
    onChange: v => setState(prev => update(prev, { name: { $set: v } })),
    onError: setErrorsList,
    placeholder: 'Введите название',
    title: 'Название',
    validate: showErrors,
  })

  const [bindDirection] = useInputNew({
    name: 'description',
    value: state?.description,
    onChange: editDesc,
    onError: setErrorsList,
    placeholder: 'Введите описание',
    title: 'Описание',
    validate: showErrors
  })

  const [bindFile] = useInputNew({
    name: 'probe_path',
    value: state?.probe_path,
    onChange: e => setState({ ...state, 'probe_path': e }),
    onError: setErrorsList,
    title: 'Файл пробника',
    placeholder: 'Выберите файл',
    validate: showErrors,
    accept: "application/pdf",
  })


  const remove = (id === 'add') ? false : () => additionalProbeApi.remove({ id }).then(res => { hist.goBack() })


  const save = () => {
    setShowErrors(true)
    const data = new FormData()
    data.append("direction_id", directionId)
    data.append("name", state?.name)
    data.append("description", jsonConvert(state?.description))
    data.append("tasks", jsonConvert(stateList(state, 'tasks')) ?? [])
    if (typeof state['probe_path'] === 'object' && state['probe_path'] !== null) data.append("probe_path", state['probe_path'])

    if (errorsList.valid)
      if (id !== 'add')
        additionalProbeApi.upd({ id, data }).then(res => {
          if (res.data.status === 'success') {
            hist.goBack()
          }
        })
      else additionalProbeApi.add({ data }).then(res => {
        if (res.data.status === 'success') {
          hist.goBack()
        }
      })
    else errorListScroll(errorsList)
  }

  return <>
    <HeaderPage title={title} part={['', pathName]} />
    <main className={cn(s.page, 'page-content')}>
      <ButtonPrev />

      <Buttons save={save} remove={remove} />


      <section className={s.description}>
        <InputNew className={s.inputNew} {...bindName} />
        <InputFormate className={s.inputNew} {...bindDirection} />
        <InputFile className={s.inputNew} {...bindFile} />
      </section>

      <ProbeTasksContainer state={state} setState={setState} />

      <Buttons save={save} remove={remove} />


    </main>
  </>
}

const Buttons = ({ save, remove }) => {
  return <div className={s.buttons}>
    <Button styles='accent' action={save}>Сохранить все изменения</Button>
    {remove && <Button styles='red' action={remove}>Удалить</Button>}
  </div>
}

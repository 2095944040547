/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
// @ts-ignore

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
// @ts-ignore

import { setCurrentPage } from "@store/page-reducer";
import { routes } from "@/routes/list";
import { Props_SetTitlePage } from "src/types/type/propsTypes";

export const SetTitlePage = (props: Props_SetTitlePage) => {
  const dispatch = useDispatch();
  const pathnames = useLocation()
    .pathname.split("/")
    .filter((el: any) => el);
  const path = `/${pathnames.slice(0, pathnames.length).join("/")}`;
  const name = routes.find((el) => el.path === path)?.label;
  const title = props?.title ?? name;
  const part = props?.part ?? ["-"];

  useEffect(() => {
    dispatch(setCurrentPage({ title, part }));
  }, [title]);
  return <></>;
};

import React from 'react';

export const useLoad = (data) => {
	const { last = true, api } = data;
	// элементы
	const [state, setState] = React.useState(data.initState ?? '');

	// индикация загрузки
	const [loading, setLoading] = React.useState(last);

	// ошибки
	const [error, setError] = React.useState('');

	// метод загрузки
	const getState = () => {
		setState(data.initState ?? '');
		setLoading(true);
		api(data?.params)
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					if (data.key !== undefined) setState(res.data[data.key]);
					else setState(res.data);
				}
			})
			.catch((err) => setError(err));
	};

	// первая загрузка
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		last && api && getState();
	}, []);

	// сброс
	const reset = () => {
		setState(data.initState ?? '');
	};

	return { state, getState, setState, loading, error, reset };
};

export default useLoad;

import store from '../store/redux.store'
import rolesEnum from "@/constant/rolesEnum";

const roleActive = () => { 
    const role = store.getState().profile.profile.role;

    const adminActive = () => {
        if(role == rolesEnum.admin || role == rolesEnum.juniorAdmin) {
            return true;
        }
        return false;
    }

    return { adminActive }; 
};
export default roleActive;
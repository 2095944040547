import { Link } from 'react-router-dom';
import {Config} from "@/config";
import s from './logo.module.css';
import {useEffect, useState} from "react";
import {settingApi} from "@/api";
import Translation from "../../../common/Header/Translation"

const Logo = () => {
  const [logo, setLogo] = useState('/logo.svg')

  useEffect(() => {
    settingApi
        .getAll()
        .then(({ data }) => {
          data?.map(param => param.key === 'mainLogo'
            ? setLogo(process.env.REACT_APP_PATH_FILE + param.value)
            : null
          )
        })
  })

  return (
    <div className={s.logoWrap}>
      <Link to="/auth" className={s.logo}>
        <img src={logo} alt={`Школа ${Config.PROJECT_NAME}`} />
      </Link>
      <Translation/>
    </div>
)}
 
export default Logo;

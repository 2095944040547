import {FC, useState, useEffect} from "react";
import {nanoid} from "nanoid";
import cn from "classnames";

import {GetIco} from "../../../utils/icons";
import classNames from "classnames";
import {ButtonDel} from "../Button";

import s from './upload-multiple-files.module.css'
import {InputFile as Input} from "../InputNew";
import {useInputNew} from "../InputNew/useInputNew";


type FileType = {
  id: string;
  file?: File;
  path?: string
};

interface IUploadMultipleFilesProps {
  defaultFiles?: FileType[];
  onInput?: (files: File[]) => void
  disabled?: boolean
  accept?: string
  maxFiles?: number
  className?: string
}

export const UploadMultipleFiles: FC<IUploadMultipleFilesProps> = (
{
  onInput,
  defaultFiles = [],
  disabled,
  accept,
  maxFiles = -1,
  className
}) => {
  const [fileList, setFileList] = useState<FileType[]>(defaultFiles);

  useEffect(() => {
    if (onInput) {
      const parseFiles = fileList
          .filter(item => item.file !== undefined)
          .map(file => file.file) as File[]
      onInput(parseFiles)
    }
  }, [fileList])

  const input = (id: string, newFile: File) => {
    setFileList((prevState) =>
        prevState.map((file) => {
          if (file.id === id) {
            return { ...file, file: newFile };
          }
          return file;
        })
    );
  };

  const remove = (id: string) => {
    setFileList((prevState) => prevState.filter((file) => file.id !== id));
  };

  const add = () => {
    const newFile: FileType = { id: nanoid() };
    setFileList((prevState) => [...prevState, newFile]);
  };

  return (
        <div className={cn(s.list, className)}>
          {fileList?.map((item) => (
              <Item key={item.id} id={item.id} value={item.path || item.file} input={input} remove={remove} disabled={disabled} accept={accept} />
          ))}

            {fileList.length < maxFiles && (
                <>
                {fileList.length === 0 ? (
                        <button className={s.btnImg} onClick={add} disabled={disabled}>
                            <GetIco icon="picture" />
                        </button>
                    ) : (
                        <button className={classNames(s.btnAdd, s.btnAdd)} onClick={add} disabled={disabled}>
                            +
                        </button>
                    )}
                </>
            )}
        </div>
  );
};

interface IItemProps {
  id: string
  value?: string | File;
  input: (id: string, file: File) => void;
  remove: (id: string) => void;
  showMode?: boolean;
  disabled?: boolean
  accept?: string;
}

const Item: FC<IItemProps> = ({ id, value, input, remove,  disabled, accept }) => {
  const [bindFiles] = useInputNew({
    name: 'files',
    value: value as any,
    onChange: (file) => input(id, file),
    placeholder: 'Загрузите файл',
    disabled: disabled,
    type: ''
  })

  return (
      <div className={s.row}>
        {/*@ts-ignore*/}
        <Input className={s.file} {...bindFiles} accept={accept} />
        <ButtonDel className={s.btnRemove} onClick={() => remove(id)} disabled={disabled}/>
      </div>
  );
};

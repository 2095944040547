import LayoutAuth from '@common/LayoutAuth'
import { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import s from './index.module.css'
import { SetTitlePage } from '@common/SetTitlePage'
import { Button, Form, Input, Modal } from 'antd'
import classNames from 'classnames'
import { usersAPI } from '../../../api'

const RestorePassword = () => {
  const hist = useHistory()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})

  const getValueFrom = (e) => {
    const name = e.target.id
    setError(prev => ({ ...prev, [name]: null }))
  }

  const restorePassword = async (data) => {
    setLoading(true)
    const result = await usersAPI.restorePassword(data)
    setLoading(false)

    if (result?.data?.status === 'success') {
      Modal.success({
        autoFocusButton: null,
        centered: true,
        afterClose: () => hist.push('/auth'),
        title: 'Восстановление пароля',
        content: 'На вашу электронную почту направлено письмо с новым паролем',
      })
    } else {
      // const validateList = {}
      // const errors = result.response.data.error
      // for (let key in errors)
      //   validateList[key] = {
      //     validateStatus: "error",
      //     help: errors[key][0],
      //     getValueFromEvent: getValueFrom
      //   }
      // setError(validateList)
      Modal.error({
        autoFocusButton: null,
        centered: true,
        title: 'Восстановление пароля',
        content: 'Невозможно сбросить пароль: почта не указана в профиле',
      })
    }
  }

  const onFinish = (data) => restorePassword(data)

  return <LayoutAuth>
    <SetTitlePage title='Восстановление пароля' />

    <div className={classNames('new_card', s.card)}>

      <h2 className={s.title}>Восстановление пароля</h2>

      <Form {...{ onFinish, layout: 'vertical' }}>
        <Form.Item name='email' rules={[
          { required: true, message: 'Пожалуйста, введите e-mail или номер телефона' },
          // { type: 'email', message: 'Введите верный e-mail' }
        ]}
          {...error?.email}>
          <Input placeholder='Электронная почта или номер телефона при регистрации' disabled={loading} />
        </Form.Item>

        {error.status && <Form.Item>
          <div className="ant-form-item-explain-error">{error.text}</div>
        </Form.Item>}

        <Form.Item>
          <div className={s.buttons}>
            <Button htmlType="submit" className={s.buttonLogin} {...{ loading }}>
              Восстановить
            </Button>
          </div>
        </Form.Item>

        <div>
          <Button type='link' onClick={() => { hist.push('/auth') }}>Авторизация</Button>
        </div>
      </Form>
    </div>
  </LayoutAuth>
}

export default RestorePassword

import {SettingsService} from "@/services/SettingsService";

import {SETTINGS_TYPES} from "@/constant";

import 'antd/dist/reset.css';
import '../fonts/montserrat/montserrat.css';
import '../fonts/inter/inter.css';
import './variables.css';
import './index.css';
import './global.css';
import './button.css';
import './input.css';
import './new_styles.css';
import './antd_styles.css';

import './swiper.css';

if(SettingsService.get(SETTINGS_TYPES.GENERAL)?.isShowNewDesign === '1'){
    import('./global_new_design.css');
}
//убрать динамический импорт после перехожа на новый дизайн
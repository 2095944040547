import Sidebar from '@common/Sidebar'
import Header from '@common/Header'
import BottomMenu from '@common/BottomMenu'
import {Config} from "@/config";

const Layout = ({ children }) => {
  return <div className={'app-wrapper'} >
    <Sidebar />
    <Header />
    {children}
    <BottomMenu />
  </div>
}

export default Layout

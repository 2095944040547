import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import s from './index.module.css'


const Textarea = ({ className, error, style, onChange = e => { }, ...props }) => {
  const refTextarea = useRef()
  const refDiv = useRef()


  let func = e => {
    onChange(e)
    let textarea = refTextarea.current
    let div = refDiv.current
    div.style.width = textarea.clientWidth + 'px'
    div.innerText = textarea.value
    let height = div.clientHeight
    textarea.style.height = height + 'px'
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { props.value && func({ target: { value: props.value } }) }, [])

  return <div style={style} className={s.wrapper}>
    <textarea ref={refTextarea} onChange={func} className={classNames(s.textarea, { [className]: className }, { [s.error]: error })} {...props}></textarea>
    <div ref={refDiv} className={s.textarea}>{props.value}</div>
  </div>
}

export default Textarea

import s from './index.module.css'
import CheckBox from '../../../../../UI/CheckBox'


export const OrderImportant = ({ data, edit }) => {
  const val = data.option_order_matters
  const setVal = () => edit(prev => ({ ...prev, 'option_order_matters': val === 1 ? 0 : 1 }))

  return <div className={s.wrapper}>
    <Input val={val === 1} setVal={setVal} text='Важен порядок' />
  </div>
}

const Input = ({ val, setVal, text }) => {
  return <div className={s.row} onClick={setVal}>
    <CheckBox className={s.checkbox} value={val} hardValue={true} />
    <span className={s.text}>{text}</span>
  </div>
}

import s from './InChatView.module.css';
import '../styles.css';
import ChatMessage from "@common/Chat/components/ChatMessage/ChatMessage";
import React, {useEffect, useRef, useState} from "react";
import {chatApi} from "@/api";
import sendIcon from '../icons/3682321.png'
import {
    ArrowLeftIcon, ClipIcon,
    CloseIcon,
    DotsIcon,
    PaperClipIcon
} from "@/utils/icons";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectProfile} from "@store/profile.reducer";
import {CollapseBtn} from '@UI/Button'
import {useInputNew} from "@UI/InputNew/useInputNew";
import * as InputNew from "@UI/InputNew/Input";
import InputEmoji from 'react-input-emoji'
import {getRightWord} from "@common/Chat/helpers";
import Moment from "react-moment";
import Popup from "@common/Chat/components/Popup/Popup";
import FixedMessage from "@common/Chat/components/ChatMessage/FixedMessage";
import UserImage from "@common/Chat/components/UserImage/UserImage";
import axios from "axios";
import moment from "moment";
import chatView from "@common/Chat/components/ChatView/ChatView";

const InChatView = ({setPage, chat, activeUsers, height}) => {
    const profile = useSelector(selectProfile);
    const [action, setAction] = useState('')
    const [isVisiblePopup, setIsVisiblePopup] = useState(false);
    const [data, setData] = useState(null);
    const [message, setMessage] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [answerTo, setAnswerTo] = useState(null);
    const [answerAuthor, setAnswerAuthor] = useState(null)
    const messageEndRef = useRef(null)
    const [file, setFile] = useState(null);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);
    const [saving, setSaving] = useState(false);
    const [progress, setProgress] = useState(0);
    const titleChat = data?.members?.length === 2 ? (data?.members.find(member => member.name !== profile.name)).name : data?.name;
    const fixedRef = useRef(null);
    const chatsListRef = useRef(null);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        chatApi.getChat(chat).then((res) => {
            setData(res?.data)
            setIsNeedUpdate(false)
        })
    }, [isNeedUpdate]);

    useEffect(() => {
        const timer = setInterval(() => {
            chatApi.getChat(chat)
                .then((res) => {
                    if (res?.data) {
                        if (res?.data.messages.length !== data.messages.length) {
                            setData(res?.data)
                        }
                    }
                })
        }, 2000);

        // очистка интервала
        return () => clearInterval(timer);
    });

    useEffect(() => {
        chatApi.readAllChatMessages(chat).then((res) => {})
    }, [chat])

    const addMes = (chatId, message, parentMessageId, file) => {
        setSaving(true)
        setProgress(0)
        appendMessage(message, parentMessageId)

        chatApi.createMessage({
            chatId: chatId,
            message: JSON.stringify(message),
            parentMessageId: parentMessageId,
        }, {
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                // setProgress(percentCompleted)
            },
        }).then(async (res) => {
            setMessage('')
            setAnswerTo(null)
            setAnswerAuthor(null)

            if (res.status === 200) {
                if (file && typeof file === 'object') {
                    const formData = new FormData();
                    formData.append('file', file);

                    await chatApi.addMessageFile(res?.data.id, formData, {
                        onUploadProgress: function (progressEvent) {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setProgress(percentCompleted)
                        },
                        cancelToken: source.token
                    }).then((res) => {
                        setIsVisiblePopup(false)
                        setAction(false)
                        setFile(null)
                    })
                }
            }
        }).finally(() => {
            chatApi
              .getChat(chat)
              .then((res) => setData(res?.data))

            setTimeout(() => {
                setSaving(false);
                setProgress(0);
            }, 1000)
        })
    }

    const appendMessage = (message, parentMessageId) => {
        setData({
            ...data,
            messages: [
                ...data.messages,
                {
                    id: 'new_' + (new Date()).getTime(),
                    author: data?.members?.find(member => member.user_id === profile.id)?.member_id,
                    message: `\"${message}\"`,
                    parentId: parentMessageId,
                    createdAt: (new Date()).getTime() / 1000,
                    files: []
                }
            ]
        })

        setTimeout(scrollToBottom, 50)
    }

    const clearAnswer = () => {
        setAnswerTo(null)
        setAnswerAuthor(null)
    }

    const handleCloseFilePopup = () => {
        if (saving) {
            source.cancel('Operation canceled by the user.');
        }

        setSaving(false)
        setIsVisiblePopup(false)
    }

    const onKeyDownHandler = (e) => {
        if (e.key === "Enter") {
            if(message){
                addMes(data.id,message, answerTo?.id);
            }
            
        }
    }

    const sendMessage = (e) => {
        e.preventDefault();
        if(message){
            addMes(data.id,message, answerTo?.id);
        }
       
    }

    const [bindFile] = useInputNew({
        name: "file",
        value: file,
        onChange: (e) => setFile(e),
        placeholder: "",
    });

    const scrollToClip = () => {
        fixedRef.current.scrollIntoView({behavior: 'smooth'})
        fixedRef.current.style.background = '#EDE0FF'
        setTimeout( () => fixedRef.current.style.background = '#fff', 1000)
    }

    useEffect(() => {
        if (action) {
            addMes(data.id, message, answerTo?.id, file);
        }
    }, [action])

    const addFile = () => {
        setIsVisiblePopup(true)
    }
    const isCreator = data?.members?.filter(member => member.isCreator === 1)
    const creator = isCreator?.length === 1 ? isCreator[0].name : 'Вы'
    const fixedId = data?.fixedMessage?.id;
    const fixedMessage = fixedId ? data.messages.find((mess) => mess.id === fixedId) : null;

    const HEIGHT_DIFFER = 4;
    const SCROLL_HEIGHT = height/* - 200*/;

    const scrollToBottom = () => {
        messageEndRef?.current?.scrollIntoView({behavior: 'smooth'})
    }

    useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 1000)

    }, [])

    const getFromBottom = () => {
        if (answerTo) return  '136px'
        else  return '64px'
    }

    const onDeleteMessage = (message) => {
        setData({
            ...data,
            messages: data.messages?.filter(msg => msg.id !== message.id)
        })
    }

    const handleCollapseBtnClick = () => setPage('default')

    return (
        <div className={s.page} style={{height: `${height - HEIGHT_DIFFER}px`}}>
            <div className={s.header}>
                <button type={'button'} className={s.btn} onClick={() => setPage('main')}><ArrowLeftIcon/></button>
                {data?.members?.length > 2 ? <UserImage path={data?.avatar}/> : ''}
                <div className={s.title}>
                    {titleChat && titleChat}
                    {!titleChat && 'Чат'}
                    <div className={s.participants}>
                        {data?.members?.length > 2 ? `${data?.members?.length} ${getRightWord(data?.members?.length, ['участник', 'участникa', 'участников'])}` : ''}</div>
                </div>
                {data?.members?.length !== 2 &&
                    <div className={s.dots} onClick={() => setPage('info', chat)}><DotsIcon/></div>
                }
                <CollapseBtn onClick={handleCollapseBtnClick} />
            </div>
            {fixedMessage &&
                <FixedMessage
                    message={fixedMessage}
                    data={data}
                    customClass={s.clipMessage}
                    setIsNeedUpdate={setIsNeedUpdate}
                    scrollToClip={scrollToClip}
                />}
            <div className={classNames('new-design-scroll', s.messages)} style={{height: `${SCROLL_HEIGHT}px`}}>
                <div className={s.defaultMessage}>
                    {creator} создали {data?.name?.length > 0 ? data?.name : 'чат'}
                </div>
                {data?.messages && data?.messages.map((chat, ind) => (
                    <ChatMessage
                        message={chat}
                        key={chat.id}
                        data={data}
                        setAnswer={setAnswerTo}
                        setAnswerAuthor={setAnswerAuthor}
                        activeUsers={activeUsers}
                        setIsNeedUpdate={setIsNeedUpdate}
                        fixed={data?.fixedMessage?.id}
                        fixedRef={fixedRef}
                        onDeleteMessage={onDeleteMessage}
                    />
                ))}
                <div className={s.messageEnd} ref={messageEndRef}></div>
            </div>
            <div className={s.footer}>
                {answerTo && answerAuthor && <div className={s.answerTo}>
                    <div className={s.answerToWrapper}>
                        <div className={s.answerName}>{answerAuthor.name}</div>
                        {JSON.parse(answerTo.message)}
                        <div className={s.close} onClick={clearAnswer}>
                            <CloseIcon width={'24'} height={'24'}/>
                        </div>
                    </div>
                </div>}
                <div className={s.footerForm}>
                    <form className={s.form} onSubmit={addMes} onKeyDown={onKeyDownHandler}>
                        <button type={'button'} className={s.clipBtn} onClick={addFile}>
                            <PaperClipIcon witdh={'32'} height={'32'}/>
                        </button>
                        {isActive && <div className={classNames(s.menu, isActive ? s.active : '')}>
                            <div className={s.menuRow}>
                                <div className={s.menuRowItem}>
                                    <InputNew.InputFileNewDesign
                                        className={s.inputFile}
                                        {...bindFile}
                                        newDesign
                                        isFileIcon
                                        placeholder='Файл'
                                        icon='file'
                                    />
                                </div>
                            </div>
                        </div>}
                        <InputEmoji
                            value={message}
                            onChange={setMessage}
                            placeholder={'Напишите сообщение'}
                            cleanOnEnter
                            fontSize={14}
                            borderColor={'transparent'}
                            language={'ru'}
                        />
                        
                        <button className={`${!message ? s.noActiveSubmit : null} ${s.submit}`} onClick={sendMessage} type={'submit'}><img style={{width: '25px'}} src={sendIcon} alt="Отправить"/></button>
                        
                    </form>
                </div>
            </div>
            {isVisiblePopup && <Popup
                isVisiblePopup={isVisiblePopup}
                closeCallback={handleCloseFilePopup}
                header={'Выберите фото или файл'}
                updateFile={true}
                setAction={setAction}
                setFile={setFile}
                file={file}
                uploading={saving}
                progress={progress}
            />}
            {data?.messages?.length > 6 &&
                <button type={'button'} className={s.btnScroll} onClick={scrollToBottom} style={{bottom: `${getFromBottom()}`}}><ArrowLeftIcon/></button>}
        </div>
    )
}
export default InChatView;
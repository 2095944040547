import bg1 from '../images/dev/bg1.png';
import bg2 from '../images/dev/bg2.png';
import bg3 from '../images/dev/bg3.png';
import bg4 from '../images/dev/bg4.png';
import bg5 from '../images/dev/bg5.png';

const SET_BANNERS= 'impulse/banners/SET_BANNERS';
const ADD_BANNER = 'impulse/banners/ADD_BANNER';
const UPDATE_BANNER = 'impulse/banners/UPDATE_BANNER';
const DEL_BANNER = 'impulse/banners/DEL_BANNER';

const initialState = {
	pages: {
		course: {
			name: 'Все курсы (основные)',
			list: [],
		},

		main: {
			name: 'Главная страница',
			list: [],
		},
	},

	admin: {
		group: [
			{
				label: 'Все курсы (основные)',
				value: 'course',
			},
			{
				label: 'Главная страница',
				value: 'main',
			},
		],
		role: [
			{
				label: 'Все',
				value: 'all',
			},
			{
				label: 'Ученик',
				value: 'children',
			},
			{
				label: 'Ученик (Не учится)',
				value: 'unpaid',
			},
			{
				label: 'Учитель',
				value: 'seminarian',
			},
			{
				label: 'Ст.Педагог',
				value: 'senior-seminarian',
			},
			{
				label: 'Персональный менеджер',
				value: 'personal-manager',
			},
		],
		type: [
			{
				label: 'Все',
				value: 'all',
			},
			{
				label: 'Школа',
				value: 'school',
			},
			{
				label: 'Универ',
				value: 'university',
			},
		],
	},
};

const bannerReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_BANNERS:
			const groups = Object.keys(state.pages)
			const banners = action.setting
			const newState = state

			groups.map(group => {
				newState.pages[group].list = banners.filter(banner => banner.group === group)
			})

			return {
				...state,
				...newState

			}
		case ADD_BANNER:
			return {
				...state,
				pages: {
					...state.pages,
					[action.banner.group]: {
						...state.pages[action.banner.group],
						list: [
							...state.pages[action.banner.group].list,
							action.banner
						],
					},
				},
			};
		case UPDATE_BANNER:
			return {
				...state,
				pages: {
					...state.pages,
					[action.banner.group]: {
						...state.pages[action.banner.group],
						list: [
							...state.pages[action.banner.group].list.map(banner => {
								if (banner.id === action.banner.id) {
									banner = action.banner
								}

								return banner
							}),
						],
					},
				},
			};
		case DEL_BANNER:
			return {
				...state,
				pages: {
					...state.pages,
					[action.banner.group]: {
						...state.pages[action.banner.group],
						list: [
							...state.pages[action.banner.group].list.filter(
								(el) => el.id !== action.banner.id
							),
						],
					},
				},
			};
		default:
			return state;
	}
};

export const setBannerList = (setting) => ({ type: SET_BANNERS, setting });
export const addBanner = (banner) => ({ type: ADD_BANNER, banner });
export const updateBanner = (banner) => ({ type: UPDATE_BANNER, banner });
export const removeBanner = (banner) => ({ type: DEL_BANNER, banner });

export default bannerReducer;

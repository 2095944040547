import { useEffect, useState } from 'react';

export const useInput = (initialVal = '', refresh = false) => {
	const [val, setVal] = useState(initialVal);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (refresh) setVal(initialVal);
	}, [initialVal]);
	const reset = () => setVal(initialVal);
	const bind = {
		value: val,
		onChange: (e) => setVal(e.target.value),
	};

	return [bind, val, reset];
};

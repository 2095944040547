import React from "react"
import { formateDate } from "../../../helpers/date"
import { GetIco } from "../../../utils/icons"
import Calendar from "../CalendarN"
import s from "./SelectDays.module.css"
import cn from 'classnames'
import { useOutside, useKeyUp } from "../../../hooks"
import { IMaskInput } from "react-imask";
import { useEffect } from "react"


const SelectDays = ({ error, className = '', selectedPeriod = false, valueDefault, selectDate = d => { }, placeholder = 'дд.мм.гггг', disabled, autoFocus = true, min, max, newDesign=false }) => {
  const [ref, show, setShow] = useOutside(false)
  const [selectedDate, setSelectedDate] = React.useState(valueDefault ? new Date(valueDefault) : '');

  useEffect(() => {
    if (!valueDefault?.length) {
      setSelectedDate('')
    }
  }, [valueDefault])

  useEffect(() => { if (selectedDate) selectDate(formateDate(selectedDate, 'YYYY-MM-DD')) }, [selectedDate])

  const onChange = value => { const data = value.split('.'); if (data[2]?.length === 4 && data[1]?.length === 2 && data[0]?.length === 2) setSelectedDate(new Date(`${data[2]}-${data[1]}-${data[0]}`)) }
  let inputParams = { value: selectedDate ? formateDate(selectedDate) : '', mask: Date, onAccept: onChange, placeholder, autoFocus, disabled }
  const select = date => { setSelectedDate(date); setShow(false) }
  const open = event => { event.stopPropagation(); setShow(!show) }

  if (min) inputParams.min = min
  const openCalendarParams = { tabIndex: 0, onClick: open, ...useKeyUp(open) }

  return <div ref={ref} className={cn(s.wrapper, { [className]: className }, newDesign ? s.widthMaxNewDesign : '')}>
    <div className={cn(s.select, 'btn', { [s.error]: error }, { 'btn--disableNo': disabled }, newDesign ? 'backgroundNone' : '', newDesign ? s.widthMaxNewDesign : '')}>
      <IMaskInput {...inputParams} className={cn(s.input)} />
      {!disabled && <GetIco className={cn(s.ico, 'focus')} icon='calendar' {...openCalendarParams} />}
    </div>
    <div className={cn(s.calendarContainer, { [s.show]: show }, { [s.periodWrapper]: selectedPeriod })}><Calendar {...{ min, max }} selectedDate={(typeof selectedDate === 'object' && selectedDate?.getDate() > 0) ? selectedDate : new Date()} selectDate={select} /></div>
  </div >

}

export default SelectDays

import s from './CustomRadioElem/CustomRadioElem.module.css';
import './and-design.css';
import {Select} from "antd";
import React, {useState} from "react";
import {DeleteChatIcon} from "@/utils/icons";
import classNames from "classnames";
import UserImage from "@common/Chat/components/UserImage/UserImage";
import {chatApi} from "@/api";
import {useAddAlert} from "@/hooks/useAddAlert";
import {useSelector} from "react-redux";
import {selectProfile} from "@store/profile.reducer";

const MemberElem = ({user, myRoleInChat, setIsVisiblePopup, setHeader,setDelId, setType, setIsNeedUpdated}) => {
    const addAlert = useAddAlert();
    const profile = useSelector(selectProfile)
    const options = [
        {
            label: 'Администратор',
            value: 1
        },
        {
            label: 'Участник',
            value: 0
        }]
    const myRole = options.find(el => el.value === user.role)
    const [role, setRole] = useState(myRole);
    const handleChange = (value) => {
        setRole(value)
        chatApi.updateRoleMember(user.member_id, {
            role: value
        }).then((res) => setIsNeedUpdated(true)).catch(err => addAlert({text: err}))
    }

    const handleDelete = () => {
        setHeader('Вы действительно хотите удалить участника?')
        setIsVisiblePopup(true)
        setDelId(user.member_id)
        setType('removeMember')
    }

    return (
        <div className={classNames(s.row, 'custom-radio-element')}>
            <div className={s.rowHead}>
                <UserImage path={user.avatar}/>
                <div className={s.rowName}>{user.name}</div>
            </div>
            <div className={s.controls}>
                <Select
                    disabled={myRoleInChat !== 1 || user?.isCreator === 1}
                    onChange={(value) => handleChange(value)}
                    options={options}
                    value={role}
                    popupClassName={'custom-dropdown'} size={'middle'}
                >
                    <Select.Option value="1" style={{fontSize: '12px'}}>Администратор</Select.Option>
                    <Select.Option value="0">Участник</Select.Option>
                </Select>
                <div className={s.btn} onClick={() => handleDelete()}>
                    <DeleteChatIcon width={'24'} height={'24'}/>
                </div>
            </div>

        </div>
    )
}

export default MemberElem;
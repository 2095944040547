import cn from 'classnames'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import JsonPars from '../../../UI/JsonPars/JsonPars'
import s from './Match.module.css'


// логика
export const DragItem = ({ id, index, moveCard, ...props }) => {
  const ref = React.useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'item',
    collect(monitor) { return { handlerId: monitor.getHandlerId() } },
    hover(item, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ opacity }, drag] = useDrag({
    type: 'item',
    item: () => ({ id, index }),
    collect: (monitor) => ({ opacity: monitor.isDragging() ? 0.2 : 1 })
  })

  drag(drop(ref))

  return <Item dRef={ref} {...{ opacity, id, handlerId, index, ...props }} />
}



// сама карточка
const Item = ({ dRef, opacity, handlerId, ...props }) => {

  // const status = props?.answerData?.status
  // state = props?.answerData?.state[props.index],
  // allowed = props?.answerData?.allowed,
  // isImg = props?.answerData?.isImg,
  // isShow = status !== 'no' && status !== 'accent'

  const styles = cn(s.booble,
    // { [s.boobleImg]: isImg },
    // { [s.red]: (!state && isShow) },
    // { [s.accent]: (state && isShow) },
    { [s.bg]: props?.select?.values?.includes(props.index) }
  )

  const settingsItem = {
    ref: dRef,
    style: { opacity },
    className: styles,
    'data-handler-id': handlerId,
    onClick: () => props?.select?.onSelect(props.index),
  }

  if (props.status > 4)
    return <div className={styles} draggable={false}>
      {props.img
        ? <a href={props.img} target='_blank' rel="noreferrer"><img src={props.img} alt='' /></a>
        : <JsonPars json={props.text} />
      }
    </div>

  return <div {...settingsItem}>
    {process.env.REACT_APP_DEV_MODE === '1' && <span className="error">id=({props.id}) </span>}

    {props?.img
      ? <a href={props.img} target='_blank' rel="noreferrer"><img src={props.img} alt='' /></a>
      : <JsonPars json={props.text} />
    }

  </div >
}

import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { courseAPI, courseMaterialApi, recordingLessonAPI } from '../../../../../api'
import { useAddAlert } from '../../../../../hooks/useAddAlert'
import { useLoad } from '../../../../../hooks/useLoad'
import { selectorMyDirectionsSelect, selectorMyGroups } from '@store/common/common.reducer'
import Button from '../../../../UI/Button'
import { InputDate, InputNew, InputSelect } from '../../../../UI/InputNew'
import { useInputNew } from '../../../../UI/InputNew/useInputNew'
import Preloader from '../../../../UI/Preloader'
import s from './index.module.css'


const UploadVideo = ({ btnAction }) => {
  const [errorsList, setErrorsList] = useState([])
  const [showErrors, setShowErrors] = useState(false)
  const [state, setState] = useState({ direction_id: '', course_id: '', topic_id: '', group_id: '', lesson_date: '', link_to_video: '' })

  const [bindDirections] = useInputNew({
    name: 'direction_id',
    placeholder: 'Направление',
    options: useSelector(selectorMyDirectionsSelect),
    value: state.direction_id,
    onChange: e => setState(prev => ({ ...prev, direction_id: e })),
    onError: setErrorsList,
    validate: showErrors,
  })

  const { state: courses, getState: getCourses } = useLoad({
    api: courseAPI.getAll,
    params: { filter: [`[direction_id]=${state.direction_id}`] },
    initState: [],
    key: 'data',
    last: false,
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { state.direction_id && getCourses() }, [state.direction_id])

  const [bindCourse] = useInputNew({
    name: 'course_id',
    placeholder: 'Курс',
    options: courses?.map(({ name: label, id: value }) => ({ label, value })) ?? [],
    errorOptions: 'Не выбрано направление или нет доступных курсов',
    value: state.course_id,
    onChange: e => setState(prev => ({ ...prev, course_id: e })),
    onError: setErrorsList,
    validate: showErrors,
  })

  const { state: materials, getState: getMaterials } = useLoad({
    api: courseMaterialApi.getAll,
    params: { filter: [`[course_id]=${state.course_id}`] },
    initState: [],
    key: 'data',
    last: false,
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { state.course_id && getMaterials() }, [state.course_id])

  const [bindTopic] = useInputNew({
    name: 'topic_id',
    placeholder: 'Тема',
    options: materials?.filter(({ type }) => type === 'topic')?.map(({ name: label, id: value }) => ({ label, value })) ?? [],
    value: state.topic_id,
    onChange: e => setState(prev => ({ ...prev, topic_id: e })),
    onError: setErrorsList,
    validate: showErrors,
  })

  const [bindGroups] = useInputNew({
    name: 'group_id',
    placeholder: 'Группа',
    options: useSelector(selectorMyGroups)?.filter(group => {
      if (state.direction_id) return group.direction_id === state.direction_id ? true : false
      return true
    })?.map(({ name: label, id: value }) => ({ label, value })) ?? [],
    value: state.group_id,
    onChange: e => setState(prev => ({ ...prev, group_id: e })),
    onError: setErrorsList,
    validate: showErrors,
  })

  const [bindDate] = useInputNew({
    name: 'lesson_date',
    placeholder: 'дд.мм.гггг',
    value: state.lesson_date,
    onChange: e => setState(prev => ({ ...prev, lesson_date: e })),
    onError: setErrorsList,
    validate: showErrors,
  })

  const [bindUrl] = useInputNew({
    name: 'link_to_video',
    placeholder: 'Вставьте ссылку на видео',
    value: state.link_to_video,
    onChange: e => setState(prev => ({ ...prev, link_to_video: e })),
    onError: setErrorsList,
    validate: showErrors,
  })

  const [loading, setLoading] = useState(false)
  const addAlert = useAddAlert()

  const save = () => {
    setShowErrors(true)
    if (errorsList.valid) {
      setLoading(true)
      recordingLessonAPI.add({ data: state }).then(res => {
        setLoading(false)
        if (res.data.status === "success") {
          addAlert({ title: 'Запись успешно добавлена!' })
          btnAction()
        }
        else addAlert({ title: 'Упс! Ошибка', text: 'Попробуйте ещё раз или обратитесь к администратору', type: 'error' })
      })
    }
  }


  return <>
    <h2 className='modal__title'>Загрузить видео с семинара</h2>

    <div className={s.list}>
      <InputSelect className={s.input} {...bindDirections} />
      <InputSelect className={s.input}{...bindCourse} />
      <InputSelect className={s.input}{...bindTopic} />
      <InputSelect className={s.input} {...bindGroups} />
      <InputDate className={s.input} {...bindDate} />
      <InputNew className={s.input} {...bindUrl} />
    </div>

    {loading ? <Preloader /> : <Button styles='accent' action={save}>Сохранить изменения</Button>}
  </>
}


export default UploadVideo

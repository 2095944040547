import { levelImpulse } from '@constant';

export const getMyLevel = (pulses = 0) => {
	const level =
		levelImpulse.find(
			({ min, max }) => pulses >= min && (max ? pulses < max : true)
		) ?? {};

	return {
		pulses,
		lvl: level.lvl,
		name: level.name,
		percent: level.max
			? Math.floor(((pulses - level.min) * 100) / (level.max - level.min))
			: 100,
		next: level.max ? level.max - pulses : 0,
	};
};

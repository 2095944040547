import { InputTextarea } from "@UI/InputNew/Input"
import Button from "@UI/Button"
import { useAddAlert } from "@hooks/useAddAlert"
import { supportAPI } from "@api"
import {InputFile, InputSelect} from "../../UI/InputNew/Input"
import {useEffect, useState} from "react"
import { useInputNew } from "../../UI/InputNew/useInputNew"
import { getInArr } from "../../../helpers/getInArr"
import {directionAPI, examNumberAPI} from "../../../api";
import {useGetApi} from "../../../hooks";

const Supports = ({ title, description, placeholder, support_type, options = {}, close }) => {
  const addAlert = useAddAlert()
  const [direction, setDirection] = useState(null)
  const [files, setFiles] = useState([])
  const [bindFile] = useInputNew({
    value: files,
    onChange: setFiles,
    multiple: true,
  })
  const onSubmit = (e) => {
    e.preventDefault()
    const data = new FormData(e.target)
    data.append('support_type', support_type)

    if (direction) {
      data.append('direction_id', direction || null)
    }

    files.forEach(file => { data.append('files[]', file) })
    for (const key in options) { data.append(key, options[key]) }

    supportAPI.add({ data }).then(res => {
      if (res.data.status === 'success') {
        addAlert({ title: 'Ваш запрос отправлен' })
        close()
      }
    })
  }

  const [bindDirections] = useInputNew({
    name: 'direction_id',
    value: direction,
    onChange: setDirection,
    placeholder: 'Направление',
    style: { maxWidth: 450, width: '100%', marginBottom: 20 },
    options: useGetApi(
        () => directionAPI.getAll(),
        {},
        res => res?.data?.data?.map(item => ({ label: item.name, value: item.id }))
    )
  })

  return <>
    <h2 className="modal__title">{title}</h2>

    <p className="modal__description" style={{ maxWidth: 450 }}>{description}</p>

    <form {...{ onSubmit }}>
      <InputTextarea name='message' placeholder={placeholder} style={{ maxWidth: 450, width: '100%', marginBottom: 20 }} />
      {support_type === 'task' &&  <InputSelect  { ...bindDirections } />}
      {!getInArr(support_type, ['contact-parent', 'change-group']) && <InputFile {...bindFile} style={{ maxWidth: 450, width: '100%', marginBottom: 20 }} />}
      <div>
        <Button type='submit' name="Отправить" styles="yellow" />
      </div>
    </form>
  </>
}

export default Supports

import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import {fcmApi} from "@/api";
import {useSelector} from "react-redux";
import {selectIsAuth} from "@store/profile.reducer";

export const useFcmMessaging = () => {
	const firebaseConfig = {
		apiKey: process.env.REACT_APP_FCM_API_KEY,
		authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_FCM_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FCM_CLIENT_ID,
		appId: process.env.REACT_APP_FCM_APP_ID,
	};

	const isAuth = useSelector(selectIsAuth)

	if (!isAuth || !Number(process.env.REACT_APP_FCM_ENABLED)) {
		return false
	}

	const fcm = initializeApp(firebaseConfig)
	const messaging = getMessaging(fcm)

	if ('Notification' in window) {
		Notification
			.requestPermission()
			.then(permission => {
				if (permission === 'granted') {
					subscribe()
				}
			})
	}

	const subscribe = () => {
		const storageKey = 'sentFirebaseMessagingToken';
		const storedToken = window.localStorage.getItem(storageKey)

		getToken(messaging, {
			vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
		}).then(token => {
			if (storedToken !== token) {
				fcmApi.storeToken(token)
				window.localStorage.setItem(storageKey, token || '')
			}
		}).catch(e => console.error(e))
	}
}

export const unsubscribeFcmMessaging = () => {
	const storageKey = 'sentFirebaseMessagingToken';
	const storedToken = window.localStorage.getItem(storageKey)

	if (storedToken) {
		window.localStorage.removeItem(storageKey)
		fcmApi.removeToken(storedToken)
	}
}

import s from './ChatInfo.module.css';
import '../styles.css';
import {ArrowLeftIcon, CloseIcon, EditIcon, PlusIcon} from "@/utils/icons";
import React, {useEffect, useState} from "react";
import {chatApi} from "@/api";
import MemberElem from "@common/Chat/components/MemberElem";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectProfile} from "@store/profile.reducer";
import {Input} from "antd";
import Popup from "@common/Chat/components/Popup/Popup";
import UserImage from "@common/Chat/components/UserImage/UserImage";
import {useAddAlert} from "@/hooks/useAddAlert";
const ChatInfo = ({chat, setPage}) => {
    const profile = useSelector(selectProfile);
    const [data, setData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [titleChat, setTitleChat] = useState(null);
    const [action, setAction] = useState(false)
    const meInChat =data?.members.find(el => el.name === profile.name);
    const filteredMembers = isAdmin ? data?.members.filter((member) => member.role === 1) : data?.members;
    const [isNeedUpdate, setIsNeedUpdated] = useState(false);
    const [isVisiblePopup, setIsVisiblePopup] = useState(false);
    const [header, setHeader] = useState('')
    const [delId, setDelId] = useState(null);
    const [type, setType] = useState('')
    const [isUpdateImage, setIsUpdateImage] = useState(false)
    const [image, setImage] = useState(null)
    const addAlert = useAddAlert()
    const [uploading, setUploading] = useState(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        chatApi.getChat(chat).then((res) => {
            setData(res?.data)
            setTitleChat(res?.data?.name)
            setIsNeedUpdated(false)
        })
    }, [chat, isNeedUpdate]);


    useEffect(() => {
        if (action) {
            if (type ==='removeMember') {
                deleteMember()
            } else if (type === 'changeImage') {
                saveLogo()
            }

        }
    }, [action])
    const deleteMember = () => {
        chatApi.deleteMember(delId)
            .then((res) => {
                if (res?.status === 200) {
                    setIsNeedUpdated(true)
                    setIsVisiblePopup(false)
                    setType('')
                    setAction(false)
                } else {
                    addAlert({text: 'Обратитесь к администратору чата'})
                    setIsNeedUpdated(true)
                    setIsVisiblePopup(false)
                    setType('')
                    setAction(false)
                }
            })
    }

    const updateChatTitle = () => {
        chatApi.updateChatTitle(data.id, {
            chatName: titleChat
        })
    }

    const addNewParticipants = () => {
        setPage('create', data, 'add')
    }

    const exit = () => {
        setType('removeMember')
        setDelId(meInChat.member_id)
        setHeader('Вы действительно хотите покинуть чат?')
        setIsVisiblePopup(true)
    }

    useEffect(() => {
        setTimeout(updateChatTitle,5000)
    }, [titleChat])

    const saveLogo = () => {
        if (image === null) {
            addAlert({title: 'Не выбран файл логотипа'});
            return;
        }

        const data = new FormData();
        if (typeof image === 'object') {
            data.append('file', image);
        }
        setUploading(true)
        chatApi.updateChatImage(chat, data, {
            onUploadProgress: function (progressEvent) {
               
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted)
            }
        }).then((res) => {
            if (res?.status === 200) {
                setIsUpdateImage(false)
                setImage(null)
                setIsNeedUpdated(true)
                setIsVisiblePopup(false)
                setType('')
                addAlert({text: 'Изображение успешно загружено'})
                setAction(false)
                setUploading(false)
                setProgress(0)
            } else {
                addAlert({text: 'Изображение не загружено, обратитесь к администратору'})
            }
        })
    }

    const handleEditImage = () => {
        setHeader('Замена изображения чата')
        setIsVisiblePopup(true)
        setIsUpdateImage(true)
        setType('changeImage')
    }

    return (
        <div className={classNames(s.page, 'chat-info')}>
            <div className={s.header}>
                <div className={s.headerTitle}>
                    <button type={'button'} className={s.btn} onClick={() => setPage('inChat', chat)}><ArrowLeftIcon/></button>
                    <div className={s.title}>
                        Информация
                    </div>
                </div>
                <div className={s.close} onClick={() => setPage('main')}>
                    <CloseIcon width={'24'} height={'24'}/>
                </div>
            </div>
            <div className={s.info}>
                <UserImage path={data?.avatar} customClass={s.big}/>
                <div className={s.edit} onClick={handleEditImage} hidden={meInChat?.role !== 1}>
                    <EditIcon/>
                </div>
                <div>
                    <div className={s.title}>
                        <Input
                            disabled={meInChat?.role !== 1}
                            className={s.input}
                            value={titleChat}
                            placeholder={'Введите название чата'}
                            onChange={e => setTitleChat(e.target.value)}
                        />
                    </div>
                    <div className={s.numbers}>{data?.members?.length} участника</div>
                </div>

            </div>
            <div className={s.tabs}>
                <div className={classNames(s.tab, !isAdmin ? s.active : '')} onClick={() => setIsAdmin(false)}>Все
                    участники
                </div>
                <div className={classNames(s.tab, isAdmin ? s.active : '')} onClick={() => setIsAdmin(true)}>Администраторы</div>
            </div>
            {meInChat && meInChat.role === 1 && <div className={s.addBlock}>
                <div className={s.addElem} onClick={addNewParticipants}>
                    <div className={s.plus}><PlusIcon/></div>
                    <div className={s.label}> Добавить участников</div>
                </div>
            </div>}
            <div className={'new-design-scroll'}>
                {filteredMembers && filteredMembers.map((member) => (
                    <MemberElem
                        user={member}
                        key={member.member_id}
                        myRoleInChat={meInChat?.role}
                        setAction={setAction}
                        action={action}
                        setIsNeedUpdated={setIsNeedUpdated}
                        setHeader={setHeader}
                        setIsVisiblePopup={setIsVisiblePopup}
                        setDelId={setDelId}
                        setType={setType}
                    />
                ))}
            </div>
            {meInChat && <div className={s.exit} onClick={exit}>
                Покинуть чат
            </div>}
            {/* {isVisiblePopup && <Popup
                isVisiblePopup={isVisiblePopup}
                closeCallback={() =>  setIsVisiblePopup(false)}
                header={header}
                updateImage={isUpdateImage}
                setAction={setAction}
                setImage={setImage}
                image={image}
                progress={progress}
                uploading={uploading}
            />} */}
            {isVisiblePopup && <Popup 
                isVisiblePopup={isVisiblePopup} 
                closeCallback={() =>  setIsVisiblePopup(false)} 
                header={header} 
                updateFile={isUpdateImage} 
                setAction={setAction} 
                setFile={setImage} 
                file={image} 
                progress={progress}
                uploading={uploading}
            />}
        </div>
    )
}

export default ChatInfo;
import {React} from "react"
import Modal from "@common/Modal"
import {connect, useDispatch} from "react-redux";
import {hide} from "@store/confirm-reducer";
import Row from "@UI/Row"
import s from './index.module.css'
import Button from "@UI/Button";

const Confirm = ({ data }) => {
  const dispatch = useDispatch()

  const onConfirm = () => {
    data.onConfirm()
    dispatch(hide())
  }

  const onCancel = () => {
    data.onCancel()
    dispatch(hide())
  }

  return (
      <Modal className={s.confirm} active={data.show} setActive={onCancel}>
        <h2 className={s.title}>{data.title}</h2>
        <p>{data.text}</p>

        <Row className={s.buttonRow}>
          <Button styles={'yellow'}
                  onClick={onConfirm}
                  loading={data.loading}
                  disable={data.loading}
          >
            Да
          </Button>
          <Button onClick={onCancel}>Отмена</Button>
        </Row>
      </Modal>
  )
}

export default connect((state) => ({
  data: state.confirm
}), {})(Confirm)

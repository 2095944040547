import classNames from 'classnames'
import { GetIco } from '../../../../utils/icons'
import Button, { ButtonDel } from '../../../UI/Button'
import Textarea from '../../../UI/Textarea'
import s from './CheckMenu.module.css'
import { useState } from 'react'
import JsonPars from '../../../UI/JsonPars/JsonPars'
import { useInputNew } from '../../../UI/InputNew/useInputNew'
import { InputSelect } from '../../../UI/InputNew'
import { useEffect } from 'react'
import Preloader from '../../../UI/Preloader'
import { getInArr } from '../../../../helpers/getInArr'


export const CheckMenu = props => {

  const [bindSelects] = useInputNew({
    name: 'score',
    value: props?.teacherAnswer?.get?.score,
    onChange: e => props.teacherAnswer.set('score', e),
    placeholder: 'Бал-ов',
    options: props?.exam_weight?.map(item => ({ label: item?.score, value: item?.score })),
  })

  const checkAttempt = () => {

    switch (props?.result?.attempt) {
      case undefined: return 0
      case 0: return 3
      case 1: return 2
      case 2: return 1
      default: return props?.result?.attempt * (-1) +3
    }
  }


  return <>
    {props.type !== 'control' &&
      <div className={s.estimation}>
        <span className={s.booble}>Использовано попыток</span>
        <span className={s.booble}> {checkAttempt()}</span>
      </div>
    }
    <div className={s.estimation}>
      <span className={s.booble}>{props.status === 10 ? 'Оценка' : 'Оцените'}</span>
      {props.status === 10
        ? <span className={s.booble}>{props?.result?.score}</span>
        : <InputSelect className={s.selectScore} {...bindSelects} />}
    </div>

    <Answers {...props} />

    <Container {...props} />
  </>
}

const Answers = props => {
  const [state, setState] = useState(false)
  if (!props?.key_text?.content) return <></>
  return <div className={s.answers}>
    <Button styles='blue-gray' action={() => setState(!state)}>{state ? 'Закрыть' : 'Открыть'} ключ</Button>

    <div className={classNames(s.answersWrap, { [s.show]: state })}>
      <div><JsonPars json={props.key_text} /></div>
      <div className={s.answersList}>
        {/* <a className={s.answersLink} href={file} target='_blank' rel="noreferrer"><img src={file} alt="" /></a>
        <a className={s.answersLink} href={file} target='_blank' rel="noreferrer"><img src={file} alt="" /></a>
        <a className={s.answersLink} href={file} target='_blank' rel="noreferrer"><img src={file} alt="" /></a> */}
      </div>
    </div>


  </div>
}

const Container = props => {
  const [fileList, setFileList] = useState((typeof props?.teacherAnswer?.getFiles === 'object' && props?.teacherAnswer?.getFiles?.length > 0) ? props?.teacherAnswer?.getFiles : [])
  const [loading, setLoading] = useState({
    add: false,
    files: []
  })

  const add = (event) => {
    setLoading({ ...loading, add: true })
    const files = []
    for (let i = 0; i < event.target.files?.length; i++)
      files.push(event.target.files[i])
    props.teacherAnswer.addCommentFile({ 'comment_files': files, 'work_id': props.homeWork_id, 'task_id': props.id }).then(res => {
      setLoading({ ...loading, add: false })
      if (res.data.data?.length > 0)
        setFileList(res.data.data)
    })
  }

  const del = element => {
    setLoading({ ...loading, files: [...loading.files, element.index] })

    props.teacherAnswer.removeCommentFile({ 'work_id': props.homeWork_id, 'task_id': props.id, index: element.index }).then(res => {
      setLoading({ ...loading, files: loading.files.filter(item => item.index !== element.index) })

      if (res.data.status === 'success') {
        const newList = fileList.filter(el => el !== element)
        setFileList(newList.map((item, index) => ({ ...item, index })))
      }
    })

  }

  const [comment, setComment] = useState(props.teacherAnswer?.get?.comment)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { props.teacherAnswer.set('comment', comment) }, [comment])

  return <>
    <div className={s.textareaWrap}>
      <Textarea className={s.textarea} value={comment} onChange={e => setComment(e.target.value)} placeholder='Комментарий' disabled={props.status === 10} />
      {props.status !== 10 && <label className={s.uploadImg}>
        <input type="file" className='hide' onInput={add} multiple={true} />
        {loading.add ? <Preloader /> : <span className={s.btnImg}><GetIco icon='picture' /></span>}
      </label>
      }

    </div>

    <div className={s.file}>
      {fileList?.map((el, index) => <span key={index}>
        <a href={process.env.REACT_APP_PATH_FILE + el.path} className='booble' target='_blank' rel="noreferrer">Вложение №{index + 1}</a>
        {props.status === 5 && (getInArr(el.index, loading.files) ? <Preloader /> : <ButtonDel onClick={() => del(el)} />)}
      </span>)}
    </div>
  </>
}

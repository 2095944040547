import { useEffect, useState } from 'react';
import { jsonConvert } from '../helpers/jsonConvert';

export const useLocalStorage = (key, initialValue) => {
	// получаем данные из LS
	const dataLS = jsonConvert(localStorage.getItem('temporary'), 'json');
	// записываем либо новое значение либо из LS если есть
	const [value, setValue] = useState(() =>
		dataLS[key] !== undefined
			? dataLS[key]
			: typeof initialValue === 'function'
			? initialValue()
			: initialValue
	);
	// обновляем значение
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		localStorage.setItem('temporary', jsonConvert({ ...dataLS, [key]: value }));
	}, [value]);

	const removeValue = () => {
		const newTemporary = {};
		for (let itemKey in dataLS) {
			if (itemKey !== key) newTemporary[itemKey] = dataLS[itemKey];
		}
		localStorage.setItem('temporary', jsonConvert(newTemporary));
	};
	return [value, setValue, removeValue];
};

import {z} from "zod";

export const configSchema = z.object({
    URL_API: z.string().url(),
    PATH_FILE: z.string().url(),
    PROJECT_NAME: z.string(),
    EDITING_GROUPS_FOR_PERSONAL_MANAGER: z.boolean().default(false),
    RATE_PER_TEACHER_ON_CARD: z.boolean().default(false),
})

export type configSchemaType = z.infer<typeof configSchema>
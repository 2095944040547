export const IcoEdit = () => (
	<svg
		width="13"
		height="12"
		viewBox="0 0 13 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.12073 7.53384L4.75488 10.1855L9.99746 4.90206L7.36331 2.25036L2.12073 7.53384Z"
			fill="#2D3134"
		/>
		<path
			d="M12.0138 1.81057L10.4343 0.219727C10.2898 0.0741563 10.1008 0.000960938 9.91158 0H9.90371C9.71451 0.000960938 9.52568 0.0741563 9.38153 0.219727L8.32715 1.2803L10.9608 3.93157L12.0138 2.871C12.1583 2.72543 12.231 2.53523 12.2319 2.34469V2.33677C12.231 2.14631 12.1583 1.95612 12.0138 1.81057Z"
			fill="#2D3134"
		/>
		<path
			d="M0.318359 11.9997L3.74061 11.204L1.10693 8.55273L0.318359 11.9997Z"
			fill="#2D3134"
		/>
	</svg>
);

export const IcoRemove = (props) => (
	<svg
		width="14"
		height="15"
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M10.5826 4.3448L7.81022 7.35451L10.7552 10.0673L9.81617 11.0867L6.87118 8.37392L4.0988 11.3836L3.1441 10.5042L5.91649 7.49451L2.95533 4.76684L3.89436 3.74742L6.85553 6.47509L9.62791 3.46539L10.5826 4.3448Z"
			fill="#11142D"
		/>
	</svg>
);

import { childrenGroupApi } from '../../api';

const SET_STATE = 'impulse/myDirection/SET_STATE';
const EDIT_RATING = 'impulse/myDirection/EDIT_RATING';

const myGroupsReducer = (state = [], action) => {
	switch (action.type) {
		case SET_STATE:
			return action.state;
		case EDIT_RATING: {
			const group = state[0];
			return [
				{
					...group,
					lessonPlans: group.lessonPlans?.map((plan) =>
						plan.lesson.id === action.data.lesson_id
							? {
									...plan,
									lesson: {
										...plan.lesson,
										lessonVideos: plan.lesson?.lessonVideos?.map((videos) =>
											videos.id === action.data.lesson_video_id
												? {
														...videos,
														...action.data.rating,
												  }
												: videos
										),
									},
							  }
							: plan
					),
				},
			];
		}
		default:
			return state;
	}
};

export default myGroupsReducer;

const setState = (state) => ({ type: SET_STATE, state });
export const editRatingState = (data) => ({ type: EDIT_RATING, data });

export const getMyGroupsActions = (id, children) => (dispatch) => {
	const privateType = id.indexOf('-private') !== -1;
	if (!privateType) {
		childrenGroupApi
			.getGroups({ id, children })
			.then((res) => dispatch(setState([res.data[0]?.group])));
	} else {
		childrenGroupApi.getIndividual({ id: id.slice(0, -8) }).then((res) => {
			const get = () => {
				if (res.data?.length)
					return {
						...res.data[0],
						teacher: res.data[0]?.order?.teacher,
						direction: res.data[0]?.order?.direction,
						direction_id: res.data[0]?.order?.direction?.id,
					};
				return [];
			};
			dispatch(setState([get()]));
		});
	}
};

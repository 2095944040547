import React from 'react';
import { API } from '../api';
import { getHeaders } from '../api/getHeaders';

export const usePaginationBottom = ({ addList }) => {
	const [loading, setLoading] = React.useState(false);
	const [load, setLoad] = React.useState(false);
	const [info, setInfo] = React.useState({});

	// получаем res
	const pushRes = (res) => {
		setLoading(false);
		const pages = res?.data?._meta;
		const nextLink = res?.data?._links?.next?.href;
		setInfo({ pages, nextLink });
	};

	//проверяем дошли ли мы до конца страницы
	const checkBottom = () => {
		const bottom = document.body.scrollHeight;
		
		const height = window.innerHeight;
		const current = Math.round(window.scrollY);

		if (current + height + 40 >= bottom && !loading) setLoading(true);
	};

	//подписываемся на обновление
	React.useEffect(() => {
		document.addEventListener('scroll', checkBottom, true);
		return () => document.removeEventListener('scroll', checkBottom, true);
	});

	React.useEffect(() => {
		if (loading && !load && info?.pages?.currentPage < info?.pages?.pageCount) {
			setLoad(true);
			API.get(info.nextLink, getHeaders()).then((res) => {
				setLoad(false);
				pushRes(res);
				addList(res);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	return { pushRes };
};

import { usersAPI } from '../api';

const SET_USER = 'impulse/popup/SET_USER';

let initialState = {};

const profileUserReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER:
			return {
				...action?.data,
				userProfile: {
					...action?.data?.userProfile,
					notes: null,
					contracts: action?.data?.userProfile?.contracts
						? action.data.userProfile.contracts
						: [],
				},
			};
		default:
			return state;
	}
};

export const setProfileUser = (data) => ({ type: SET_USER, data });

export const getUserProfile = (id, expand) => (dispatch) =>
	usersAPI
		.getUser({ id, expand })
		.then((res) => dispatch(setProfileUser(res.data)));

export default profileUserReducer;

import React from 'react';
import update from 'immutability-helper';
import { selectors } from '@hooks/select';
import ProbeTasks from '../ThemeEditor/ProbeTasks/ProbeTasks';
import { nextId } from '../../../../helpers/nextId';

const ProbeTasksContainer = ({ state, setState }) => {
	const arrIdTasks = (list) =>
		list?.map((item) =>
			item?.task?.id !== undefined ? item?.task?.id : item?.id
		);

	const [show, setShow] = React.useState(false);

	const [file, setFile] = React.useState(state?.probe_path);
	React.useEffect(() => {
		if (typeof state?.probe_path === 'string') setFile(state?.probe_path);
	}, [state?.probe_path]);

	const [bindType, type] = selectors.useType();
	const onAdd = () => {
		setState({
			...state,
			tasks: [{ devID: nextId(state.tasks, 'devID'), type }, ...state.tasks],
		});
		setShow(false);
	};

	const saveLS = (data) => {
		const tasks = [
			...state.tasks?.filter((item) =>
				data.devID !== undefined ? item.devID !== data.devID : true
			),
		];
		if (data.devID !== undefined) {
			tasks.unshift(data.state);
			tasks.forEach((_, i) => {
				tasks[i].sorting = i;
			});
		} else {
			tasks.forEach((item, i) => {
				if (item.id === data.state.id) tasks[i] = { ...data.state, sorting: i };
			});
		}
		setState({ ...state, tasks });
	};

	const removeLS = (id) =>
		setState({
			...state,
			tasks: state.tasks.filter((item) => item.id !== id),
		});
	const cancelLS = (id) => {
		if (id)
			setState({
				...state,
				tasks: state.tasks.filter((item) => item.devID !== id),
			});
	};
	const moveLS = {
		_moveArr(val, type = 'up') {
			const arrayIn = arrIdTasks(state.tasks);
			let index = arrayIn.indexOf(val);
			arrayIn.splice(index, 1);
			if (type === 'up') arrayIn.splice(index - 1, 0, val);
			else arrayIn.splice(index + 1, 0, val);
			return arrayIn;
		},
		_convert(arr) {
			return arr.map((item, i) => ({
				...state?.tasks.find((i) => i.id === item),
				sorting: i,
			}));
		},
		up(id) {
			setState({
				...state,
				tasks: this._convert(this._moveArr(id, 'up')),
			});
		},
		down(id) {
			setState({
				...state,
				tasks: this._convert(this._moveArr(id, 'down')),
			});
		},
	};

	const addFile = (val) =>
		setState(update(state, { probe_path: { $set: val } }));

	const probeTask = React.useMemo(() => state?.tasks, [state?.tasks]);

	const directionID = state?.direction_id;

	const newProps = {
		show,
		setShow,
		file,
		addFile,
		bindType,
		onAdd,
		probeTask,
		saveLS,
		moveLS,
		removeLS,
		cancelLS,
		directionID,
	};

	return <ProbeTasks {...newProps} />;
};

export default ProbeTasksContainer;

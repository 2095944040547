import s from './index.module.css'
import CheckBox from '../../../../../UI/CheckBox'
import { useEffect } from 'react'


export const SequenceType = ({ data, edit }) => {
  useEffect(() => {
    if (!data?.option_one_mismatch_order && !data?.option_all_mismatch_order)
      setValAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const valOne = data?.option_one_mismatch_order
  const setValOne = () => edit(prev => ({ ...prev, 'option_one_mismatch_order': 1, 'option_all_mismatch_order': 0 }))

  const valAll = data?.option_all_mismatch_order
  const setValAll = () => edit(prev => ({ ...prev, 'option_one_mismatch_order': 0, 'option_all_mismatch_order': 1 }))

  return <div className={s.wrapper}>
    <Input val={valOne === 1} setVal={setValOne} text='Минус балл, если цифра не на своем месте относительно других, точное положение роли не играет' />
    <Input val={valAll === 1} setVal={setValAll} text='Минус балл за каждое неверное положение цифры в ответе' />
  </div>
}

const Input = ({ val, setVal, text }) => {
  return <div className={s.row} onClick={setVal}>
    <CheckBox className={s.checkbox} value={val} hardValue={true} />
    <span className={s.text}>{text}</span>
  </div>
}

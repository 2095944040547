import { useEffect, useState } from 'react';

export const useScreenWidth = (width) => {
	const check = (width) => (width >= window.innerWidth ? true : false);

	const [state, setState] = useState(check(width));

	useEffect(() => {
		const handleResize = () => setState(check(width));
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return state;
};

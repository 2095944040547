import Modal from '../../../common/Modal'
import s from './VideoAnswer.module.css'
import { useState } from 'react'
import { getIdUrlYoutube } from '../../../../helpers/getIdUrlYoutube'


export const VideoAnswer = ({ video }) => {
  const [active, setActive] = useState(false)
  return <>
    <button type='button' className='btn btn--yellow' onClick={() => setActive(true)}>Посмотреть видеорешение</button>
    <Modal active={active} setActive={setActive}>
      <iframe className={s.iframe} src={`https://www.youtube.com/embed/${getIdUrlYoutube(video)}`}
        title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen></iframe>
    </Modal>
  </>
}

import classNames from 'classnames'
import { InputFile as Input } from '../../../UI/InputNew'
import { useInputNew } from '../../../UI/InputNew/useInputNew'
import UploadFile from '../../../UI/UploadFile'
import { AnswerButton } from '../AnswerButton/AnswerButton'
import { useTaskHomeWork } from '../hooks/useTaskHomeWork'
import s from './InputFile.module.css'
import {UploadMultipleFiles} from "../../../UI/UploadMultipleFiles/UploadMultipleFiles";
import {useState} from "react";
import {parseAcceptArray} from "../../../../utils/parseAcceptArray";


export const InputFile = ({ type, ...props }) => {
  switch (type) {
    case 'decided': return <Decided {...props} />
    case 'correction': return <Correction {...props} />
    default: return <Task {...props} />
  }
}

const Correction = () => {
  return <div className={classNames(s.wrapper, s.wrapperCorrection)}>
    <div className={s.row}>
      <UploadFile className={s.input} noIco={true} allowed={false} />
    </div>
  </div>
}

const Decided = ({ userRole, ...props }) => {
  const answer = props?.params?.result?.answer
  const decidedRight = props?.params?.result?.decided_right
  const files = props?.params?.result?.answer_files ?? []

  return <div className={s.answerWrap}>
    <span className={classNames(s.capsuleLeft, { [s.red]: decidedRight !== 1 })}>{userRole === 'children' ? 'Ваш ответ' : 'Ответ ребёнка'}</span>
    {answer ? <a href={process.env.REACT_APP_PATH_FILE + '/' + answer} className={s.capsuleRight} target='_blank' rel="noreferrer">Открыть файл</a> : 'Ребёнок не прикрепил файл'}

    {files.map(file => (
        <a key={file} href={process.env.REACT_APP_PATH_FILE + '/' + file.path} className={s.capsuleRight} target='_blank' rel="noreferrer">Открыть файл</a>
    ))}
  </div>
}

const Task = ({ params, ...props }) => {
  const [files, setFiles] = useState([])
  const {
    onAnswer,
    answer,
    status,
    hint,
    video
  } = useTaskHomeWork(params,{ ...props, type: 'file' }, true, files)

  const [bindFiles] = useInputNew({
    name: 'files',
    value: answer.get,
    onChange: answer.set,
    placeholder: 'Загрузите файл',
    disabled: status > 4
  })

  const accept = parseAcceptArray(props?.mime)
  const defaultFiles = params?.result?.answer_files ?? []


  return <>
    <div className={s.wrapper}>
      <Input className={s.inputNew} {...bindFiles} accept={accept} />
      <UploadMultipleFiles
          onInput={setFiles}
          disabled={status > 4}
          accept={accept}
          maxFiles={9}
          defaultFiles={defaultFiles}
      />
    </div>
    <AnswerButton {...{ status, onAnswer, hint, video }} />
  </>
}

import s from "@common/Chat/CreateChat/CreateChat.module.css";
import {CloseIcon} from "@/utils/icons";
import React from "react";

const Participant =({participant, setParticipants, participants}) => {
    const deleteParticipant =() => {
        setParticipants([...participants].filter(el => el.id !== participant.id))
    }
    return (
        <div className={s.participant}>
            <div className={s.participantName}>{participant.name}</div>
            <div className={s.participantDelBtn} onClick={deleteParticipant}><CloseIcon/></div>
        </div>
    )
}

export default Participant;
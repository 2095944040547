/* eslint-disable no-useless-escape */
export default function getVideoPlayer(url) {
  if (url) {
    const video = url.replace(/\s/g, "");
    const regExpYouTube =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const regExpYandex = /^.*(disk.yandex.ru\/|\&v=)([^#\&\?]*).*/;
    const regExpRuTube =
      /^.*(rutube.ru\/|v\/|u\/\w\/|video\/|\&v=)([^#\&\?]*).*/;

    const matchYouTube = video.match(regExpYouTube);
    const matchRuTube = video.match(regExpRuTube);
    const matchVimeo = video.split("/");
    const matchYandex = video.split(regExpYandex);
    const matchYandexPath = matchYandex[2]?.split("/");
    const matchLocal = video.startsWith(process.env.REACT_APP_PATH_FILE);

    if (matchYouTube && matchYouTube[2].length === 11)
      return { type: "youtube", url: matchYouTube[2] };
    else if (matchRuTube && matchRuTube[2].length === 33)
      return { type: "rutube", url: matchRuTube[2] };
    else if (
      matchVimeo.length === 5 &&
      matchVimeo[2] === "vimeo.com" &&
      matchVimeo[3].length === 9 &&
      matchVimeo[4].length === 10
    )
      return { type: "vimeo", url: [matchVimeo[3], matchVimeo[4]] };
    else if (
      matchVimeo.length === 3 &&
      matchVimeo[0] === "vimeo.com" &&
      matchVimeo[1].length === 9 &&
      matchVimeo[2].length === 10
    )
      return { type: "vimeo", url: [matchVimeo[1], matchVimeo[2]] };
    else if (matchYandex && matchYandex[1] === "disk.yandex.ru/")
      return { type: "yandex", url: matchYandexPath };
    else if (matchLocal) {
      return { type: "local", url: url };
    }
  }
  return { type: "error", url };
}
